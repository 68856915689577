<template>
  <div class="wrapper">
    <h2 class="text-start">
      <i class="fa-solid fa-gear"></i>
      &nbsp;Einstellungen
    </h2>
    <div class="list-wrapper mt-5">
      <div class="list-group list-group-light justify-content-between searchable-list">
        <ul class="list-group">
          <h3 class="my-3">
            <i class="fa-solid fa-envelope"></i>
            &nbsp;Spielplantag
          </h3>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 me-auto my-auto">
              <div class="fw-bold text-start align-middle">
                Aktivieren
              </div>
            </div>

            <div class="align-middle mb-1">
              <div class="form-check form-switch form-switch-xl my-auto">
                <input
                    v-model="formActive"
                    class="form-check-input"
                    type="checkbox"
                    @change="update"
                />
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
              v-if="!formActive"
          >
            <div class="ms-2 my-auto flex-fill">
              <div class="fw-bold text-start align-middle">Mitteilung an den Nutzer</div>
            </div>
            <div class="flex-fill"></div>
            <div class="flex-fill">
              <div class="input-group">
                <input
                    v-model="formMessage"
                    aria-describedby="button-save"
                    class="form-control text-end"
                    placeholder="Mitteilung"
                    type="text"
                    v-on:keyup.enter="update"
                />
                <button
                    v-show="formMessage != activeMessage"
                    class="btn btn-outline-dark"
                    type="button-save"
                    @click="update"
                >
                  Speichern
                </button>
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 my-auto flex-fill">
              <div class="fw-bold text-start align-middle">Abgleich der Spiele ab Datum:</div>
            </div>
            &nbsp;<VueFlatPickr v-model="formGameStartDate" :config="config" class="d-none"/>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 me-auto my-auto">
              <div class="fw-bold text-start align-middle">
                Präsenzmodus
              </div>
            </div>

            <div class="align-middle mb-1">
              <div class="form-check form-switch form-switch-xl my-auto">
                <input
                    v-model="formPresence"
                    class="form-check-input"
                    type="checkbox"
                    @change="update"
                />
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
              v-if="formPresence"
          >
            <div class="ms-2 my-auto flex-fill">
              <div class="fw-bold text-start align-middle">Anzahl an TANS pro Liste</div>
            </div>
            <div class="flex-fill"></div>
            <div class="flex-fill">
              <div class="input-group">
                <input
                    v-model="formNumber"
                    aria-describedby="button-save"
                    class="form-control text-end"
                    placeholder="Anzahl"
                    type="number"
                    v-on:keyup.enter="update"
                />
                <button
                    v-show="formNumber != tanNumber"
                    class="btn btn-outline-dark"
                    type="button-save"
                    @click="update"
                >
                  Speichern
                </button>
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
              v-if="formPresence"
          >
            <div class="ms-2 my-auto flex-fill">
              <div class="fw-bold text-start align-middle">TAN-Länge</div>
            </div>
            <div class="flex-fill"></div>
            <div class="flex-fill">
              <div class="input-group">
                <input
                    v-model="formLength"
                    aria-describedby="button-save"
                    class="form-control text-end"
                    placeholder="Länge"
                    type="number"
                    v-on:keyup.enter="update"
                />
                <button
                    v-show="formLength != tanLength"
                    class="btn btn-outline-dark"
                    type="button-save"
                    @click="update"
                >
                  Speichern
                </button>
              </div>
            </div>
          </li>

          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="my-auto mx-auto">
              <div class="fw-bold text-start align-middle">
                <input id="tan" autocomplete="off" class="btn-check" type="checkbox" @click="toTan">
                <label class="btn btn-outline-dark float-left mt-2" for="tan"><i class="fa-solid fa-download"></i>
                  TAN-Listen</label>
              </div>
            </div>
          </li>
          <hr/>
          <h3 class="my-3">
            <i class="fa-regular fa-message"></i>
            &nbsp;Hinweisnachricht
          </h3>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 me-auto my-auto">
              <div class="fw-bold text-start align-middle">
                Aktiveren
              </div>
            </div>

            <div class="align-middle mb-1">
              <div class="form-check form-switch form-switch-xl my-auto">
                <input
                    v-model="formCardActive"
                    class="form-check-input"
                    type="checkbox"
                    @change="update"
                />
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
              v-if="formCardActive"
          >
            <div class="ms-2 my-auto flex-fill">
              <div class="fw-bold text-start align-middle">Icon (Font-Awesome 6)</div>
            </div>
            <div class="flex-fill"></div>
            <div class="flex-fill">
              <div class="input-group">
                <input
                    v-model="formIcon"
                    aria-describedby="button-save"
                    class="form-control text-end"
                    placeholder="Mitteilung"
                    type="text"
                    v-on:keyup.enter="update"
                />
                <button
                    v-show="formIcon != cardIcon"
                    class="btn btn-outline-dark"
                    type="button-save"
                    @click="update"
                >
                  Speichern
                </button>
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
              v-if="formCardActive"
          >
            <div class="ms-2 my-auto flex-fill">
              <div class="fw-bold text-start align-middle">Titel</div>
            </div>
            <div class="flex-fill"></div>
            <div class="flex-fill">
              <div class="input-group">
                <input
                    v-model="formTitle"
                    aria-describedby="button-save"
                    class="form-control text-end"
                    placeholder="Mitteilung"
                    type="text"
                    v-on:keyup.enter="update"
                />
                <button
                    v-show="formTitle != cardTitle"
                    class="btn btn-outline-dark"
                    type="button-save"
                    @click="update"
                >
                  Speichern
                </button>
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
              v-if="formCardActive"
          >
            <div class="ms-2 my-auto flex-fill">
              <div class="fw-bold text-start align-middle">CSS-Klasse (Bootstrap 5)</div>
            </div>
            <div class="flex-fill"></div>
            <div class="flex-fill">
              <div class="input-group">
                <input
                    v-model="formCSS"
                    aria-describedby="button-save"
                    class="form-control text-end"
                    placeholder="Mitteilung"
                    type="text"
                    v-on:keyup.enter="update"
                />
                <button
                    v-show="formCSS != cardCSS"
                    class="btn btn-outline-dark"
                    type="button-save"
                    @click="update"
                >
                  Speichern
                </button>
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-5 p-2 shadow border border-dark"
              v-if="formCardActive"
          >
            <div class="align-middle mb-1 flex-fill">
              <div class="form-check form-switch form-switch-xl my-auto">
                <div class="input-group">
                  <textarea class="form-control" v-model="formHTML"></textarea>
                  <button
                      v-if="cardHTML != formHTML"
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="update"
                  >
                    Speichern
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <Spielplantag/>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from 'axios';
import VueFlatPickr from "vue-flatpickr-component";
import {German} from "flatpickr/dist/l10n/de";
import Spielplantag from "@/components/Admin/Settings/Globals/Spielplantag/Spielplantag.vue";


export default {
  name: "Globals",
  components: {Spielplantag, VueFlatPickr},
  data() {
    return {
      formMessage: null,
      formActive: null,
      formPresence: null,
      formHTML: null,
      formCSS: null,
      formIcon: null,
      formTitle: null,
      formCardActive: null,
      formLength: null,
      formNumber: null,
      formGameStartDate: null,
      config: {
        mode: 'single',
        position: 'center',
        wrap: false, // set wrap to true only when using 'input-group'
        locale: German, // locale for this instance only
        inline: true,
        onChange: (date, string) => {
          this.update()
        },
        static: true,
        weekNumbers: false,
        nextArrow: '<i class="fa-solid fa-arrow-right fa-xl"></i>',
        prevArrow: '<i class="fa-solid fa-arrow-left fa-xl"></i>',
        disable: []
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getGlobals']),
    active() {
      return this.getGlobals.find(global => global.setting === "active").value === "true"
    },
    presence() {
      return this.getGlobals.find(global => global.setting === "presence").value === "true"
    },
    activeMessage() {
      return this.getGlobals.find(global => global.setting === "activeMessage").value
    },
    cardHTML() {
      return this.getGlobals.find(global => global.setting === "cardHTML").value
    },
    cardCSS() {
      return this.getGlobals.find(global => global.setting === "cardCSS").value
    },
    cardTitle() {
      return this.getGlobals.find(global => global.setting === "cardTitle").value
    },
    cardIcon() {
      return this.getGlobals.find(global => global.setting === "cardIcon").value
    },
    cardActive() {
      return this.getGlobals.find(global => global.setting === "showCard").value === "true"
    },
    tanLength() {
      return parseInt(this.getGlobals.find(global => global.setting === "tanLength").value)
    },
    tanNumber() {
      return parseInt(this.getGlobals.find(global => global.setting === "tanNumber").value)
    },
    gamesStartDate() {
      return new Date(this.getGlobals.find(global => global.setting === "gameStartDate").value)
    },

  },
  mounted() {
    this.formMessage = this.activeMessage
    this.formActive = this.active
    this.formPresence = this.presence
    this.formHTML = this.cardHTML
    this.formTitle = this.cardTitle
    this.formCSS = this.cardCSS
    this.formIcon = this.cardIcon
    this.formCardActive = this.cardActive
    this.formNumber = this.tanNumber
    this.formLength = this.tanLength
    this.formGameStartDate = this.gamesStartDate
  },
  methods: {
    ...mapActions('auth', ['globals']),
    toTan() {
      this.$router.replace({name: 'tanSetting'})
    },
    async update() {
      await axios.post('globals/update', {
        settings: [
          {
            setting: "active",
            value: this.formActive
          },
          {
            setting: "activeMessage",
            value: this.formMessage
          }, {
            setting: "presence",
            value: this.formPresence
          },
          {
            setting: "showCard",
            value: this.formCardActive
          }, {
            setting: "cardIcon",
            value: this.formIcon
          }, {
            setting: "cardTitle",
            value: this.formTitle
          }, {
            setting: "cardHTML",
            value: this.formHTML
          }, {
            setting: "cardCSS",
            value: this.formCSS
          },
          {
            setting: "tanNumber",
            value: this.tanNumber
          },
          {
            setting: "tanLength",
            value: this.tanLength
          },
          {
            setting: "gameStartDate",
            value: this.formGameStartDate
          },
        ]
      })
      await this.globals()
    }
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  label {
    min-width: 10px;
    text-align: end;
  }
}
</style>
