<template>
  <div class="row mx-2">
    <button class="btn btn-outline-dark" @click="contactData = !contactData" v-if="contact" :class="{active: contactData}">Kontaktdaten</button>
  </div>
  <div v-if="contactData">
    <ContactTeam v-if="user.length > 0" :user="user"/>
    <ContactClub v-if="manager.length > 0" :manager="manager"/>
  </div>

</template>


<script>
import ContactTeam from "@/components/Reschedule/GameDetails/RescheduleItem/ContactUser/Team/Team";
import ContactClub from "@/components/Reschedule/GameDetails/RescheduleItem/ContactUser/Club/Club";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'ContactUser',
  components: {ContactClub, ContactTeam},
  props: ['game'],
  data() {
    return {
      user: [],
      manager: [],
      contactData: false
    }
  },
  computed: {
    contact(){
      return this.user.length > 0 || this.manager.length > 0
    },
  }, async mounted() {
    const data = await axios.get('match/contact/' + this.game.matchId).then(res => res.data)
    this.user = data.user
    this.manager = data.manager
  }
}
</script>


<style scoped>

</style>