<template>
  <li
      class="list-group-item d-flex justify-content-between align-items-center row mx-2 mb-1 shadow-sm border border-dark">
    <div class="align-items-center col-8 col-sm-7">
      <div class="p-3">
        <p class="fw-bold mb-1 searchable-name text-start">{{ gym.bezeichnung }}</p>
        <p class="text-muted mb-0 list-search-item text-start"><i
            class="fa-solid fa-house"></i>&nbsp;{{ gym.strasse }}
        </p>
        <p class="text-muted mb-0 list-search-item text-start"><i
            class="fa-solid fa-house"></i>&nbsp;{{ gym.plz }}&nbsp;{{ gym.ort }}
        </p>
        <p class="text-muted mb-0 list-search-item text-start"><i
            class="fa-solid fa-hashtag"></i>&nbsp;{{ gym.gymId }}</p>
      </div>
    </div>

    <div class="btn-group-vertical col-3 col-sm-4 me-0 pe-0">
      <div
          class="form-check form-switch form-switch  align-top  text-center"
      >
        <input
            v-model="blocked"
            class="form-check-input"
            type="checkbox"
            @change="updateGym()"
        />&nbsp;
       <span v-if="!blocked"><i class="fa-solid fa-lock-open"></i>&nbsp;belegbar</span>
        <span v-if="blocked"><i class="fa-solid fa-lock"></i> &nbsp; Nicht belegbar</span>

      </div>
      <a @click="del"
                   class="btn btn-danger"><i class="fa-solid fa-eraser"></i><span
          class="btn-description">&nbsp;löschen</span></a>
    </div>
  </li>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "GymListItem",
  props: ["gym"],
  data(){return{
    blocked: false
  }},
  methods: {
    ...mapActions('gym', ['deleteGym', 'addGym']),
    ...mapActions('toast',['add']),
    updateGym(){
      let gym = {
        ...this.gym
      }
      gym.blocked = this.blocked
      this.addGym(gym)
    },
    async del() {
      if (confirm("Wirklich löschen?")) {
        await this.deleteGym(this.gym.gymId).then(() =>
        this.add({
          title: 'gelöscht',
          type: 'success',
          message: 'Die Liga "' + this.gym.bezeichnung + '" wurde erfolgreich gelöscht.'
        }))
      }
    },
  },mounted(){
    this.blocked = this.gym.blocked
  }
}
</script>

<style scoped>

</style>