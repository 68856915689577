<template>
  <div class="mt-2 mb-5">
    <div v-if="value.length > 1">
      <h3>
        {{ value.length }} Ligen ausgewählt
      </h3>
      <Days :value="value" v-on:change="$emit('change')"/>
      <Dates :value="value" v-on:change="$emit('change')"/>
      <Blocking :value="value"  v-on:change="$emit('change')" />
    </div>

    <div v-else-if="value.length === 1">
      <h3>
        {{ value[0].name }}
      </h3>
      <Days :value="value" v-on:change="$emit('change')"/>
      <Dates :value="value" v-on:change="$emit('change')"/>
      <Blocking :value="value" v-on:change="$emit('change')" />
    </div>


  </div>
</template>

<script>
import Days from "@/components/Admin/Settings/League/Options/Days/Days";
import Dates from "@/components/Admin/Settings/League/Options/Dates/Dates";
import Blocking from "@/components/Admin/Settings/League/Options/Blocking/Blocking";

export default {
  name: 'LeagueSettingsOptions',
  emits: ['change'],
  props: ['value'],
  components: {
    Days, Dates, Blocking
  }
}
</script>

<style scoped>

</style>