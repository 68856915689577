<template>
  <form @submit.prevent="submit">
    <div class="row justify-content-center">
      <div class="col-lg-12 mb-3">
        <img class="img-responsive center-block d-block mx-auto img-logo-bbv"
             src="@/assets/images/bbv_puls_logo_cymk.png">
      </div>
      <div class="col-8">
        <h2 class="h3 mb-3 font-weight-normal"><i class="fa-solid fa-key"></i> Passwort vergessen?</h2>
        <div class="E-Mail mb-3">
          <input v-model="form.email" autocomplete="email" autofocus
                 class="form-control" placeholder="E-Mail" required type="email">
        </div>
        <button class="btn btn-lg btn-primary" type="submit">
          Zurücksetzen
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import router from '@/router/router';
import {mapActions} from 'vuex';

export default {
  name: "ForgetPassword",
  data() {
    return {
      form: {
        email: null
      }
    };
  },
  methods: {
    ...mapActions('toast', ['add']),
    async submit() {
      this.form.email = this.form.email.toLowerCase();
      await this.$store.dispatch("auth/forget", this.form);
      router.push({name: 'login'}).then(
          () => {
            this.add({
              title: 'zurückgesetzt',
              type: 'success',
              message: 'Sollte die E-Mail-Adresse im System existieren, wurde ein Link zum Reseten des Passworts verschickt.'
            });
          });
    }
  }
};
</script>

<style scoped>

</style>