<template>
  <li v-if="rescheduled" class="list-group-item rounded mb-5  shadow  mx-2 border border-dark grey-event">
    <div class="align-items-center container">
      <span class="top-right text-reset"> 
         <button v-if="!spt && !invalidTime && !tan && !error"
                 class="btn btn-dark form-control border border-dark" @click="rescheduleGame">
           <i class="fa-solid fa-arrow-right-arrow-left"></i>&nbsp;
           Verlegung
           <span v-if="overlaps" class="text-danger">
             trotzdem
           </span>
           anfragen <span v-if="presence" class="text-danger"> OHNE TAN</span>
         </button>
        <button v-if="!spt && !invalidTime && tan"
                class="btn btn-dark form-control border border-dark" @click="rescheduleGame">
           <i class="fa-solid fa-arrow-right-arrow-left"></i>&nbsp;
           Spiel
           <span v-if="overlaps" class="text-danger">
             trotzdem
           </span>
           verlegen <span v-if="presence" class="text-danger"> MIT TAN</span>
         </button>
      <button v-if="spt" class="btn btn-warning disabled form-control border border-dark"
      ><i class="fa-solid fa-triangle-exclamation"></i>&nbsp;Halle nicht belegbar</button>
        <button v-if="!spt && ( error || invalidTime)" class="btn btn-warning disabled form-control border border-dark"
        ><i class="fa-solid fa-triangle-exclamation"></i>&nbsp;Spielzeit ändern</button>
      </span>
      <span class="top-left bg-grey text-reset">Entwurf</span>
      <div class="p-3 row mb-1 mt-5 rounded border-bottom">
        <div class="col-5">
          <p class="fw-bold mb-0 searchable-name text-end">
            <i class="fa-solid fa-house"></i>
            &nbsp;{{ game.homeTeamTeamname }}
          </p>
        </div>
        <div class="col-2 mb-0 align-middle h-100">
          <i class="fa-solid fa-arrow-right-arrow-left"></i>
        </div>
        <div class="col-5">
          <p class="fw-bold mb-0 searchable-name text-start">
            <i class="fa-solid fa-suitcase"></i>
            &nbsp;{{ game.guestTeamTeamname }}
          </p>
        </div>
      </div>
    </div>

    <div class="justify-content-between align-items-center row">
      <div class="col col-12 col-lg-12 justify-content-center">
        <div class="px-3 row justify-content-center">
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-hashtag"></i>
              &nbsp;{{ game.matchNo }}
            </p>
          </div>
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-calendar-days"></i>
              &nbsp;{{ date }}
            </p>
          </div>
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-clock"></i>
              &nbsp;{{ time }}
            </p>
          </div>
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-house"></i>
              &nbsp;{{ gym }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="presence" :class="{'border border-danger border-3 text-danger' : tanError }" class="bg-grey p-3 rounded">
      <h3 :class="{'text-danger' : tanError }"><i class="fa-solid fa-lock"></i>&nbsp;Tan</h3>
      <div class="mx-5 mb-3">
        <input v-model="tan" :class="{'border border-danger border-3 text-danger' : tanError }" :maxlength="tanLength" class="form-control text-center" type="text"
               @keyup="tanChange" :disabled="spt || invalidTime">
        <h6 v-if="tanError" class="text-danger mt-2"><strong>{{ tanErrorMessage }}</strong></h6>
      </div>
    </div>
    <div v-if="overlaps" class="justify-content-center align-items-center row mt-4">
      <div class="col-lg-8">
        <div class="btn-group align-items-center mx-auto bg-danger p-2 text-light">
          <i class="fa-solid fa-triangle-exclamation fa-xl me-2"></i> Überschneidung festgestellt, bitte Hallenansicht
          prüfen! <i class="ms-2 fa-solid fa-triangle-exclamation fa-xl"></i>
        </div>
      </div>
    </div>
    <div v-if="info" class="justify-content-center align-items-center row mt-4">
      <div class="col-lg-8">
        <div class="btn-group align-items-center mx-auto bg-secondary p-2 text-light">
          <i class="fa-solid fa-circle-info fa-xl me-2"></i> Für dieses Datum wurde folgende Info
          hinterlegt:<br/>{{ infoMessage }}
        </div>
      </div>
    </div>
    <div class="justify-content-center align-items-center row mt-4">
      <div class="col-lg-6">
        <div class="btn-group align-items-center mx-auto">
          <button class="btn btn-danger" @click="remove">
            <i class="fa-solid fa-circle-xmark"></i>&nbsp;Löschen
          </button>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {toGermanDate, calcTimeDifference} from "@/helper/date.helper";

export default {
  name: 'DraftItem',
  props: ['game', 'reschedule'],
  inject: ['formatTime', 'formatDate', 'formatTableDate'],
  data() {
    return {
      tan: null,
      tanError: false,
      tanErrorMessage: "",
      errorTan: null,
    }
  },
  computed: {
    ...mapGetters('league', ['getLeagueById']),
    ...mapGetters('gym', ['getGymById']),
    ...mapGetters('auth', ['getGlobals']),
    presence() {
      return this.getGlobals.find(global => global.setting === "presence").value === "true"
    },
    tanLength() {
      return parseInt(this.getGlobals.find(global => global.setting === "tanLength").value)
    },
    rescheduled() {
      return (this.reschedule.newDate !== null && this.changedDate) ||
          (this.reschedule.newTime !== null && this.changedTime) ||
          (this.reschedule.newGym !== null && this.changedGym)
    },
    //Changed Properties
    changedDate() {
      return this.reschedule.newDate !== null && !((new Date(this.reschedule.newDate)).getFullYear() === (new Date(this.getDate)).getFullYear() &&
          (new Date(this.reschedule.newDate)).getMonth() === (new Date(this.getDate)).getMonth() &&
          (new Date(this.reschedule.newDate)).getDate() === (new Date(this.getDate)).getDate())

    },
    changedGym() {
      return this.reschedule.newGym !== null && this.reschedule.newGym !== this.getGym
    },
    changedTime() {
      return this.reschedule.newTime !== null && this.reschedule.newTime !== this.getTime

    },
    //formated Data
    date() {
      return this.changedDate ? toGermanDate(this.reschedule.newDate) : toGermanDate(this.getDate)
    },
    resDate() {
      return this.changedDate ? this.reschedule.newDate : this.getDate
    },
    tableDate() {
      return this.changedDate ? this.reschedule.newDate : this.getDate
    },
    time() {
      return this.changedTime ? this.reschedule.newTime : this.getTime
    },
    preGym() {
      return this.changedGym ? this.reschedule.newGym : this.getGym
    },
    gym() {
      return this.getGymById(this.preGym).bezeichnung
    },
    // RAW Data
    getLeague() {
      return this.getLeagueById(this.game.ligaId)
    },
    getGym() {
      return this.game.resAnswer === "ACCEPTED" ? this.game.resGymId : this.game.gymId
    },
    getTime() {
      return this.game.resAnswer === "ACCEPTED" ? this.game.resKickoffTime : this.game.kickoffTime
    },
    getDate() {
      return this.game.resAnswer === "ACCEPTED" ? this.game.resKickoffDate : this.game.kickoffDate
    },
    gymData() {
      return this.getGymById(this.preGym)
    },
    spt() {
      return !!this.gymData.blocked
    },
    error() {
      var regex = /^[0-2][0-9]:[0-5][0-9]$/;
      return !regex.test(this.time)
    },
    invalidTime() {
      return this.time === "23:59"
    },
    overlaps() {
      if (!!this.gymData.matches) {
        return this.gymData.matches.filter((game) => {
          return this.getDate === game.kickoffDate
        }).filter((game) => {
          return calcTimeDifference(this.time, game.kickoffTime) < 2
        }).length > 0 || this.gymData.reschedule.filter((game) => {
          return this.getDate === game.resKickoffDate
        }).filter((game) => {
          return calcTimeDifference(this.time, game.resKickoffTime) < 2
        }).length > 0
      }
      return false
    },

    info() {
      let free = this.getLeague.blockingDates.filter(date => date.type === "INFO")
      if (free.find(el => el.date === this.date
      )) {
        return true
      }
      return false
    },
    infoMessage() {
      let free = this.getLeague.blockingDates.filter(date => date.type === "INFO")
      if (free.find(el => el.date === this.date)) {
        return free.find(el => el.date === this.date).info
      }
      return null;

    },
    type() {
      if (!this.changedGym && !this.changedDate && !this.changedTime) {
        return "NONE"
      }
      if (!this.changedGym && !this.changedDate && this.changedTime) {
        return "TIME"
      }
      if (!this.changedGym && this.changedDate && !this.changedTime) {
        return "DATE"
      }
      if (this.changedGym && !this.changedDate && !this.changedTime) {
        return "GYM"
      }
      return "MULTIPLE"
    },
  },
  methods: {
    ...mapActions('match', ['submitReschedule']),
    ...mapActions('gym', ['loadGym']),
    tanChange() {
      if (this.errorTan && this.tan !== this.errorTan) {
        this.tanErrorMessage = null;
        this.tanError = false;
        this.errorTan = null;
      }
    },
    async rescheduleGame() {
      if (this.tan && this.tan.length < this.tanLength) {
        this.tanError = true
        this.tanErrorMessage = "Die TAN ist zu kurz. Bitte korrekte TAN eingeben!"
        this.errorTan = this.tan
        return
      }
      if (this.presence && !this.tan) {
        if (confirm("Wirklich ohne TAN die Verlegung anfragen? Die Anfrage wird dem anderen Verein über das Portal unterbreitet. Während der Präsenz sollte besser mithilfe einer TAN verlegt werden. Soll " +
            "trotzdem ohne TAN die Verlegung angefragt werden?")) {
          await this.submitReschedule({
            game: this.game.matchId,
            newDate: this.resDate,
            newGym: this.preGym,
            newTime: this.time,
            type: this.type,
            tan: this.tan
          }).then(() => this.$emit('reschedule'))

          return this.remove()
        }
      } else {
        if (confirm("Wirklich verlegen?")) {

          const res = await this.submitReschedule({
            game: this.game.matchId,
            newDate: this.resDate,
            newGym: this.preGym,
            newTime: this.time,
            type: this.type,
            tan: this.tan
          }).then((res) => {
            this.$emit('reschedule')
            return res
          })
          if (res.status === 406) {
            this.errorTan = this.tan
            this.tanError = true;
            this.tanErrorMessage = res.data.message;
            return
          }
          this.remove()
        }
      }
    },
    remove() {
      this.$emit('setTime', null)
      this.$emit('setDate', null)
      this.$emit('setGym', null)
      this.tan = null
    }
  },
}
</script>

<style scoped>
.grey-event {
  background: repeating-linear-gradient(
      45deg,
      rgba(20, 20, 20, 0.2),
      white 10px
  );
  color: black;
  border-bottom: solid 0px;
  border-left: solid 0px;
  border-color: black;
  border-style: dashed;
}

.bg-orange {
  background-color: rgb(194, 86, 6)
}

.orange-event {
  background: repeating-linear-gradient(
      45deg,
      rgba(194, 86, 6, 0.3),
      white 10px
  );
  color: black;
  border-bottom: solid 0px;
  border-left: solid 0px;
  border-color: black;
  border-style: dashed;
}

</style>