import axios from 'axios'
import store from '@/store/store'
import socket from '@/socket/socket'
import {useStorage} from "@vueuse/core";


export default {
    namespaced: true,
    state: {
        leagues: useStorage("leagues", [], localStorage),
    },
    getters: {
        getLeagues(state) {
            return state.leagues
        },
        getLeagueById: (state) => (id) => {
            return Object.values(state.leagues).find((league) => league.ligaId === id)
        },
    },
    mutations: {
        setLeagues(state, leagues) {
            state.leagues = leagues;
        },
        resetLeagues(state){
            state.leagues = []
            localStorage.removeItem('leagues')
        }
    },
    actions: {
        async updateSettings({commit},payload){
            await axios.post('/leagues/settings', payload).then(response => commit('setLeagues',response.data));
        },
        async deleteDate({commit}, payload){
            await axios.post('/leagues/blocking', payload).then(response => commit('setLeagues',response.data));
        },
        async loadLeagues({commit}){
            await axios.get('/leagues/list').then(response => commit('setLeagues',response.data));
        },
        async orphanRemoval({commit}){
            await axios.get('/leagues/orphan')
            await store.dispatch('toast/add', {
                title: 'Erfolg',
                type: 'success',
                message: 'Alles wurde geupdated.',
            })
        },
        async updateLeagueTeamSL({dispatch}, payload){
            await axios.get('/leagues/update/'+ payload)
            await dispatch('loadLeagues')
            await store.dispatch('toast/add', {
                title: 'Erfolg',
                type: 'success',
                message: 'Liga wurde geupdated.',
            })
        },
        async updateTeamSL({dispatch}){
            await axios.get('/leagues/updates')
            await dispatch('loadLeagues')
            await store.dispatch('toast/add', {
                title: 'Erfolg',
                type: 'success',
                message: 'Alles wurde geupdated.',
            })
        },
        async updateTeamSLFuture({dispatch}){
            await axios.get('/leagues/updates/future')
            await dispatch('loadLeagues')
            await store.dispatch('toast/add', {
                title: 'Erfolg',
                type: 'success',
                message: 'Alles wurde geupdated.',
            })
        },
    },
}