<template>
  <div>
    <h4 class="text-start">
      <i class="fa-solid fa-list"></i>&nbsp;Ligaeinstellungen
    </h4>

      <div class="p-2">
        <DualListBox
            :source="source"
            :destination="destination"
            label="name"
            @onChangeList="onChangeList"
            ref="box"
        />
      </div>
      <LeagueSettingsOptions :value="destination" v-on:change="change"  />


  </div>
</template>

<script>
import LeagueSettingsOptions from "@/components/Admin/Settings/League/Options/Options";
import DualListBox from "@/components/Helper/DualListBox/DualListBox";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'LeagueSettings',
  components: {LeagueSettingsOptions,DualListBox
   },
  props:{
      league: {
        default: null
      }},
  inject:['groupBy'],
  data(){
    return{
      prePreFilter: null,
      source: [
      ],
      destination: [
      ],
    }
  },
  computed:{
    ...mapGetters('league', ['getLeagues', 'getLeagueById']),
    preFilter () {
      if(this.prePreFilter !== null){
        const filter = [...this.prePreFilter]
        this.prePreFilter = null
        return filter
      }
      if(this.$route.query){
        if(this.$route.query.liga){
          return this.$route.query.liga
        }}
      return null;
    },
    preSource(){
      return this.getLeagues.filter(league => !league.onlyHistory).filter(league =>{
        if(this.preFilter !== null){
          if(this.preFilter.includes(league.ligaId)){
            return false
          }
        }
        return true
      }).map((league)=>{
       return {
          name: league.liganame,
          id: league.ligaId,
          akName: league.akName,
          monday: league.resMon,
          tuesday: league.resTue,
          wednesday: league.resWed,
          thursday: league.resThu,
          friday: league.resFri,
          saturday: league.resSat,
          sunday: league.resSun,
          firstDay: league.firstDay,
          lastDay: league.lastDay,
          blockingDates: league.blockingDates
        }
      })
    },
    preDestination(){
      return this.getLeagues.filter(league =>{
        if(this.preFilter !== null){
          if(this.preFilter.includes(league.ligaId)){
            return true
          }
        }
        return false
      }).map((league)=>{
        return {
          name: league.liganame,
          id: league.ligaId,
          akName: league.akName,
          monday: league.resMon,
          tuesday: league.resTue,
          wednesday: league.resWed,
          thursday: league.resThu,
          friday: league.resFri,
          saturday: league.resSat,
          sunday: league.resSun,
          firstDay: league.firstDay,
          lastDay: league.lastDay,
          blockingDates: league.blockingDates
        }
      })
    },

  },
  methods:{
    ...mapActions('toast', ['add']),
    onChangeList: function ({ source, destination }) {
      let newSource = []
      source.forEach((el) =>
      {
        let league = this.getLeagueById(el.id)
        newSource.push({
          name: league.liganame,
          id: league.ligaId,
          selected: el.selected,
          akName: league.akName,
          monday: league.resMon,
          tuesday: league.resTue,
          wednesday: league.resWed,
          thursday: league.resThu,
          friday: league.resFri,
          saturday: league.resSat,
          sunday: league.resSun,
          firstDay: league.firstDay,
          lastDay: league.lastDay,
          blockingDates: league.blockingDates
        })
      })
      let newDest = []
      destination.forEach(el =>{
        let league = this.getLeagueById(el.id)
        newDest.push({
          name: league.liganame,
          id: league.ligaId,
          akName: league.akName,
          selected: el.selected,
          monday: league.resMon,
          tuesday: league.resTue,
          wednesday: league.resWed,
          thursday: league.resThu,
          friday: league.resFri,
          saturday: league.resSat,
          sunday: league.resSun,
          firstDay: league.firstDay,
          lastDay: league.lastDay,
          blockingDates: league.blockingDates
        })
      })
      this.source = newSource;
      this.destination = newDest;
    },
    change(){
      this.onChangeList({ source:this.source, destination:this.destination})
      this.add({
                    title: 'Erfolgreich',
                    type: 'success',
                    message: `Daten gespeichert!`,
                    timeout: 1000
                })
    }
  },
  async mounted(){
    this.source = this.preSource
    this.destination = this.preDestination
  },
  watch:{
    getLeagues:{
      handler(){
       
        this.prePreFilter = this.destination.map(dest => dest.id)
        this.source = this.preSource
        this.destination = this.preDestination
      },deep: true
    }
  }
}
</script>


<style >
.flatpickr-wrapper{
  width: 100%;
}
.flatpickr-calendar{
  margin-left:auto;
  margin-right: auto;
}
</style>