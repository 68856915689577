<template>
<div class="mb-5">
  <OpenItem v-if="open && game" :game="game" v-on:setDate="date => setDate(date)"
            v-on:setGym="gym => setGym(gym)" v-on:setTime="time => setTime(time)" v-on:reschedule="closeEditor"/>
  <DraftItem v-else-if="game" :game="game" :reschedule="reschedule" v-on:setDate="date => setDate(date)"
             v-on:setGym="gym => setGym(gym)" v-on:setTime="time => setTime(time)" v-on:reschedule="closeEditor"/>

  <RescheduleItem :game="game" :reschedule="reschedule" v-on:setDate="date => setDate(date)"
                  v-on:setGym="gym => setGym(gym)" v-on:setTime="time => setTime(time)" v-if="game"
  />

  <ContactUser :game="game" v-if="game"/>
  <Chat v-if="message && game" :game="game" v-on:chat="delayedLoad"/>
</div>




</template>

<script>
import RescheduleItem from "@/components/Reschedule/GameDetails/RescheduleItem/RescheduleItem";
import DraftItem from "@/components/Reschedule/GameDetails/DraftItem/DraftItem";
import OpenItem from "@/components/Reschedule/GameDetails/OpenItem/OpenItem";
import {mapGetters} from "vuex";
import ContactUser from "@/components/Reschedule/GameDetails/RescheduleItem/ContactUser/ContactUser";
import Chat from "@/components/Helper/Chat/Chat";
import {toEnglishDate} from "@/helper/date.helper";
import axios from "axios";
import socket from "@/socket/socket";

export default {
  name: 'GameDetails',
  props: ['gameId'],
  data() {
    return {
      game: null,
      polling: null,
      reschedule: {
        newDate: null,
        newGym: null,
        newTime: null,
      },
    }
  },
  components: {
    DraftItem, RescheduleItem, OpenItem, ContactUser, Chat
  },
  computed: {
    ...mapGetters('league', ['getLeagueById']),
    gym() {
      if (!this.game) {
        return ""
      }
      return this.game.gymId
    },
    message() {
      if (!this.game) {
        return false
      }
      return !!this.game.chat;
    },
    open(){
      if (!this.game) {
        return false
      }
      return this.game.resAnswer === 'OPEN'
    }
  },
  methods: {
    async getGame(id) {
      this.game = await axios.get('/match/' + id).then(res => res.data)
    },
    setDate(date) {
      this.reschedule.newDate = date
    },
    setTime(time) {
      this.reschedule.newTime = time
    },
    setGym(gym) {
      this.$socket.emit('gymId', gym)
      this.reschedule.newGym = gym
    },
    async closeEditor() {
      await this.getGame(this.gameId)
    },
    async delayedLoad() {
      await (new Promise(resolve => setTimeout(resolve, 850))).then(() => {
        this.getGame(this.gameId)
      });
    }
  },
  async beforeMount() {
    await this.getGame(this.gameId).then(() => {
      if (new Date(this.game.kickoffDate) < new Date() && this.game.resAnswer === "NONE") {
        this.reschedule.newDate = toEnglishDate(this.getLeagueById(this.game.ligaId).firstDay)
      }
    })

  },
  mounted() {
    socket.on('chat', (res) => {
      if (res.game === this.gameId) {
        this.getGame(res.game)
      }
    })
    socket.on('answer', (res) => {
      if (res.game === this.gameId) {
        this.getGame(res.game)
      }
    })
    socket.on('reschedule', (res) => {
      if (res.game === this.gameId) {
        this.getGame(res.game)
      }
    })

  }
}

</script>

<style scoped>


</style>
