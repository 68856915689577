<template>
  <vue-good-table
      :columns="columns"
      :pagination-options="pagination"
      :rows="getClubs"
      :search-options="search"
      :sort-options="{enabled: true}"
      styleClass="vgt-table"
      theme="polar-bear"
  >
    <template #emptystate>
      <i class="fa-solid fa-magnifying-glass"></i> Es wurden keine Vereine gefunden.
    </template>
    <template #table-row="props">
      <ClubsListItem :club="props.row"/>
    </template>
  </vue-good-table>
</template>

<script>
import {mapGetters} from "vuex";
import ClubsListItem from "@/components/Admin/Clubs/List/Item/ClubsListItem";
import {pagination, search, sort} from '@/config/datatable.config'

export default {
  name: "ClubsList",
  data() {
    return {
      columns:[   {
        label: 'Name',
        field: 'vereinsname',
      },]
    };
  },
  components: {
    ClubsListItem,
  },
  computed: {
    ...mapGetters('club', ['getClubs']),
    pagination() {
      return pagination
    },
    search() {
      return search
    },
    sort() {
      return sort
    },
  },

};
</script>

<style scoped></style>
