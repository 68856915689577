import axios from 'axios'
import store from '@/store/store'
import {useStorage} from "@vueuse/core";


export default {
    namespaced: true,
    state: {
        clubs: useStorage("clubs", [], localStorage),
        lastModified: useStorage("clubsLastModified", [], localStorage),
    },
    getters: {
        getClubs(state) {
            return state.clubs
        },
        getClubById: (state) => (id) => {
            return Object.values(state.clubs).find((club) => parseInt(club.vereinId) === parseInt(id))
        },
        getManager: (state) => (id) => {
            const club = Object.values(state.clubs).find((club) => parseInt(club.vereinId) === parseInt(id))
            if (club.manager !== undefined && club.manager.length > 0) {
                return club.manager
            }
            return []
        },
        getUser: (state) => (id) => {
            const club = Object.values(state.clubs).find((club) => parseInt(club.vereinId) === parseInt(id))
            if (club.user !== undefined && club.user.length > 0) {
                return club.user
            }
            return []
        },
    },
    mutations: {
        resetClubs(state) {
            state.clubs = []
            localStorage.removeItem('clubs')
            localStorage.removeItem('clubsLastModified')
        },
        setClubs(state, clubs) {
            state.lastModified = new Date()
            state.clubs = clubs
        },
        updateClub(state, club) {
            state.clubs[Object.values(state.clubs).indexOf(Object.values(state.clubs).find(el => el.vereinId === club.vereinId))] = {...club}
        },
    },
    actions: {
        async loadClubs({commit}) {

            await axios.get('/clubs/list').then((res) => {
                commit('setClubs', res.data)
            })
        },
        async updateInfo({commit}, payload){
            await axios.post('clubs/update/' + payload.id, payload).then((res) => {
                commit('updateClub', res.data)
            })
        },
        async loadClubById({commit}, payload) {
            await axios.get('club/' + payload).then((res) => {
                commit('updateClub', res.data)
            })
        },
        async registerManager({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/user/manager/add', {
                        email: payload.email,
                        firstName: payload.firstName,
                        lastName: payload.lastName,
                        club: payload.club
                    })
                    .then((response) => {
                        store.dispatch('toast/add', {
                            title: 'Erfolg',
                            type: 'success',
                            message: 'Vereinsverantwortlicher wurde angelegt.'
                        })
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        async changeTeams({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post
                ('/user/change', {
                    delTeams: payload.delTeams,
                    teams: payload.teams,
                    user: payload.user,
                    club: payload.club
                }).then((response) => {
                        store.dispatch('toast/add', {
                            title: 'Erfolg',
                            type: 'success',
                            message: 'Mannschaften wurden geändert.'
                        })
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        async deleteUser({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/user/delete', {
                    teams: payload.teams,
                    club: payload.club,
                    user: payload.user
                }).then((response) => {
                    store.dispatch('toast/add', {
                        title: 'Gelöscht',
                        type: 'danger',
                        message: 'Benutzer wurde gelöscht.'
                    })
                    resolve()
                }).catch((error) => reject(error))
            })
        },
        async deleteManager({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('user/manager/delete', {
                    club: payload.club,
                    user: payload.user
                }).then((response) => {
                    if(response.data.ownAccount){
                        store.dispatch('auth/logout')
                    }
                    store.dispatch('toast/add', {
                        title: 'Gelöscht',
                        type: 'danger',
                        message: 'Verantwortlicher wurde gelöscht.'
                    })
                    resolve()
                }).catch((error) => reject(error))
            })
        },

        async registerUser({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/user/add', {
                        teams: payload.teams,
                        email: payload.email,
                        firstName: payload.firstName,
                        lastName: payload.lastName
                    })
                    .then((response) => {
                        store.dispatch('toast/add', {
                            title: 'Erfolg',
                            type: 'success',
                            message: 'Teamverantwortlicher wurde angelegt.'
                        })
                        resolve()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        }
        ,
    },
}
