<template>
  <div class="ClubOpen" v-if="loaded">
    <h2 v-if="games && games.length > 0 " class="text-danger mb-4"><i class="fa-solid fa-thumbs-down"></i>
      {{ games.length }} Spiele sind offen</h2>
    <h2 v-else class="text-success"><i class="fa-solid fa-thumbs-up"></i> Keine Spiele offen</h2>
    <div class="mx-5">
      <input
          id="btnShowVisible"
          v-model="show"
          autocomplete="off"
          class="btn-check btn-block"
          type="checkbox"
      />
      <label class="btn btn-outline-dark w-100 mb-5" for="btnShowVisible">
        <i class="fa-solid fa-eye"></i>&nbsp;anzeigen
      </label>
      <br/>
      <div v-show="show" class="mb-5">
        <GamesListItem v-for="game in games" :game="game"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GamesListItem from "@/components/Shared/Games/List/Item/GamesListItem.vue";
import {mapGetters} from "vuex";

export default {
  name: 'ClubOpen',
  components: {GamesListItem},
  props: ['club'],
  data() {
    return {
      games: [],
      show: false,
      loaded: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin'])
  },
  async mounted() {
    if (this.isAdmin) {
      this.games = await axios.get('match/open/' + this.club).then(res => res.data)
      this.loaded = true
    }
  }
};
</script>
