   <template>
     <div class="center">
       <div class="chat bg-dark">
         <div class="contact bar d-flex justify-content-between text-light">
           <div>
             <div class="name text-start">
               <i class="fa-solid fa-envelope"></i> Chat
             </div>
           </div>
           <div @click="$emit('close')" class="hoverMe">
             <i class="fa-solid fa-x fa-xl text-white"></i>
           </div>
         </div>
         <Messages :game-id="game.matchId"/>
         <Input :game-id="game.matchId"/>
       </div>
     </div>

   </template>

   <script>
  import Input from "@/components/Helper/Chat/Input/Input";
import Messages from "@/components/Helper/Chat/Messages/Messages";

   export default{
     name: 'Desktop',
     props: ['game'],
     components:{
       Input, Messages
     },
  mounted(){
    var chat = document.getElementById("chat");
    chat.scrollTop = chat.scrollHeight - chat.clientHeight;
    let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
   }
   </script>

   <style lang="scss">

   .fa-x{
     color:#FFF;
   }
   .fa-x:hover{
     color:#0099cc !important;
     cursor:pointer !important;
   }

   .center {
     position:fixed;
     top: calc(99%);
     bottom: calc(1%);
     right: calc(1%);
     left: calc(99%);
     transform: translate(-50%, -50%);
     z-index: 1000000;
   }

   .chat {
     position: fixed;
     bottom: 0;
     right: 0;
     display: flex;
     flex-direction: column;
     justify-content: space-between;

     width: 28rem;
     height: 38rem;
     z-index: 100000;
     box-sizing: border-box;
     border-radius: 1rem;

     background: white;
     box-shadow: 0 0 8rem 0 rgba(black, 0.1), 0rem 2rem 4rem -3rem rgba(black, 0.5);

     .contact.bar {
       flex-basis: 0.5rem;
       flex-shrink: 0;
       margin: 1.5rem;

       box-sizing: border-box;
     }
   }


   </style>