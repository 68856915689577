export default {
    namespaced: true,
    state: {
        toasts: []
    },
    getters: {
        getToasts(state) {
            return state.toasts;
        }
    },
    mutations: {
        addToast(state, toast) {
            state.toasts.push(toast);
        },
        clearToast(state, title) {
            const index = state.toasts.findIndex((toast) => toast.title === title);
            state.toasts.splice(index, 1);
        },
        emptyToast(state){
            state.toasts = []
        }
    },
    actions: {
        add({
                commit
            }, toast) {
            commit('addToast', toast);
        },
        dismiss({commit}, title) {
            commit('clearToast', title);
        }
    },
    modules: {}
}
