<template>
  <div class="gym">
    <h4><i class="fa-solid fa-house"></i>&nbsp;Neuer Spielort</h4>
    <div class="wrapper mt-2 align-items-center">
      <div class="row justify-content-center">
        <div class="input-group">
          <Multiselect
              v-model="gymModel"
              :class="'text-center '"
              :close-on-select="true"
              :options="options"
              :searchable="true"
              @input="value => this.$emit('setGym', value)"
              placeholder="Bitte Halle aus der Liste auswählen!"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import {mapGetters} from "vuex";


export default {
  name: "Gym",
  props: ['gym', 'game'],
  components: {
    Multiselect
  },
  data() {
    return {
      gymModel: null
    }
  },
  computed: {
    ...mapGetters('gym', ['getGyms']),
    options() {
      let gym = this.getGyms.filter(gym => !gym.blocked).map((obj) => {
        return {value: obj.gymId, label: obj.bezeichnung};
      })

      return gym.sort(function (a, b) {
        return a.label.localeCompare(b.label)
      })
    },

  },
  mounted() {
    this.gymModel = this.gym
  },


};
</script>

<style>
.multiselect-single-label {
  width: 100%;
  margin-left: auto;
  text-align: center !important;
}

.multiselect-single-label-text {
  padding-left: 10px;
  width: 100%;
  text-align: center;
}
.multiselect-option.is-selected {
  background: rgb(0, 175, 255)!important;
}

</style>
