<template>

  <ul class="navbar-nav ms-auto mb-2 mb-md-0 dropdown ">
    <li v-if="getNewOpen > 0  && !isAdmin"
          class="nav-item text-light"
      >
        <a class="nav-link text-light"  @click="toOpen">
          <i class="fa-solid fa-book-open"></i>&nbsp;{{ getNewOpen }}
        </a>
      </li>
    <li v-if="getNewUnread > 0 && !isAdmin" class="nav-item">
        <a class="nav-link text-light" @click="toChat">
          <i class="fa-solid fa-envelope"></i> {{ getNewUnread }}
        </a>
      </li>



    <li v-if="isAuthenticated" class="nav-item">
      <a
          id="userMenu"
          aria-expanded="false"
          class="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          href="#"
      >
        <i class="fa-solid fa-lock-open"></i>
        &nbsp;{{ getUser.firstName }}
      </a>
      <ul aria-labelledby="userMenu" class="ms-auto dropdown-menu dropdown-menu-end">
        <li>
          <router-link :to="{ name: 'userProfile'}" class="dropdown-item"><i class="fa-solid fa-address-card"></i>&nbsp;Profil
          </router-link>
        </li>
        <li>
          <router-link :to="{name: 'userPassword'}" class="dropdown-item"><i
              class="fa-solid fa-key"></i>&nbsp;Passwort ändern
          </router-link>
        </li>
        <hr />
        <li> <router-link class="dropdown-item " :to="{name: 'legal'}">
          <i class="fa-solid fa-gavel"></i>
          &nbsp;Datenschutz
        </router-link></li>
        <li> <router-link class="dropdown-item " :to="{name: 'impressum'}">
          <i class="fa-solid fa-stamp"></i>
          &nbsp;Impressum
        </router-link></li>
        <hr/>

        <li><a class="dropdown-item text-danger" @click="submit"><i
            class="fa-solid fa-lock"></i>&nbsp;Logout</a></li>
      </ul>
    </li>

    <li v-else class="nav-item">
      <router-link :to="{ name: 'login'}" class="nav-link">
        <i class="fa-solid fa-lock"></i>
        &nbsp;Login
      </router-link>
    </li>
  </ul>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";
import { useRouter } from 'vue-router';

export default {
  name: "ProfileNavbar",
  data() {
    return {
      polling: null,
    }
  },
  computed: {
    ...mapGetters('auth', ["getUser", "isAdmin", 'isAuthenticated']),
    ...mapGetters('match', ['getOpen', 'getUnread']),
    getNewOpen() {
      return this.getOpen
    },
    getNewUnread() {
      return this.getUnread
    },
  },
  async mounted() {
    await this.profileNavbar()
  },
  methods: {
    ...mapActions({"logout": "auth/logout"}),
    ...mapActions('match', ['profileNavbar']),
    ...mapMutations('filter', ['setFilter']),
    submit() {
      this.logout();
    },

    toOpen(){
      this.setFilter({
        gym: [],
        date: [],
        search: "",
        canceled: false,
        accepted: false,
        declined: false,
        open: "open",
        calledBack: false,
        spt: false,
        message: false,
        unread: false,
        preFilter: []
      })
      this.$router.replace({name: 'schedule'})
    },
    toChat(){
      this.setFilter({
        gym: [],
        date: [],
        search: "",
        canceled: false,
        accepted: false,
        declined: false,
        open: false,
        calledBack: false,
        spt: false,
        message: false,
        unread: "unread",
        preFilter: []
      })
      this.$router.replace({name: 'schedule'})
    }
  },
};
</script>

<style scoped>

</style>
