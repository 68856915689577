<template>
    <div class="DeletedGames">
      <h2 class="text-start mb-4"><i class="fa fa-solid fa-eraser"></i> ausgeblendete Spiele</h2>
      <div v-if="games.length === 0" class="text-danger">
        Keine Spiele vorhanden!
      </div>
      <div class="mb-5">
        <GamesListItem v-for="game in games" :game="game" @update="mountedFunction"/>
      </div>
    </div>
</template>

<script>
    import axios from "axios";
    import GamesListItem from "@/components/Shared/Games/List/Item/GamesListItem.vue";

    export default {
        name: 'DeletedGames',
      components: {GamesListItem},
      data(){
          return{
            games : []
          }
      },
      methods:{
          async mountedFunction(){
            this.games = await axios.get('/match/deleted/').then(res=> res.data)
          }
      },
      async mounted(){
          await this.mountedFunction()
      }
    };
</script>
