<template>
  <div class="container">
  <div class="list-box-wrapper row justify-content-center">
    <div class="list-box-item  col-lg-5 mt-2">
      <h3 class="border-bottom mt-2"><i class="fa-solid fa-list-check"></i> Ligen</h3>
      <div class="search-box">
        <input v-model="searchSource" type="text" placeholder="Suche" />
        <div
          v-if="searchSource"
          class="clear-search"
          title="Clear Search"
          @click=" searchSource='' "
        >&times;</div>
      </div>
      <ul class="list-box">
        <li
          v-for="(item,key) in source.map((item,inx) => ({inx,...item})).filter(item => item[label in item ? label : 'label'].toLowerCase().includes(searchSource.toLowerCase()))"
          v-bind:key="key"
          :class="'list-item'+ (item.selected ? ' active':'')"
          @click="selectSource(searchSource?item.inx:key)"
        >{{item[label in item ? label : 'label']}}</li>
        <li
          v-if="source.filter(item => item[label in item ? label : 'label'].toLowerCase().includes(searchSource.toLowerCase())).length == 0 && source.length"
          class="list-item"
        >Keine Ergebnisse gefunden</li>
      </ul>
      <div class="bulk-action">
        <div class="select-all" @click="selectAllSource">Alle</div>

        <div class="deselect-all" @click="deSelectAllSource">Keine</div>
      </div>
    </div>
    <div class="actions col-lg-2 mt-2">
      <div class="btn-action btn btn-outline-dark" @click="moveDestination">
        <i class="fa-solid fa-angle-right"></i>
      </div>
      <div class="btn-action btn btn-outline-dark" @click="moveAllDestination">
        <i class="fa-solid fa-angles-right"></i>
      </div>
      <div class="btn-action btn btn-outline-dark" @click="moveSource">
        <i class="fa-solid fa-angle-left"></i>
      </div>
      <div class="btn-action btn btn-outline-dark" @click="moveAllSource">
        <i class="fa-solid fa-angles-left"></i>
      </div>
    </div>
    <div class="list-box-item col-lg-5 mt-2">
      <h3 class="border-bottom mt-2"><i class="fa-solid fa-check-to-slot"></i> Auswahl</h3>
      <div class="search-box">
        <input v-model="searchDestination" type="text" placeholder="Suche" />
        <div
          v-if="searchDestination"
          class="clear-search"
          title="Clear Search"
          @click=" searchDestination='' "
        >&times;</div>
      </div>
      <ul class="list-group list-group-flush border rounded list-box">
        <li
          v-for="(item,key) in destination.map((item,inx) => ({inx,...item})).filter(item => item[label in item ? label : 'label'].toLowerCase().includes(searchDestination.toLowerCase()))"
          v-bind:key="key"
          :class="'list-item'+ (item.selected ? ' active':'')"
          @click="selectDestination(searchDestination?item.inx:key)"
        >{{item[label in item ? label : 'label']}}</li>
        <li
          v-if="destination.filter(item => item[label in item ? label : 'label'].toLowerCase().includes(searchDestination.toLowerCase())).length == 0 && destination.length"
          class="list-item"
        >Keine Ergebnisse gefunden</li>
      </ul>
      <div class="bulk-action">
        <div class="select-all" @click="selectAllDestination">Alle</div>
        <div class="deselect-all" @click="deSelectAllDestination">Keine</div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

export default {
  props: {
    source: Array,
    destination: Array,
    label: {
      default: 'name'
    }
  },
  data: function() {
    return {
      searchSource: "",
      searchDestination: ""
    };
  },
  methods: {
    moveDestination: function() {
      let selected = this.source.filter(f => f.selected);
      if (!selected.length) return;
      selected = selected.map(item => ({
        ...item,
        selected: false
      }));
      let destination = [...selected, ...this.destination];
      let source = this.source.filter(f => !f.selected);
      this.searchSource = "";
      this.searchDestination = "";
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    moveSource: function() {
      let selected = this.destination.filter(f => f.selected);
      if (!selected.length) return;
      selected = selected.map(item => ({
        ...item,
        selected: false
      }));
      let source = [...selected, ...this.source];
      let destination = this.destination.filter(f => !f.selected);
      this.searchSource = "";
      this.searchDestination = "";
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    moveAllDestination: function() {
      let s = this.source;
      if(this.searchSource){
        s = this.source.filter(item => {
          return item.name.toLowerCase().includes(this.searchSource.toLowerCase())
        })
        }

      let destination = [
        ...s.map(item => ({ ...item, selected: false })),
        ...this.destination
      ];
      let source = this.source.filter(item => {
        return s.findIndex(object => object.name === item.name) === -1
      })

      this.searchSource = "";
      this.searchDestination = "";
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    moveAllSource: function() {
      let d = this.destination;
      if(this.searchDestination){
        d = this.destination.filter(item => {
          return item.name.toLowerCase().includes(this.searchDestination.toLowerCase())
        })
      }
      let source = [
        ...d.map(item => ({ ...item, selected: false })),
        ...this.source
      ];
      let destination = this.destination.filter(item => {
        return d.findIndex(object => object.name === item.name) === -1
      })
      this.searchSource = "";
      this.searchDestination = "";
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    selectDestination: function(key) {
      let source = this.source;
      let destination = this.destination.map((i, k) => {
        if (k === key) {
          i.selected = !i.selected;
        }
        return i;
      });
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    selectSource: function(key) {
      let destination = this.destination;
      let source = this.source.map((i, k) => {
        if (k === key) {
          i.selected = !i.selected;
        }
        return i;
      });
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    selectAllSource: function() {
      let source = this.source.map(item => ({ ...item, selected: true }));
      let destination = this.destination;
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    deSelectAllSource: function() {
      let source = this.source.map(item => ({ ...item, selected: false }));
      let destination = this.destination;
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    selectAllDestination: function() {
      let destination = this.destination.map(item => ({
        ...item,
        selected: true
      }));
      let source = this.source;
      this.$emit("onChangeList", {
        source,
        destination
      });
    },
    deSelectAllDestination: function() {
      let destination = this.destination.map(item => ({
        ...item,
        selected: false
      }));
      let source = this.source;
      this.$emit("onChangeList", {
        source,
        destination
      });
    }
  }
};
</script>

<style scoped lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
}
.list-box-wrapper {
  font-family: sans-serif;

  display: flex;
  align-items: center;
  & > div {
    flex: 1;
  }
  .list-box-item {
    border: solid 1px #cccccc;
    border-radius: 3px;
    .search-box {
      border-bottom: solid 1px #cccccc;
      position: relative;
      input {
        border: none;
        width: 100%;
        padding: 0.5rem 1rem;
      }
      .clear-search {
        position: absolute;
        padding: .5rem;
        right: 0;
        top: 0;
        cursor: pointer;
        font-weight: bold;
        color: #e74c3c;
      }
    }
    .list-box {
      height: 250px;
      overflow: auto;
      list-style: none;
      padding: 0;
      margin: 0;
      .list-item {
        padding: 0.5rem 1rem;
        border-bottom: solid 1px #cccccc;
        cursor: pointer;
        // &:last-child {
        //   border: none;
        // }
        &.active {
          background-color: #000000;
          color: #FFFFFF;
        }
      }
    }
    .bulk-action {
      display: flex;
      border-top: solid 1px #cccccc;
      text-align: center;
      cursor: pointer;
      .select-all {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.20);
        color: #000;
        &:hover{
          border: black 1px solid;
        }
      }
      .deselect-all {
        width: 100%;
        background-color: #fff;
        color: #000;
        &:hover{
          border: black 1px solid;
        }
      }
    }
  }
  .actions {
    flex-grow: 0;
    padding: 0 1rem;
    .btn-action {
      margin-bottom: 0.5rem;
    }
  }
}

.btn-action {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  width: 100%;
}

</style>