<template>
  <div class="wrapper mb-5">
    <div class="text-start mb-1">
      <button class="btn btn-outline-dark" type="button" @click="this.$router.go(-1)">
        <i class="fa-solid fa-backward-step"></i>
        Zurück
      </button>
    </div>
    <h2 class="text-start"><i class="fa-solid fa-arrow-right-arrow-left"></i>&nbsp;Verlegung</h2>
    <div class="list-group list-group-light justify-content-between searchable-list">
      <GameDetails :game-id="gameId" v-if="gameId"/>
    </div>
  </div>
</template>

<script>
import GameDetails from "@/components/Reschedule/GameDetails/GameDetails";
import axios from "axios";
import store from "@/store/store";
import socket from "@/socket/socket";
export default {
  name: "Reschedule",
  props: ['gameId'],
  data() {
    return {
      game: null
    }
  },
  components: {
    GameDetails
  }
}
</script>


