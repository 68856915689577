<template>
  <div class="text-start mb-1">
    <button class="btn btn-outline-dark " type="button" @click="this.$router.go(-1)"><i
        class="fa-solid fa-backward-step"></i> Zurück
    </button>
  </div>
  <div class="list-group list-group-light justify-content-between searchable-list">
    <li class="list-group-item rounded mb-2 shadow  mx-2 border border-dark">
      <div class="align-items-center container">
        <span class="top-left bg-dark text-light"><i class="fa-solid fa-gavel"></i>
        &nbsp;Datenschutz</span>
        <div class="panel-body">
          <div class="form-group">
            <div class="text mt-5">
              <p>
                Mit dieser Datenschutzerkl&auml;rung m&ouml;chten wir Sie &uuml;ber Art, Umfang
                und Zweck
                der Verarbeitung von personenbezogenen Daten auf unserer Webseite
                aufkl&auml;ren. Personenbezogene Daten sind alle Daten, die einen
                pers&ouml;nlichen
                Bezug zu Ihnen aufweisen, z. B. Name, Adresse, E-Mail-Adresse oder
                Nutzerverhalten.
              </p>
            </div>
          </div>
          <div>
            <div class="text">
              <strong style="text-decoration: underline;">Wer bei uns f&uuml;r die
                Datenverarbeitung verantwortlich ist</strong>
              <p>Verantwortlich f&uuml;r die Datenverarbeitung ist:</p>
            </div>
          </div>
          <p>
            Berliner Basketball Verband e.V.<br />Hanns-Braun-Stra&szlig;e<br />14053
            Berlin<br />Deutschland<br />030/8936480<br />gs@basketball-verband.berlin<br />
          </p>
          <div>
            <div class="text">
              <strong style="text-decoration: underline;">Kontaktdaten unseres
                Datenschutzbeauftragten</strong>
              <p>
                Unseren Datenschutzbeauftragten k&ouml;nnen Sie per E-Mail unter
                gs@basketball-verband.berlin oder unter unserer Postadresse mit dem Zusatz
                „an den Datenschutzbeauftragten“ erreichen.
              </p>
            </div>
          </div>
          <div>
            <div class="text">
              <strong>Verarbeitung Ihrer Daten im Rahmen der Kernt&auml;tigkeit unseres
                Unternehmens</strong>
              <p>
                Wir verarbeiten Ihre an uns &uuml;bertragene personenbezogene Daten zum Zweck
                der von der Webseite zur Verfügung gestelleten SpielplanTags-Funktion. Hierzu
                speichern und verarbeiten wir Ihre Daten in den von uns genutzten
                Computersystemen. Zu den von uns verarbeiteten Daten geh&ouml;ren all
                diejenigen Daten, die zum Zwecke der Funktionalität durch Sie bereitgestellt wurden.
              </p>
              <p>Hierzu k&ouml;nnen insbesondere folgende Daten z&auml;hlen:</p>
              <ul>
                <li>E-Mailadresse</li>
                <li>persönliche Informationen übermittelt in Chat-Nachrichten</li>


              </ul>
              <p>
                Die Verarbeitung beschr&auml;nkt sich auf diejenigen Daten,
                die&nbsp;zur&nbsp;Beantwortung&nbsp;von Anfragen und/oder zur Erf&uuml;llung
                eines zwischen Ihnen und uns geschlossenen Vertrages erforderlich und
                zweckm&auml;&szlig;ig sind. Eine Weitergabe der personenbezogenen Daten an
                Dritte
                erfolgt nur, wenn dies zum Zwecke der Erbringung der Leistung oder im
                Rahmen der Organisation unseres Betriebes zur Abwicklung der
                Finanzbuchhaltung sowie der Befolgung gesetzlicher Verpflichtungen
                erforderlich ist. In diesem Fall werden nur diejenigen Daten an externe
                Dienstleister &uuml;bertragen, die zur Erf&uuml;llung des Vertrages oder zur
                Abwicklung der Finanzbuchhaltung sowie der Befolgung gesetzlicher
                Verpflichtungen erforderlich und zweckm&auml;&szlig;ig sind. Die Verarbeitung
                durch
                uns erfolgt gem&auml;&szlig; Ihren Weisungen oder den gesetzlichen Bestimmungen.
                <strong style="text-decoration: underline;">Rechtsgrundlage:</strong> Die
                Verarbeitung Ihrer personenbezogenen
                Daten sowie die &uuml;bertragung an Dritte erfolgt gem&auml;&szlig; Art. 6 Abs.
                1 lit. b)
                DSGVO und dient dazu, den Vertrag zwischen Ihnen und uns zu erf&uuml;llen. Im
                &uuml;brigen geben wir Daten an Dritte nur dann weiter, wenn hierzu eine
                gesetzliche Verpflichtung besteht, Art. 6 Abs. 1 lit. c) DSGVO oder wenn
                hieran ein berechtigtes Interesse besteht, Art. 6 Abs. 1 lit f. DSGVO.
                Dies ist beispielsweise dann der Fall, wenn dies zur Verfolgung unserer
                Anspr&uuml;che erforderlich ist. <strong
                  style="text-decoration: underline;">L&ouml;schung:</strong> Die
                L&ouml;schung Ihrer
                Daten erfolgt sobald die Daten nicht mehr zu Erf&uuml;llung vertraglicher oder
                gesetzlicher F&uuml;rsorgepflichten sowie f&uuml;r den Umgang mit etwaigen
                Gew&auml;hrleistungs- und vergleichbaren Pflichten erforderlich sind. Hiervon
                bleiben die gesetzlichen Aufbewahrungspflichten unber&uuml;hrt.
              </p>
            </div>
          </div>
          <div>
            <div class="text">
              <strong style="text-decoration: underline;">Webhosting</strong>
              <p>
                Wir bedienen uns zum Vorhalten unserer Onlinepr&auml;senz eines
                Internet-Service-Providers, auf dessen Server die Webseite gespeichert
                wird (Hosting) und der unsere Seite im Internet verf&uuml;gbar macht. Hierbei
                verarbeitet der Internet-Service-Provider in unserem Auftrag Kontaktdaten,
                Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Bestandsdaten sowie Meta- und
                Kommunikationsdaten. Name und Anschrift des Internet-Service-Providers:
                Strato AG Pascalstra&szlig;e 10 10587 Berlin Deutschland Dessen
                Datenschutzbestimmungen k&ouml;nnen Sie hier einsehen:
                https://www.strato.de/datenschutz/&nbsp;<strong
                  style="text-decoration: underline;">Rechtsgrundlage: </strong>Der
                Internet-Service-Provider verarbeitet die vorgenannten Daten in
                unserem Auftrag, Art. 28 DSGVO. Die Datenverarbeitung erfolgt auf Grund
                unseres berechtigten Interesses an einer effizienten und sicheren
                Bereitstellung unseres Internetangebotes ein, Art. 6 Abs. 1 lit. f) DSGVO.
              </p>
            </div>
          </div>
          <div>
            <div class="text">
              <strong>Angaben zu den von uns vorgehaltenen
                Kontaktaufnahmem&ouml;glichkeiten</strong>
              <p>
                Soweit Sie uns &uuml;ber E-Mail, Soziale Medien, Telefon, Fax, Post, unser
                Kontaktformular oder sonstwie ansprechen und uns hierbei personenbezogene
                Daten wie Ihren Namen, Ihre Telefonnummer oder Ihre E-Mail-Adresse zur
                Verf&uuml;gung stellen oder weitere Angaben zur Ihrer Person oder Ihrem
                Anliegen machen, werden diese Daten zur Verarbeitung Ihrer Anfrage in
                unserem Hause gespeichert und weiterverarbeitet.
                <strong style="text-decoration: underline;">Rechtsgrundlage</strong>: Sofern Sie
                Ihre Anfrage &uuml;ber unser
                Kontaktformular gestellt haben, ist die Rechtsgrundlage f&uuml;r die
                Verarbeitung Ihrer Daten Art. 6 Abs.1 lit. a DSGVO. Sofern Sie Ihre
                Anfrage im Rahmen vertraglicher oder vorvertraglicher Beziehungen mit uns
                stellen, ist die Rechtsgrundlage f&uuml;r die Verarbeitung Ihrer Daten Art. 6
                Abs. 1 lit. b. DSGVO.&nbsp;Sofern Ihre&nbsp;Anfrage nicht unter eine der
                vorgenannten Kategorien f&auml;llt,&nbsp;liegt unser berechtigtes Interesse an
                der Verarbeitung Ihrer Daten darin, Ihre Anfrage ordnungsgem&auml;&szlig; und in
                Ihrem Sinne zu beantworten, Art. 6 Abs. 1 lit. f
                DSGVO.&nbsp;<strong
                  style="text-decoration: underline;">L&ouml;schung:&nbsp;</strong>Die von uns
                erhobenen
                personenbezogenen Daten werden gel&ouml;scht, sofern sie nicht mehr
                erforderlich sind. Wir &uuml;berpr&uuml;fen die Erforderlichkeit alle 2 Jahre.
                Au&szlig;erdem k&ouml;nnen Sie die Datenverarbeitung jederzeit widerrufen.
              </p>
            </div>
          </div>
          <div>
            <div class="text">
              <strong style="text-decoration: underline;">Ihre Rechte nach der DSGVO</strong>
              <p>
                Nach der DSGVO stehen Ihnen die nachfolgend aufgef&uuml;hrten Rechte zu, die
                Sie jederzeit bei dem in Ziffer 1. dieser Datenschutzerkl&auml;rung genannten
                Verantwortlichen geltend machen k&ouml;nnen:
              </p>
              <ul>
                <li>
                  <strong style="text-decoration: underline;">Recht auf Auskunft:</strong> Sie
                  k&ouml;nnen nach Art. 15 DSGVO eine
                  Best&auml;tigung dar&uuml;ber verlangen, ob und welche personenbezogenen
                  Daten wir
                  von Ihnen verarbeiten. Dar&uuml;ber hinaus k&ouml;nnen Sie von uns
                  unentgeltlich
                  Auskunft &uuml;ber die Verarbeitungszwecke, die Kategorie der
                  personenbezogenen Daten, die Kategorien von Empf&auml;ngern, gegen&uuml;ber
                  denen
                  Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer,
                  das Bestehen eines Rechts auf Berichtigung, L&ouml;schung,
                  Einschr&auml;nkung der
                  Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts sowie
                  die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden,
                  verlangen. Ferner steht Ihnen ein Auskunftsrecht dar&uuml;ber zu, ob Ihre
                  personenbezogenen Daten an ein Drittland oder an eine internationale
                  Organisation &uuml;bermittelt wurden. Sofern dies der Fall ist, steht Ihnen
                  das Recht zu, Auskunft &uuml;ber die geeigneten Garantien im Zusammenhang
                  mit
                  der &uuml;bermittlung zu erhalten.
                </li>
                <li>
                  <strong style="text-decoration: underline;">Recht auf Berichtigung:</strong>
                  Gem&auml;&szlig; Art. 16 DSGVO k&ouml;nnen Sie
                  die Berichtigung unrichtiger oder Vervollst&auml;ndigung
                  unvollst&auml;ndiger der
                  bei uns gespeicherten und Sie betreffenden personenbezogenen Daten
                  verlangen.
                </li>
                <li>
                  <strong style="text-decoration: underline;">Recht auf
                    L&ouml;schung:</strong> Gem&auml;&szlig; Art. 17 DSGVO steht Ihnen das
                  Recht zu, die L&ouml;schung Ihrer bei uns gespeicherten personenbezogenen
                  Daten zu verlangen, soweit wir deren Verarbeitung nicht zu folgenden
                  Zwecken ben&ouml;tigen:
                  <ul>
                    <li>zur Erf&uuml;llung einer rechtlichen Verpflichtung,</li>
                    <li>
                      zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
                      Rechtsanspr&uuml;chen,
                    </li>
                    <li>
                      zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung
                      und Information
                      oder
                    </li>
                    <li>
                      aus Gr&uuml;nden der in Art. 17 Abs. 3 lit c und d DSGVO genannten
                      F&auml;lle
                      des &ouml;ffentlichen Interesses.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong style="text-decoration: underline;">Recht auf
                    Einschr&auml;nkung:</strong> Gem&auml;&szlig; Art. 18 DSGVO haben Sie
                  das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                  Daten zu verlangen, wenn
                  <ul>
                    <li>
                      die Richtigkeit der Daten von Ihnen bestritten wird, und zwar
                      f&uuml;r
                      eine Dauer, die es uns erm&ouml;glicht, die Richtigkeit der
                      personenbezogenen Daten zu &uuml;berpr&uuml;fen,
                    </li>
                    <li>
                      die Verarbeitung Ihrer Daten unrechtm&auml;&szlig;ig ist, Sie aber
                      deren
                      L&ouml;schung ablehnen und stattdessen die Einschr&auml;nkung der
                      Nutzung der
                      Daten verlangen,
                    </li>
                    <li>
                      wir die personenbezogenen Daten f&uuml;r die Zwecke der Verarbeitung
                      nicht l&auml;nger ben&ouml;tigen, Sie die Daten jedoch zur
                      Geltendmachung,
                      Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen
                      ben&ouml;tigen
                    </li>
                    <li>
                      Sie gem&auml;&szlig; Art. 21 DSGVO Widerspruch gegen die
                      Verarbeitung Ihrer
                      Daten eingelegt haben, es aber noch nicht feststeht, ob die
                      berechtigten Gr&uuml;nde, die uns trotz Ihres Widerspruches zu einer
                      weiteren Verarbeitung berechtigten, Ihre Rechte &uuml;berwiegen.
                    </li>
                  </ul>
                </li>
                <li>
                  <strong style="text-decoration: underline;">Recht auf
                    Unterrichtung:</strong> Sofern Sie das Recht auf
                  Berichtigung, L&ouml;schung oder Einschr&auml;nkung der Verarbeitung
                  gegen&uuml;ber uns
                  geltend gemacht haben, sind wir verpflichtet, allen Empf&auml;ngern, denen
                  gegen&uuml;ber die Sie betreffenden personenbezogene Daten offengelegt
                  wurden, die von Ihnen verlangte Berichtigung oder L&ouml;schung der Daten
                  oder deren Einschr&auml;nkung der Verarbeitung mitzuteilen, es sei denn,
                  dies
                  erweist sich als unm&ouml;glich oder ist mit einem
                  unverh&auml;ltnism&auml;&szlig;igen
                  Aufwand verbunden. Ihnen steht das Recht zu, von uns &uuml;ber diese
                  Empf&auml;nger unterrichtet zu werden.
                </li>
                <li>
                  <strong style="text-decoration: underline;">Recht auf
                    Daten&uuml;bertragbarkeit:</strong> Gem&auml;&szlig; Art. 20 DSGVO
                  k&ouml;nnen Sie verlangen, dass wir die Sie betreffenden personenbezogenen
                  Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
                  g&auml;ngigen und maschinenlesebaren Format zu erhalten oder die
                  &uuml;bermittlung
                  an einen anderen Verantwortlichen zu verlangen.
                </li>
                <li>
                  <strong style="text-decoration: underline;">Beschwerderecht</strong>: Nach
                  Art. 77 DSGVO haben Sie das
                  Recht, sich bei einer Aufsichtsbeh&ouml;rde zu beschweren. Hierf&uuml;r
                  k&ouml;nnen Sie
                  sich an die Aufsichtsbeh&ouml;rde Ihres &uuml;blichen Aufenthaltsortes,
                  Ihres
                  Arbeitsplatzes oder unseres Firmensitzes wenden.
                </li>
              </ul>
              <strong style="text-decoration: underline;">Widerrufsrecht</strong>
              <p>
                Gem&auml;&szlig; Art. 7 Abs. 3 DSGVO steht Ihnen das Recht zu, Ihre erteilte
                Einwilligung in die Verarbeitung Ihrer Daten jederzeit uns gegen&uuml;ber zu
                widerrufen. Der von Ihnen erkl&auml;rte Widerruf &auml;ndert nichts an der
                Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Verarbeitung Ihrer
                personenbezogenen Daten.
              </p>
              <strong style="text-decoration: underline;">Widerspruchsrecht</strong>
              <p>
                Sie haben das Recht, aus Gr&uuml;nden, die sich aus Ihrer besonderen Situation
                ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
                personenbezogenen Daten, die aufgrund einer Interessenabw&auml;gung (Art. 6
                Abs. 1 lit. f DSGVO) erfolgt, Widerspruch einzulegen. Dies ist
                insbesondere dann der Fall, wenn die Datenverarbeitung nicht zur Erf&uuml;llung
                eines Vertrages erforderlich ist. Sofern Sie von Ihrem Widerspruchsrecht
                Gebrauch machen, bitten wir Sie um die Darlegung der Gr&uuml;nde. Wir werden
                Ihre personenbezogenen Daten dann nicht mehr verarbeiten, es sei denn, wir
                k&ouml;nnen Ihnen gegen&uuml;ber nachweisen, dass zwingende schutzw&uuml;rdige
                Gr&uuml;nde an
                der Datenverarbeitung Ihre Interessen und Rechte &uuml;berwiegen.
              </p>
              <p>
                                        <span style="text-decoration: underline;"><strong>Unabh&auml;ngig vom vorstehend
                                                Gesagten, haben Sie das jederzeitige Recht,
                                                der Verarbeitung Ihrer personenbezogenen Daten f&uuml;r Zwecke der
                                                Werbung
                                                und Datenanalyse zu widersprechen.</strong></span>
              </p>
              <p>
                Ihren Widerspruch richten Sie bitte an die oben angegebene Kontaktadresse
                des Verantwortlichen.
              </p>
            </div>
          </div>
          <div>
            <div class="text">
              <strong style="text-decoration: underline;">Sicherheitsma&szlig;nahmen</strong>
              <p>
                Wir treffen im &uuml;brigen technische und organisatorische
                Sicherheitsma&szlig;nahmen nach dem Stand der Technik, um die Vorschriften der
                Datenschutzgesetze einzuhalten und Ihre Daten gegen zuf&auml;llige oder
                vors&auml;tzliche Manipulationen, teilweisen oder vollst&auml;ndigen Verlust,
                Zerst&ouml;rung oder gegen den unbefugten Zugriff Dritter zu sch&uuml;tzen.
              </p>
              <strong style="text-decoration: underline;">Aktualit&auml;t und &auml;nderung dieser
                Datenschutzerkl&auml;rung</strong>
              <p>
                Diese Datenschutzerkl&auml;rung ist aktuell g&uuml;ltig und hat den Stand Juli
                2020.
                Aufgrund ge&auml;nderter gesetzlicher bzw. beh&ouml;rdlicher Vorgaben kann es
                notwendig werden, diese Datenschutzerkl&auml;rung anzupassen.
              </p>
            </div>

          </div>
        </div>

      </div>


    </li>
  </div>
</template>

<script>
    export default {
        name: 'Legal'
    };
</script>

<style scoped>
li{
  list-style: none;
}
</style>