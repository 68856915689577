<template>
  <div id="spt_navbar" class="collapse navbar-collapse">
    <ul class="navbar-nav me-auto mb-2 mb-md-0">
      <Adminbar v-if="isAdmin"/>
      <Managerbar v-else-if="isManager"/>
      <Userbar v-else/>
      <li class="nav-item">
        <router-link class="nav-link" :to="{name: 'schedule'}">
          <i class="fa-solid fa-list"></i>
          &nbsp;Spielplan
        </router-link>
      </li>
    </ul>
    <ProfileNavbar/>
  </div>
</template>

<script>
import Adminbar from "@/components/Admin/AdminNavbar";
import Managerbar from "@/components/Manager/ManagerNavbar";
import Userbar from "@/components/User/UserNavbar";
import ProfileNavbar from "@/components/Navbar/Profile/ProfileNavbar.vue";
import {mapGetters} from "vuex";

export default {
  name: "Collapsebar",
  components: {
    Adminbar,
    Managerbar,
    Userbar,
    ProfileNavbar
  },
  data() {
    return {
      nav: 1
    }
  },
  computed: {
    ...mapGetters('auth', ["isAdmin", "isManager"]),
  }
};
</script>

<style scoped>

</style>