<template>
  <li class="list-group-item ">
    <div class="d-flex justify-content-between align-items-start w-100">
      <div class="ms-2 me-5">
        <div class="fw-bold">{{ liste.clubName }}</div>
      </div>
      <div class="me-auto"><small>{{ toFormatedDate(liste.date) }}</small></div>
      <div class="me-2 ms-auto">
        <a href="#" @click="removeMe"><span class="badge bg-danger rounded-pill p-2 "><i class="fa-solid fa-eraser"></i> Löschen</span></a>
        <span class="badge bg-primary rounded-pill p-2 ">{{ getValueNow }} frei</span>
        <span class="badge bg-primary rounded-pill p-2 ">{{ getTanLength }} total</span>
        <a :href="pdfUrl" v-if="pdf" :download="liste.clubName+'.pdf'" target="_blank"><span
            class="badge bg-dark  text-light p-2 rounded-pill"><i class="fa-solid fa-download"></i> Download</span></a>
      </div>
    </div>
    <div class="progress mt-1" role="progressbar" :aria-valuenow="getValueNow" aria-valuemin="0"
         :aria-valuemax="getTanLength" style="height: 2px">
      <div class="progress-bar" :style="getWidth"
           :class="{'bg-danger' : getValueNow < 20, 'bg-success' : getValueNow > 80}"></div>
    </div>
  </li>

</template>

<script>
import {generatePDFWithCodes} from '@/helper/pdf.helper'
import axios from 'axios';
import {toFormatedDate} from "@/helper/date.helper";

export default {

  name: "TanListItem",
  props: ["liste"],
  data() {
    return {

      pdf: null,
    }
  }, methods: {
    toFormatedDate,
    async generatePDF() {
      this.pdf = await generatePDFWithCodes(this.liste)
          .then((doc) => doc)
          .catch(error => console.error('Error generating PDF:', error));
    },
    async removeMe() {
      if (confirm("Wirklich löschen?")) {
        await axios.delete('administrator/tan/' + this.liste._id);
        this.$emit('update');
      }
    }
  },
  computed: {
    getTanLength() {
      return parseInt(this.liste.tans.length)
    },
    getValueNow() {
      return parseInt(this.liste.tans.filter(tan => !tan.used).length)
    },
    getWidth() {
      return 'width: ' + (this.liste.tans.filter(tan => !tan.used).length * 100 / this.liste.tans.length) + '%'
    },
    pdfUrl() {
      if (this.pdf) {
        const blob = new Blob([this.pdf], {type: 'application/pdf'});
        return URL.createObjectURL(blob);
      }
      return '';
    }
  },
  async mounted() {
    await this.generatePDF()
  }
}
</script>

<style scoped>

</style>