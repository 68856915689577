<template>
  <div class="wrapper">
    <h2 class="text-start">
      <i class="fa-solid fa-key"></i>
      &nbsp;Passwort ändern
    </h2>
    <div class="list-wrapper mt-5">
      <div
          class="list-group list-group-light justify-content-between searchable-list"
      >
        <ul class="list-group">
          <li
              class="list-group-item  align-items-start mb-2 p-2 shadow border border-dark"
          >
            <form @submit.prevent="submit">
              <div class="input-group mb-3 mt-5">

                <input
                    v-model="form.password"
                    :class="{ 'is-invalid': error || toshort, 'is-valid': success }"
                    autofocus
                    class="form-control"
                    placeholder="Passwort"
                    required
                    type="password"
                />
              </div>
              <div class="input-group mb-3">
                <input
                    v-model="form.passwordVerify"
                    :class="{ 'is-invalid': error || toshort, 'is-valid': success }"
                    class="form-control"
                    placeholder="Passwort wiederholen"
                    required
                    type="password"
                />
                <div v-if="error" class="invalid-feedback">
                  Die Passwörter müssen übereinstimmen!
                </div>
                <div v-if="toshort" class="invalid-feedback">
                  Das Passwort muss mindest 5 Stellen lang sein.
                </div>
                <div v-if="success" class="valid-feedback">
                  Das sieht gut aus!
                </div>
              </div>

              <button class="btn btn-lg btn-primary" type="submit">
                Ändern
              </button>
            </form>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import store from "@/store/store";

export default {
  name: "ResetProfilePassword",
  data() {
    return {
      form: {
        password: null,
        passwordVerify: null
      },
      error: false,
      success: false,
      toshort: false,
    }
  },
  methods: {
    ...mapActions('toast', ['add']),
    ...mapActions('auth', ['reset']),
    async submit() {
      if (this.form.password == this.form.passwordVerify && this.form.password != "" && this.form.password.length > 4) {
        await this.reset( {
              password: this.form.password
              })
        this.form.password = null
        this.form.passwordVerify = null
        this.error = false
        this.success = false
        this.toshort = false
      }
    }
  },
  watch: {
    'form.password'(newValue) {
      if (newValue != null && newValue.length <= 4 && newValue != '') {
        this.toshort = true
      } else {
        this.toshort = false
      }

      if (this.form.passwordVerify != newValue && newValue != '') {
        this.error = true
        this.success = false
      } else if (newValue == '' && this.form.passwordVerify != '') {
        this.error = true
        this.success = false
      } else if (newValue == '' && this.form.passwordVerify == '') {
        this.error = false
        this.success = false
      } else {
        this.error = false
        this.success = true
      }
    },
    'form.passwordVerify'(newValue) {
      if (newValue != null && this.form.password != newValue && newValue != '') {
        this.error = true
        this.success = false
      } else if (newValue == '' && this.form.password != '') {
        this.error = true
        this.success = false
      } else if (newValue == '' && this.form.password == '') {
        this.error = false
        this.success = false
      } else {
        this.error = false
        this.success = true
      }
    },
  },
}

</script>

<style scoped>

</style>