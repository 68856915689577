export default {
    namespaced: true,
    state: {
        routeParam: null
    },
    getters: {
        getParam(state) {
            return state.routeParam;
        }
    },
    mutations: {
        setParam(state, param){
            state.routeParam = param
        },
        resetParam(state){
            state.routeParam = null
        }
    }
}
