<template>

    <div class="toast-container top-0 end-0 p-3">

    <SingleToast
        v-for="(toast, index) of toasties"
        :key="index"
        :toast="toast">
    </SingleToast>
    </div>

</template>

<script>
import SingleToast from './Toast.vue'
import {mapGetters} from 'vuex'


export default {
  name: 'Toasts',
  components: {SingleToast},
  props: ['position'],
  computed: {
    ...mapGetters({"toasties": "toast/getToasts"})
  }
}
</script>

<style>
.toast-dd-container *,
.toast-dd-container {
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
}

.myChat {
  position: fixed;
  bottom: 5px;
  right: 10px;
}
.toast-dd-container {
  position: fixed;
  top: 0;
  z-index: 99999;
  pointer-events: none;
  display: flex;
  align-items: center;
  height: 10vh;
  gap: 10px;
  flex-direction: column;
  padding: 10px;
  width: 500px;
}

.toast-dd-container.top-toast-right {
  right: 0;
  justify-content: start;
}

.toast-dd-container.top-toast-left {
  left: 0;
  justify-content: start;
}

.toast-dd-container.bottom-toast-right {
  right: 0;
  justify-content: end;
  flex-direction: column-reverse;
}

.toast-dd-container.bottom-toast-left {
  left: 0;
  justify-content: end;
  flex-direction: column-reverse;
}

.toast-dd-container.top-toast-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  justify-content: start;
}

.toast-dd-container.bottom-toast-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  justify-content: end;
  flex-direction: column-reverse;
}

.toast-dd-notification {
  max-width: 500px;
  padding: 15px;
  width:100%;
  pointer-events: all;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.2s;
  opacity: 1;
  display: flex;
  align-items: center;
}

.toast-dd-text-container {
  display: flex;
  flex-direction: column;
}

.toast-dd-notification:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  opacity: 1;
}

.toast-dd-notification * {
  max-width: 100%;
  overflow-wrap: break-word;
  white-space: normal;
  word-break: break-word;
  position: relative;
}

.toast-dd-notification.success {
  background-color: #2ECC71
}

.toast-dd-notification.error {
  background-color: #E74C3C
}

.toast-dd-notification.info {
  background-color: #3992D0
}

.toast-dd-notification.warn {
  background-color: #F39C12
}

@media (min-width: 500px) {
  .toast-dd-notification.success::before {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important;
    margin-right: 15px;
  }

  .toast-dd-notification.error::before {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important;
    margin-right: 15px;
  }

  .toast-dd-notification.info::before {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important;
    margin-right: 15px;
  }

  .toast-dd-notification.warn::before {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important;
    margin-right: 15px;
  }
}

.toast-dd-content {
  width:100%;
  font-size: 15px;
}

.toast-dd-title {
  width:100%;
  font-size: 25px;
  font-weight: bold;
}

.toast-dd-notification-fadout {
  opacity: 0;
  transition: opacity 1500ms;
}
</style>