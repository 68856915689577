<template>
    <div class="Dates" v-if="value.length > 0">
      <div class="row mt-2 border-top border-bottom py-2">
        <div class=" col-lg-5 mt-2">
          <div class="w-100">
            <h5>
              Erster Spieltag
            </h5>
            <VueFlatPickr v-model="first" :config="{...config, ...firstConfig}" class="form-control form-control-lg w-100 mb-2"
                          :placeholder="first === null ? 'Verschiedene Daten' : 'Datum'" :class="{'undefined': first===null}"/>
          </div>

        </div>
        <div class="col-lg-0 col-md-2"></div>
        <div class="col-lg-5 mt-2">
          <div class="w-100">
            <h5>
              Letzter Spieltag
            </h5>

            <VueFlatPickr v-model="last" :config="{...config, ...lastConfig}" class="form-control form-control-lg w-100 mb-2"
                          :placeholder="last === null ? 'Verschiedene Daten' : 'Datum'"  :class="{'undefined': last===null}"/>
          </div>
        </div></div>
    </div>
</template>

<script>
import VueFlatPickr from "vue-flatpickr-component";
import {German} from 'flatpickr/dist/l10n/de';
import {mapActions} from "vuex";

    export default {
        name: 'Dates',
      props:['value'],
      emits: ['change'],
      inject:['formatDate'],
      components: {
        VueFlatPickr
      },
      data() {
        return {
          lastDay: null,
          firstDay: null,
          firstConfig: {
            onClose: (date, string) => {this.update({firstDay: string})
            this.firstDay = string}
          },
          lastConfig: {
            onClose: (date,string) => {this.update({lastDay: string})
            this.lastDay = string}
          },
          config: {
            mode: 'single',
            position: 'center',
            wrap: false, // set wrap to true only when using 'input-group'
            locale: German, // locale for this instance only
            static: true,
            weekNumbers: false,
            dateFormat: 'd.m.Y',
            nextArrow: '<i class="fa-solid fa-arrow-right fa-xl"></i>',
            prevArrow: '<i class="fa-solid fa-arrow-left fa-xl"></i>',
            maxDate: '',
            minDate: '',
          },
        }
      },
      computed:{
        first() {
          let firstDate = null;
          if(this.value.length > 0){
            if(this.value[0].firstDay){
              firstDate = this.value[0].firstDay
            }

            if (this.value.filter(league => league.firstDay === firstDate).length === this.value.length) {
              return firstDate
            }}
          return null
        },
        last() {
          let lastDate = null;
          if(this.value.length > 0){
            if(this.value[0].lastDay){
              lastDate = this.value[0].lastDay
            }

            if (this.value.filter(league => league.lastDay === lastDate).length === this.value.length) {
              return lastDate
            }}
          return null
        },
      },
      methods:{
        ...mapActions('league', ['updateSettings']),
        update(object){
          let map = Object.values(this.value).map(val => val.id)
          let ids = {
            ids: map
          }
          this.updateSettings({...ids, ...object})
          this.$emit('change')
        }
      }
    };
</script>

<style>
.undefined{
  background-color: #000 !important;
  color: #FFF !important;
}
.undefined::placeholder{
  color: rgba(255, 255, 255, 0.90) !important;
}
</style>