import axios from "axios";
import store from "@/store/store";
import router from "@/router/router";


axios.defaults.baseURL = process.env.VUE_APP_NODE_ENV === 'production' ? process.env.VUE_APP_AXIOS : process.env.VUE_APP_DEV_AXIOS;



axios.interceptors.response.use(
    (response) => {
  
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    },
    async (error) => {
        if (error.response.status) {
            switch (error.response.status) {
                case 400:
                    await store.dispatch('auth/logout')
                    await store.dispatch('toast/add', {
                        title: 'Fehler',
                        type: 'danger',
                        message:
                        error.response.data.message,
                    })
                    break;
                case 401:
                    await store.dispatch('auth/logout')
                    await store.dispatch('toast/add', {
                        title: 'Fehler',
                        type: 'danger',
                        message:
                        error.response.data.message,
                    })
                    break;
                case 403:
                    await router.push({name: 'login'})
                    await store.dispatch('toast/add', {
                                title: 'Keine Berechtigung',
                                type: 'danger',
                                message:
                                    'Du hast keine Berechtigung diese Ressource aufzurufen.',
                            })
                    break;
                case 404:
                    await store.dispatch('toast/add', {
                            title: 'Fehler',
                            type: 'danger',
                            message:
                            error.response.data.message,
                        })
                    break
                case 406:
                    await store.dispatch('toast/add', {
                        title: 'Fehler',
                        type: 'danger',
                        message:
                        error.response.data.message,
                    })
                    break
                case 410:
                    await store.dispatch('auth/logout')
                    await store.dispatch('toast/add', {
                        title: 'Fehler',
                        type: 'danger',
                        message:
                        error.response.data.message,
                    })
                    break;
                case 502:
                    await store.dispatch('auth/logout').then(()=>{
                        store.dispatch('toast/add', {
                            title: 'Ausgeloggt',
                            type: 'danger',
                            message:
                                'Der Login ist abgelaufen, bitte erneut anmelden.',
                        })
                    })
                    break;
            }
            return Promise.reject(error.response)
        }
    },
)