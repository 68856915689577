
exports.pagination = {
    enabled: true,
    mode: 'records',
    perPage: 10,
    position: 'bottom',
    perPageDropdown: [],
    dropdownAllowAll: true,
    setCurrentPage: 1,
    nextLabel: 'Weiter',
    prevLabel: 'Vor',
    rowsPerPageLabel: 'Spiele pro Seite',
    ofLabel: 'von',
    pageLabel: 'Seite', // for 'pages' mode
    allLabel: 'Alle'
}

exports.sort =
    {
        enabled: true,
        initialSortBy: [
            {field: 'kickoffDate', type: 'asc'}
        ]
}


exports.search = {
    enabled: true,
    trigger: 'keyup',
    skipDiacritics: true,
//    searchFn: mySearchFn,
    placeholder: 'Suchen',
    //  externalQuery: searchQuery
}

const {compareAsc} = require("date-fns");

exports.sortDateFn = sortFn = (x, y, col, rowX, rowY) => {

    const dateA = rowX.kickoffDate
    const dateB = rowY.kickoffDate
    const dateObjectA = new Date(dateA)
    const dateObjectB = new Date(dateB)
    const gymA = rowX.gymBezeichnung
    const gymB = rowY.gymBezeichnung
    const timeA = rowX.kickoffTime
    const timeB = rowY.kickoffTime
    const timeObjectA = parseInt(timeA.split(':')[0]) * 60 + parseInt(timeA.split(':')[1])
    const timeObjectB = parseInt(timeB.split(':')[0]) * 60 + parseInt(timeB.split(':')[1])

    return compareAsc(dateObjectA, dateObjectB) || gymA.localeCompare(gymB) || (timeObjectA < timeObjectB ? -1 : (timeObjectA > timeObjectB ? 1 : 0))
}
