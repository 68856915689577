<template>
  <div class="container rounded-2 border bg-dark border-white text-light mb-2">
      <div class="px-3 row  border-bottom border-white">
          <div class="col-lg-3"><i class="fa-solid fa-arrow-right-arrow-left"></i>&nbsp;verlegt von:</div>
          <div class="col-lg-3">{{history.initialName}}</div>
          <div class="col-lg-3">{{ initialTeam }}</div>
          <div class="col-lg-3"><i class="fa-solid fa-stopwatch"></i>&nbsp;{{ date }}</div>
      </div>
      <div class="px-3 row d-flex justify-content-around">
        <div class="col-lg-3 my-2">
            <i class="fa-solid fa-calendar-days"></i>
            &nbsp;{{ kickoffDate }}
        </div>
        <div class="col-lg-3 my-2">
            <i class="fa-solid fa-clock"></i>
            &nbsp;{{ history.kickoffTime }}
        </div>
        <div class="col-lg-3 my-2">
            <i class="fa-solid fa-house"></i>
            &nbsp;{{ getGym(history.gym) }}
        </div>
      </div>


      <div class="px-3 row  border-top border-white" v-if="history.answer === 'ACCEPTED'">
        <div class="col-lg-3 text-success"><i class="fa-solid fa-check-double"></i>&nbsp;angenommen</div>
        <div class="col-lg-3 text-light">{{ history.answerName }}</div>
        <div class="col-lg-3">&nbsp;</div>
        <div class="col-lg-3"><i class="fa-solid fa-stopwatch"></i>&nbsp;{{ answer }}</div>
      </div>
      <div class="px-3 row  border-top border-white" v-else-if="history.answer === 'OPEN'">
        <div class="col-lg-3 text-light"><i class="fa-solid fa-book-open"></i>&nbsp;offen</div>
        <div class="col-lg-3 text-light">&nbsp;</div>
        <div class="col-lg-3">&nbsp;</div>
        <div class="col-lg-3">&nbsp;</div>
      </div>
      <div class="px-3 row  border-top border-white" v-else-if="history.answer === 'DECLINED'">
        <div class="col-lg-3 text-danger"><i class="fa-solid fa-circle-xmark"></i>&nbsp;abgelehnt</div>
        <div class="col-lg-3 text-light">{{ history.answerName }}</div>
        <div class="col-lg-3">&nbsp;</div>
        <div class="col-lg-3"><i class="fa-solid fa-stopwatch"></i>&nbsp;{{ answer }}</div>
      </div>
      <div class="px-3 row  border-top border-white" v-else-if="history.answer === 'CALLED_BACK'">
          <div class="col-lg-3 text-danger"><i class="fa-solid fa-clock-rotate-left"></i>&nbsp;zurückgezogen</div>
          <div class="col-lg-3 text-light">&nbsp;</div>
          <div class="col-lg-3">&nbsp;</div>
          <div class="col-lg-3"><i class="fa-solid fa-stopwatch"></i>&nbsp;{{ answer }}</div>
        </div>
    </div>
</template>


<script>
import {mapGetters} from "vuex";
import {toFormatedDate, toGermanDate} from "@/helper/date.helper";

export default {
  name: 'HistoryItem',
  props: {
    history: {
      required: true,
    },
    game:{
      required: true
    },
    index:{
      required:true
    }
  },
  computed: {
    ...mapGetters('gym', ['getGymById']),
    kickoffDate(){
      return toGermanDate(this.history.kickoffDate)
    },
    date(){
      return toFormatedDate(this.history.date)
    },
    answer(){
      return toFormatedDate(this.history.answerDate)
    },
    initialTeam(){
      if(parseInt(this.history.initialClub) === 0){
        return "Spielleitung BBV"
      }
      return parseInt(this.history.initialClub) === parseInt(this.game.homeTeamClubId) ? this.game.homeTeamTeamname : this.game.guestTeamTeamname
    }
  },
  methods: {
    getGym(id) {
      return this.getGymById(id).bezeichnung
    }
  }
}
</script>

<style scoped>

</style>