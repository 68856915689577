<template>
  <div class="wrapper">
    <h2 class="text-start"><i class="fa-solid fa-table-list "></i>&nbsp;Clubs</h2>
    <ClubsList />
  </div>
</template>

<script>
import ClubsList from "@/components/Admin/Clubs/List/ClubsList";


export default {
  name: "Clubs",
  components: {ClubsList}
};
</script>

<style scoped>

</style>