<template>
  <li class="list-group-item rounded shadow my-3 mx-2 border border-dark">
    <div class="align-items-center container">
      <span class="top-left bg-secondary text-light">Sportwart</span>
      <div class="pt-2 row  mt-2 rounded  justify-content-center fw-bold text-center">
        <div class="row d-flex justify-content-center text-center">
          <ContactClubUser v-for="(role, index) in manager" :key="index" :role="role"/>
        </div>
      </div>

    </div>
  </li>
</template>


<script>
import ContactClubUser from "@/components/Reschedule/GameDetails/RescheduleItem/ContactUser/User/User";

export default {
  name: 'ContactClub',
  props: ['manager'],
  components: {
    ContactClubUser
  }

}
</script>


<style scoped>

</style>