<template>
  <div class="row justify-content-center">
    <div class="col-lg-12 mb-3">
      <img class="img-responsive center-block d-block mx-auto img-logo-bbv"
           src="@/assets/images/bbv_puls_logo_cymk.png">
    </div>
    <div class="col-8">
      <h2 class="h3 mb-3 font-weight-normal"><i class="fa-solid fa-triangle-exclamation text-danger"></i> Nicht gefunden
      </h2>
      <div class="E-Mail mb-3">
        Die angegebene Ressource <i class="fa-solid fa-wave-square"></i> <strong>{{ to }}</strong> wurde nicht gefunden
        oder es fehlt die Berechtigung.
      </div>

      <router-link :to="{ name: 'home'}" class="btn btn-outline-primary">
        <i class="fa-solid fa-house-user"></i>&nbsp;Home
      </router-link>
      &nbsp;
      <button class="btn btn-outline-dark" type="button" @click="this.$router.go(-1)">
        <i class="fa-solid fa-backward-step"></i>
        Zurück
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: ['to']
};
</script>

<style scoped>
</style>