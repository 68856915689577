<template>
  <div class="wrapper mb-5">
    <h2 class="text-start">
      <i class="fa-solid fa-list"></i>
      &nbsp;Spielplan
    </h2>
    <GamesListNew/>
  </div>
</template>

<script>
import GamesListNew from "@/components/Shared/Games/List/GamesListNew";

export default {
  name: "Games",
  components: {GamesListNew}
}
</script>

<style scoped>
</style>