<template>
  <li class="nav-item dropdown">
    <a
        id="daten"
        aria-expanded="false"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        href="#"
    >
      {{ vereinsName }}
    </a>
    <ul aria-labelledby="daten" class="dropdown-menu">
      <li>
      <router-link :to="{ name: 'home'}" class="dropdown-item">
        <i class="fa-solid fa-hashtag"></i>
        &nbsp;Dashboard
      </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'clubManager'}" class="dropdown-item">
          <i class="fa-solid fa-user-graduate"></i>
          &nbsp;Vereinsverantwortliche
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'clubUser'}" class="dropdown-item">
          <i class="fa-solid fa-user"></i>
          &nbsp;Teamverantwortliche
        </router-link>
      </li>
    </ul>
  </li>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Managerbar",
  computed: {
    ...mapGetters('auth', ['getUser']),
    vereinsName() {
      if (this.getUser && this.getUser.UserRoles && this.getUser.UserRoles.length > 0) {
        if(!!this.getUser.UserRoles[0].club.kurzName){
          return this.getUser.UserRoles[0].club.kurzName
        }
        return this.getUser.UserRoles[0].club.vereinsName
      }
      return "Mein Verein"
    }
  }
};
</script>
<style></style>
