<template>
  <ul class=" list-group ">
    <li class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark">
      <div class="ms-2 me-auto">
        <div class="fw-bold text-start">Vereinsnummer</div>
      </div>
      <div>{{ vereinsNummer }}</div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark">
      <div class="ms-2 me-auto">
        <div class="fw-bold text-start">Teamanzahl</div>
      </div>

      <div>{{ teamLength }}</div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark">
      <div class="ms-2 me-auto my-auto flex-fill">
        <div class="fw-bold text-start align-middle">Kurzname</div>
      </div>
      <div class="flex-fill"></div>
      <div class="flex-fill">
        <div class="input-group">
          <input v-model="kurz" aria-describedby="button-save" class="form-control text-end"
                 placeholder="Kurzname" type="text" v-on:keyup.enter="saveKurzname()">
          <button v-show="kurz != kurzName" class="btn btn-outline-dark" type="button-save"
                  @click="saveKurzname()">Speichern
          </button>
        </div>
      </div>
    </li>
    <li class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark">
      <div class="ms-2 me-auto my-auto flex-fill">
        <div class="fw-bold text-start align-middle">SR-Kürzel</div>
      </div>
      <div class="flex-fill"></div>
      <div class="flex-fill">
        <div class="input-group">
          <input v-model="srKurz" aria-describedby="button-save" class="form-control text-end"
                 placeholder="SR-Kürzel" type="text" v-on:keyup.enter="saveSrName()">
          <button v-show="srKurz != srName" class="btn btn-outline-dark" type="button-save"
                  @click="saveSrName()">Speichern
          </button>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ClubDetails",
  props: ["kurzName", "srName", "teamLength", "vereinsNummer", "club"],
  data() {
    return {
      kurz: null,
      srKurz: null
    };
  }, methods: {
    ...mapActions("club", ["updateInfo"]),
    ...mapActions('toast', ['add']),
    async saveKurzname() {
      if (this.kurzName != this.kurz) {
        this.updateInfo({id: this.club, kurzName: this.kurz});
        this.add({
          title: 'Kurzname',
          type: 'success',
          message: 'Der Kurzname wurde erfolgreich gespeichert.'
        })
      }
    },
    async saveSrName() {
      if (this.srName != this.srKurz) {
        this.updateInfo({id: this.club, srName: this.srKurz});
        this.add({
          title: 'SR-Kürzel',
          type: 'success',
          message: 'Das SR-Kürzel wurde erfolgreich gespeichert.'
        })
      }
    }
  },
  beforeMount() {
    this.kurz = this.kurzName;
    this.srKurz = this.srName;
  }
};
</script>
