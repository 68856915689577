<template>
  <div class="col-md-8">
  <div class="my-2">
    {{ role.firstName }}&nbsp;{{ role.lastName }}
  </div>
  <figure v-if="role.contactInfo" class="my-2">
    <figcaption class="blockquote-footer mt-1">
      {{ role.contactInfo }}&nbsp;<cite title="Source Title"><i
        class="fa-solid fa-circle-info text-secondary"></i></cite>
    </figcaption>
  </figure>
  </div>
  <div class="row mx-2 w-100 border-top">
    <div class="text-center col-md-6 my-2 justify-content-between" v-if="role.email">
      <i class="fa-solid fa-envelope"></i> <a :href="'mailto:' + role.email"
                                              class="text-reset text-decoration-none">{{ role.email }}</a>
    </div>
    <div class="text-center col-md-6 my-2 justify-content-between" v-if="role.contactPhone">
      <i class="fa-solid fa-phone"></i> <a :href="'tel:'+ role.contactPhone"
                                           class="text-reset text-decoration-none">{{ role.contactPhone }}</a>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ContactClubUser',
  props: ['role']
}
</script>

<style scoped>

</style>