import {createRouter, createWebHistory} from "vue-router";
import Dashboard from "../components/Dashboard/Dashboard.vue";
import Clubs from "../components/Admin/Clubs/Clubs.vue";
import Login from "../components/Security/Login.vue";
import store from "@/store/store";
import {Collapse} from "bootstrap";
import Leagues from "@/components/Admin/Leagues/Leagues";
import UserProfile from "@/components/Profile/UserProfile";
import ResetProfilePassword from "@/components/Profile/ResetProfilePassword";
import ResetPassword from "@/components/Security/ResetPassword";
import Club from "@/components/Admin/Club/Club";
import ForgetPassword from "@/components/Security/ForgetPassword.vue";
import NotFound from "@/components/Security/NotFound";
import ClubManager from "@/components/Manager/Manager/ClubManager";
import ClubUser from "@/components/Manager/User/ClubUser";
import Games from "@/components/Shared/Games/Games";
import Reschedule from "@/components/Reschedule/Reschedule";
import Mobile from "@/components/Helper/Chat/Mobile/Mobile";
import LeagueSettings from "@/components/Admin/Settings/League/League";
import Impressum from "@/components/Legal/Impressum";
import Legal from "@/components/Legal/Legal";
import GymSettings from "@/components/Admin/Settings/Gym/GymSettings";
import Spielplantag from "@/components/Admin/Settings/Globals/Spielplantag/Spielplantag";
import Globals from "@/components/Admin/Settings/Globals/Globals";
import Tan from "@/components/Admin/Settings/Tan/Tan.vue";
import DeletedGames from "@/components/Admin/Settings/DeletedGames/DeletedGames.vue";

const routes = [
    {
        path: "/not-found",
        name: "notFound",
        component: NotFound,
        props: true
    },
    {
        path: "/",
        name: "home",
        component: Dashboard
    },
    {
        path: "/leagues/clubs",
        name: "clubs",
        meta: {
            role: 'admin'
        },
        component: Clubs
    },
    {
        path: "/settings/league",
        name: "leagueSettings",
        meta: {
            role: 'admin'
        },
        component: LeagueSettings
    },
    {
        path: "/settings/gym",
        name: "gymSettings",
        meta: {
            role: 'admin'
        },
        props: true,
        component: GymSettings
    },
    {
        path: "/settings/global",
        name: "globalSettings",
        meta: {
            role: 'admin'
        },
        props: true,
        component: Globals
    },    {
        path: "/settings/tan",
        name: "tanSetting",
        meta: {
            role: 'admin'
        },
        props: true,
        component: Tan
    },
    {
        path: "/leagues/club/:club",
        name: "clubDetail",
        component: Club,
        meta: {
            role: 'admin'
        },
        props: true
    },
    {
        path: "/manager/manager",
        name: "clubManager",
        component: ClubManager,
        meta: {
            role: 'manager'
        },
        props: true
    },
    {
        path: "/schedule",
        name: "schedule",
        component: Games,
        props: true
    },
    {
        path: "/impressum",
        name: "impressum",
        component: Impressum,
    },
    {
        path: "/legal",
        name: "legal",
        component: Legal,
    },
    {
        path: "/manager/user",
        name: "clubUser",
        component: ClubUser,
        meta: {
            role: 'manager'
        },
        props: true
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: "/forget",
        name: "forget",
        component: ForgetPassword
    },
    {
        path: "/reset",
        name: "reset",
        component: ResetPassword
    },
    {
        path: "/leagues/leagues",
        name: "adminLeagues",
        meta: {
            role: 'admin'
        },
        component: Leagues
    },
    {
        path: "/deleted",
        name: "deletedGames",
        meta: {
            role: 'admin'
        },
        component: DeletedGames
    },
    {
        path: "/user/profile",
        name: "userProfile",
        component: UserProfile
    },
    {
        path: "/user/reset",
        name: "userPassword",
        component: ResetProfilePassword
    },

   {
        path: '/reschedule/:gameId',
        name: 'reschedule',
        component: Reschedule,
        props: true
    },{
        path:'/reschedule/:gameId/chat',
        name: 'rescheduleChat',
        component: Mobile,
        props: true
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


function canUserAccess(to) {
    if (to.meta.role) {
        if(to.meta.role === 'admin'){
            return store.getters["auth/isAdmin"]
        }
        else if(to.meta.role === 'manager'){
            return store.getters["auth/isAdmin"] || store.getters["auth/isManager"]
        }
    }
    return true;
}

router.beforeEach(async (to) => {

    if(to.name === "impressum" || to.name === "legal"){
        return true
    }
    if(to.name === 'login' && (to.query.verified || to.query.expired)){
        await store.dispatch("auth/logout")
        return true
    }
    if (
        // make sure the user is authenticated
        !store.getters["auth/isAuthenticated"] && to.name === "forget"
    ) {
        // redirect the user to the login page
        return true
    } else if (
        // make sure the user is authenticated
        !store.getters["auth/isAuthenticated"] && to.name === "reset"
    ) {
        // redirect the user to the login page
        return true
    } else if (
        // make sure the user is authenticated
        !store.getters["auth/isAuthenticated"] && to.name !== "login"
    ) {
        // redirect the user to the login page

        store.commit("auth/setLastPath", to);
        return {name: "login"};
    } else if (
        store.getters["auth/isAuthenticated"] && to.name === "login"
    ) {
        return {name: "home"};
    }
    const canAccess = canUserAccess(to)
    if (!canAccess || to.matched.length === 0) {
        return {name: "notFound", params: {to: to.fullPath}};
    }
    return true;
});

router.afterEach(async (to, from) => {
    var myCollapse = document.getElementById("spt_navbar");
    if (myCollapse) {
        var bsCollapse = new Collapse(myCollapse, {toggle: false});
        bsCollapse.hide();
    }

});

export default router;
