export function toEnglishDate(date)
{
    return date === null || date === undefined ? null : date.split('.')[2] + "-" + date.split('.')[1] + "-" + date.split('.')[0]
}

export function toGermanDate(date)
{
    return date === null || date === undefined ? null : date.split('-')[2] + "." + date.split('-')[1] + "." + date.split('-')[0]
}

export function calcTimeDifference(timeA, timeB)
{
    const first = parseInt(timeA.split(":")[0]) * 60 + parseInt(timeA.split(":")[1])
    const second = parseInt(timeB.split(":")[0]) * 60 + parseInt(timeB.split(":")[1])

    return Math.abs(first-second) / 60
}

export function toFormatedDate(date){
    let dateObject = new Date(date);

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear();
    const hours = dateObject.getHours().toString().padStart(2, '0');
    const minutes = dateObject.getMinutes().toString().padStart(2, '0');

    return `${day}.${month}.${year} - ${hours}:${minutes} Uhr`;
}