<template>
  <li class="list-group-item d-flex justify-content-between border-bottom">
      <span :class="{'bg-secondary': type === 'INFO', 'bg-danger' : type === 'BLOCK', 'bg-success' : type === 'FREE'}"
            class="top-left text-white">
        {{ typeName }}</span>
    <div class="text-center ms-5 me-auto w-100">
      <div class="fw-bold"> {{ date}}</div>
      {{ info }}
    </div>
    <span v-if="setting" class="badge" >
        <button class="btn btn-outline-danger btn-lg"  @click="deleteMe">
          X
        </button>
      </span></li>
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: {
    type: String,
    info: String,
    date: String,
    id: String,
    setting: Boolean,
    ids: {
      type: Array,
      required: false
    }
  },
  computed:{
    typeName(){
      if(this.type === "FREE"){
        return 'frei'
      }
      if(this.type === 'BLOCK'){
        return 'sperren'
      }
      if(this.type === 'INFO'){
        return 'info'
      }
    }
  },
  name: 'BlockingListItem',
  emits: ['change'],
  inject: ['formatDate'],
  methods: {
    ...mapActions('league', ['deleteDate']),
    async deleteMe() {
      if(confirm("Wirklich löschen?")){
        await this.deleteDate({
          id: this.id,
          ids: this.ids,
        }).then(() => this.$emit('change'))
      }
    },
  },

};
</script>
