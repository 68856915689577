<template>
  <form @submit.prevent="submit">
    <div class="row justify-content-center">
      <div class="col-lg-12 mb-3">
        <img class="img-responsive center-block d-block mx-auto img-logo-bbv"
             src="@/assets/images/bbv_puls_logo_cymk.png">
      </div>
    </div>
      <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="card text-bg-info mb-3 me-auto ms-auto" style="max-width: 18rem;">
          <div class="card-header">Wir haben den</div>
          <div class="card-body">
            <h5 class="card-title text-white">Spielplantag</h5>
            <p class="card-text">kurz offline.</p>
          </div>
        </div>

      </div>
    </div>
  </form>
</template>

<script>

export default {
  name: "LoginForm",
  data() {
    return {
      form: {
        email: null,
        password: null      }
    };
  },
  methods: {
    async submit() {
      await this.$store.dispatch("auth/login", this.form);
    }
  }
};
</script>

<style scoped>

</style>