<template>
  <li class="list-group-item rounded mb-5  shadow  mx-2 border border-dark">
    <div class="align-items-center container">
      <span class="top-right bg-dark text-light">{{ game.akName }}</span>
      <span class="top-left bg-dark text-light">{{ game.liganame }}</span>
      <div class="p-3 row mb-1 mt-5 rounded border-bottom">
        <div class="col-5">
          <p class="fw-bold mb-0 searchable-name text-end">
            <i class="fa-solid fa-house"></i>
            &nbsp;{{ game.homeTeamTeamname }}
          </p>
        </div>
        <div class="col-2 mb-0 align-middle h-100">
          <i class="fa-solid fa-arrow-right-arrow-left"></i>
        </div>
        <div class="col-5">
          <p class="fw-bold mb-0 searchable-name text-start">
            <i class="fa-solid fa-suitcase"></i>
            &nbsp;{{ game.guestTeamTeamname  }}
          </p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center my-2">
      <div class="col-lg-6">
        <div class="btn btn-outline-success disabled" v-if="game.accepted">
          <i class="fa-solid fa-arrow-right-arrow-left"></i>&nbsp;verlegt
        </div>
        <div class="btn btn-outline-danger disabled" v-else-if="game.declined">
          <i class="fa-solid fa-arrow-right-arrow-left"></i>&nbsp;abgelehnt
        </div>
        <div class="btn btn-outline-danger disabled" v-else-if="game.calledBack">
          <i class="fa-solid fa-arrow-right-arrow-left"></i>&nbsp;zurückgezogen
        </div>
        <button class="btn btn-outline-dark" v-if="game.accepted||game.declined||game.calledBack||game.open"  :class="{active: showHistory}" @click="showHistory = !showHistory">
          <i class="fa-solid fa-clock-rotate-left"></i>&nbsp;Historie
        </button>
      </div>
      <div class="col-lg-10 my-4" v-if="showHistory">
        <History :game="game"/>
      </div>
    </div>
    <div class="justify-content-between align-items-center row">
      <div class="col col-12 col-lg-12 justify-content-center">
        <div class="px-3 row justify-content-center">
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-hashtag"></i>
              &nbsp;{{ game.matchNo }}
            </p>
          </div>
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-calendar-days"></i>
              &nbsp;{{ date }}
            </p>
          </div>
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-clock"></i>
              &nbsp;{{ game.kickoffTime }}
            </p>
          </div>
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-house"></i>
              &nbsp;{{ game.gymBezeichnung }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3 btn-group col-12 col-lg-12 ">
      <button v-if="!game.open" :class="{clicked: showDate}" class="btn btn-outline-secondary"
              @click="showDate = !showDate">
        <i class="fa-solid fa-calendar-days"></i>
        <span class="btn-description">&nbsp;Neuer Spieltermin</span>
      </button>
      <button v-if="!game.open" :class="{clicked: showTime}" class="btn btn-outline-secondary"
              @click="showTime = !showTime">
        <i class="fa-solid fa-clock"></i>
        <span class="btn-description">&nbsp;Neuer Spielbeginn</span>
      </button>
      <button v-if="!game.open" :class="{clicked: showGym}" class="btn btn-outline-secondary"
            @click="showGym = !showGym" >
        <i class="fa-solid fa-house"></i>
        <span class="btn-description">&nbsp;Neuer Spielort</span>
      </button>
      <button :class="{clicked: showTable}" class="btn btn-outline-secondary"
      @click="showTable = !showTable">
        <i class="fa-solid fa-list"></i>
        <span class="btn-description">&nbsp;Hallenansicht</span>
      </button>
    </div>

    <div>
      <div v-if="showDate" class="mb-3 mt-3 border-top">
        <Calendar :game="game"
                  :date="game.kickoffDate"
                  v-on:setDate="(date) => setDate(date)"/>
      </div>
      <div v-if="showTime" class="mb-3 mt-3 border-top">
        <Time :game="game"
              :time="game.kickoffTime"
              v-on:setTime="(time) => setTime(time)"/>
      </div>
      <div v-if="showGym" class="mb-3 mt-3 border-top">
        <Gym :game="game" :gym="game.gymId"
             v-on:setGym="(gym) => setGym(gym)"/>
      </div>
      <div v-if="showTable" class="mb-3 mt-3 border-top">
        <Table :game="game" :reschedule="reschedule"/>
      </div>
    </div>
  </li>
</template>

<script>
import Time from "@/components/Helper/Time/Time";
import Gym from "@/components/Helper/Gym/Gym";
import Table from "@/components/Helper/Table/Table";
import Calendar from "@/components/Helper/Calendar/Calendar";
import History from "@/components/Reschedule/GameDetails/RescheduleItem/History/History";
import {toGermanDate} from "@/helper/date.helper";

import {mapGetters} from "vuex";

export default {
  name: 'RescheduleItem',
  props: ['game', 'reschedule', 'closeEdit'],
  components: {
    Calendar,
    Time,
    Gym,
    Table,
    History
  },
  data(){
    return{
      showHistory:false,
      showDate: false,
      showGym: false,
      showTable:false,
      showTime: false
    }
  },
  computed: {
    ...mapGetters('gym', ['getGymById']),
    date() {
      return toGermanDate(this.game.kickoffDate)
    }
  },
  methods: {
    setGym(gym) {
      this.$emit('setGym', gym)
    },
    setTime(time) {
      this.$emit('setTime', time)
    },
    setDate(date) {
      this.$emit('setDate', date)
    }

  },
  watch:{
    'game.open'(newValue){
      if (newValue === true){
        this.showHistory = false
        this.showTime = false
        this.showGym = false
        this.showDate = false
        this.showTable = false
      }
    }
  }

}
</script>

<style scoped>
.clicked {
  background: rgb(0, 175, 255);
  color: white;
}

.grey-event {
  background: repeating-linear-gradient(
      45deg,
      rgba(20, 20, 20, 0.1),
      white 10px
  );
  color: black;
  border-color: black;
  border-style: dashed;
  border-radius: 10px;
}

.bg-dark {
  background-color: #3f4458 !important;
}

.link {
  cursor: pointer;
}
</style>