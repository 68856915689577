<template>
  <div class="messages" id="chat">
    <Message v-for="(message, index) in chat" :message="message" :key="index" />

    <div v-if="chat.length === 0">
      <div class="time">
       Noch keine Nachrichten vorhanden.
      </div>
    </div>
    <div id="bottomMessage"></div>
  </div>

</template>

<script>
import Message from "@/components/Helper/Chat/Messages/Message/Message";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import {compareAsc, compareDesc} from "date-fns";
import axios from "axios";
import socket from "@/socket/socket";

export default {
  name: "Messages",
  props: ['gameId'],
  data(){
    return{
      game: null,
      lastTimestamp: null,
      lastUserName: null,
    }
  },
  components:{
    Message
  },
  computed:{
    chat(){
      if (this.game && this.game.chat) {
        return this.game.chat.sort((a, b) => compareAsc(new Date(a.sent), new Date(b.sent)))
      }
      return []
    }
  },
  methods:{
    async getGame(id) {
      this.game = await axios.get('/match/' + id).then(res => res.data)
    },
    scrollDown(){
      document.getElementById('bottomMessage').scrollIntoView({behaviour:'smooth', block:'end', inline: 'nearest'})
    },
  },
  async beforeMount() {
    await this.getGame(this.gameId)
  },
  mounted(){
   this.scrollDown()
    socket.on('chat', (res) => {
      if (res.game === this.gameId) {
        this.getGame(res.game)
      }
    })
  },
  watch:{
    async chat(newValue, oldValue){
      const delay = ms => new Promise(res => setTimeout(res, ms));
      if(newValue.length !== oldValue.length){
        await delay(500)
        this.scrollDown()
      }
    }
  }
}
</script>

<style scoped lang="scss">

$background: #f7f7f7;

.messages {
  padding: 1rem;
  background: $background;
  flex-shrink: 2;
  overflow-y: auto;
  height: 50vw;
  box-shadow: inset 0 2rem 2rem -2rem rgba(black, 0.05),
  inset 0 -2rem 2rem -2rem rgba(black, 0.05);
}

@media (max-width: 800px) {
  .messages {
    height: 80vh;
  }
}

</style>