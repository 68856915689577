<template>
  <login-form></login-form>
 <!--<Disabled></Disabled> -->
</template>

<script>
import LoginForm from "@/components/Security/LoginForm";
import Disabled from "@/components/Security/Disabled";
import {mapActions} from 'vuex';

export default {
  name: "Login",
  components: {LoginForm, Disabled},
  methods: {
    ...mapActions('toast', ['add']),
    ...mapActions('auth', ['attempt', 'logout']),
  },
  async mounted() {
    if (this.$route.query.token) {
      await this.attempt({token: this.$route.query.token});
    }
    if (this.$route.query.verified) {
      await this.logout().then(()=>
      this.add( {
        title: 'E-Mail verifiziert',
        type: 'success',
        message: 'Deine E-Mail-Adresse wurde erfolgreich verifiziert. Ein Link zum setzen deines Passwortes wurde soeben an deine E-Mail-Adresse versendet.'
      }))
    }
    if (this.$route.query.expired){
      await this.logout().then(()=>
          this.add( {
            title: 'Abgelaufen',
            type: 'danger',
            message: 'Der Verifikationslink ist bereits abgelaufen. Du hast jetzt eine E-Mail mit einen neuen Link erhalten. Bitte versuche es erneut!'
          }))
    }
  }
};
</script>

<style scoped>

</style>