<template>
  <div class="Blocking py-2">
    <h4><i class="fa-solid fa-ban"></i>&nbsp;Sperrtage</h4>
  </div>

  <div class="row">
    <div class="col-lg-2">
      <select v-model="type" class="form-select form-select-lg mb-3">
        <option selected>Typ</option>
        <option value="FREE">frei</option>
        <option value="BLOCK">sperren</option>
        <option value="INFO">Info</option>
      </select>
    </div>
    <div class="col-lg-4">
      <div>
        <VueFlatPickr :config="config" :modelValue="dateProp" class="form-control form-control-lg w-100 mb-3 btn-out"
                      placeholder="Datum"/>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="input-group mb-3">
        <input v-model="info" class="form-control form-control-lg " placeholder="angezeigte Info"/>
        <button v-if="!!type&&!!info&&!!date" :class="{'btn-outline-success ': parseInt(type) === 0, 'btn-outline-danger': parseInt(type) === 1, 'btn-outline-secondary': parseInt(type) === 2}" class="btn btn-lg"
                @click="update()"
        ><i class="fa-solid fa-plus"></i></button>
      </div>
    </div>
  </div>
  <div>
    <h5 v-if="dates.length > 1">gemeinsame Sperrtage</h5>
    <h5 v-else-if="dates.length === 1">Liste</h5>
  </div>
  <div class="text-center ">
    <BlockingList :dates="dates" :ids="ids" :setting="true" v-on:change="$emit('change')"/>
  </div>
</template>

<script>
import VueFlatPickr from "vue-flatpickr-component";
import {German} from 'flatpickr/dist/l10n/de';
import BlockingList from "@/components/Helper/BlockingList/BlockingList";
import {mapActions} from "vuex";

export default {
  name: 'Blocking',
  props: ['value'],
  emits: ['change'],
  components: {
    BlockingList,
    VueFlatPickr
  },
  data() {
    return {
      dateProp: null,
      date: null,
      type: null,
      info: null,
      config: {
        mode: 'single',
        position: 'center',
        wrap: false, // set wrap to true only when using 'input-group'
        locale: German, // locale for this instance only
        static: true,
        weekNumbers: false,
        onClose: (date, string) => this.date = string,
        dateFormat: 'd.m.Y',
        nextArrow: '<i class="fa-solid fa-arrow-right fa-xl"></i>',
        prevArrow: '<i class="fa-solid fa-arrow-left fa-xl"></i>',
        maxDate: '',
        minDate: '',
      },
    }
  },
  computed: {
    dates() {
      let dates = this.value.map((val) => val.blockingDates);
      let results = [];
      dates.forEach((el) => {
        if (dates.length > 0) {
          results = dates[0].filter((o) =>
              el.some(({id, info, date}) => o.id === id && o.info === info && o.date === date)
          );
        }
      });
      return results;
    },
    ids() {
      return this.value.map(el => el.id)
    }
  },
  methods: {
    ...mapActions('league', ['updateSettings']),
    async update() {
      await this.updateSettings({
        ids: this.ids,
        info: this.info,
        date: this.date,
        type: this.type
      }).then(() => this.$emit('change'))
      this.info = null
      this.date = null
      this.type = null
    },
  }

};
</script>
