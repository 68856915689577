<template>
  <div class="wrapper">
    <h2 class="text-start"><i class="fa-solid fa-table-list "></i>&nbsp;Ligen</h2>
    <LeaguesList />
  </div>
</template>

<script>
import LeaguesList from "@/components/Admin/Leagues/List/LeaguesList";

export default {
  name: "Leagues",
  components: { LeaguesList}
};
</script>

<style scoped>

</style>