<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container-fluid">
        <router-link :to="{ name: 'home'}">
          <div class="bbv_logo"></div>
        </router-link>
        <div class="navbar-brand">
          <a v-if="presence">PRÄSENZMODUS</a>
          <a v-else>SPIELPLANTAG</a>
          <span v-if="presence">Spielplantag</span>
          <span>Berliner Basketball Verband</span>
        </div>

        <Collapsebar v-if="auth"/>
        <ul class="navbar-nav ms-auto mb-2 mb-md-0" v-if="!auth">
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'legal'}">
              <i class="fa-solid fa-gavel"></i>
              &nbsp;Datenschutz
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'impressum'}">
              <i class="fa-solid fa-stamp"></i>
              &nbsp;Impressum
            </router-link>
          </li>
        </ul>
        <button
            aria-controls="spt_navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
            class="navbar-toggler"
            data-bs-target="#spt_navbar"
            data-bs-toggle="collapse"
            type="button"
            v-if="auth"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>

</template>
<script>
import Collapsebar from "./Collapse/Collapsebar.vue";
import {mapGetters} from "vuex";

export default {
  name: "Navbar",
  components: {
    Collapsebar
  },
  computed: {
    ...mapGetters({"auth": "auth/isAuthenticated"}),
    ...mapGetters({"globals": "auth/getGlobals"}),
    presence() {
      return this.globals.find(global => global.setting === "presence").value === "true"
    },
  }
};
</script>
<style>
.navbar-toggler {
  position:relative;
  left: 1px;
  right:1px;
  margin:  0 0 0 auto;
}
</style>
