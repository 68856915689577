import store from "@/store/store";
import axios from "axios";
import socket from '@/socket/socket';

store.subscribe((mutation) => {
        switch (mutation.type) {
            case "auth/setToken":
                if (mutation.payload) {
                    axios.defaults.headers.common["x-access-token"] = `${mutation.payload}`;
                    localStorage.setItem("token", mutation.payload);
                } else {
                    axios.defaults.headers.common["x-access-token"] = null;
                    localStorage.setItem("token", mutation.payload);
                    localStorage.removeItem("token");
                }
                break;
        }
    }
);


