<template>
  <div>
    <div class="list-wrapper">
      <div class="list-group list-group-light justify-content-between searchable-list">
        <div class="col-12 mb-5">
          <h5>
            <button class="btn btn-outline-primary" @click="add = !add">
              <i class="fa-solid fa-file-circle-plus"></i>
              &nbsp;Vereinverantwortlichen hinzufügen
            </button>
          </h5>
          <div class="input-group mb-3" v-if="add">
            <input
                v-model="form.firstName"
                :class="{ 'is-valid': validation.firstName }"
                class="form-control"
                placeholder="Vorname"
                type="text"
            />
            <input
                v-model="form.lastName"
                :class="{ 'is-valid': validation.lastName }"
                class="form-control"
                placeholder="Nachname"
                type="text"
            />
            <input
                v-model="form.email"
                :class="{
                'is-valid': validation.email,
                'is-invalid': validation.email === false,
              }"
                class="form-control"
                placeholder="E-Mail"
                type="email"
            />
            <button
                v-if="
                validation.email &&
                validation.firstName &&
                validation.lastName &&
                !validation.already
              "
                class="btn btn-outline-secondary"
                type="button"
                @click="register()"
            >
              Speichern
            </button>
            <div
                v-if="validation.email && validation.firstName && validation.lastName"
                class="valid-feedback"
            >
              Klicke auf Speichern zum hinzufügen!
            </div>
            <div class="invalid-feedback" v-if="validation.already">
              Die E-Mail-Adresse wurde bereits verwendet, bitte eine andere wählen!
            </div>
          </div>
        </div>
        <div class="list-wrapper mb-3">
        <vue-good-table
            :columns="columns"
            :pagination-options="pagination"
            :rows="manager"
            :search-options="search"
            styleClass="vgt-table"
            theme="polar-bear"
        >
          <template #emptystate>
            <i class="fa-solid fa-magnifying-glass"></i> Es wurden keine Vereinsverantworlichen gefunden.
          </template>
          <template #table-row="props">
            <ClubManagerListItem
                :user="props.row"
                :club="club"
            />
          </template>
        </vue-good-table>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ClubManagerListItem from "@/components/Shared/Manager/Item/ClubManagerListItem";
import {mapActions, mapGetters} from "vuex";
import EmailValidator from "email-validator";
import {pagination, search} from "@/config/datatable.config";

export default {
  name: "ClubManagerList",
  props: ["club"],
  components: {
    ClubManagerListItem,
  },
  data() {
    return {
      add: false,
      columns:[{
        name:"email",
        field: "email"
      },
        {
          field:"firstName",
          hidden:true
        },
        {
          field:"lastName",
          hidden:true
        }
      ],
      form: {
        firstName: null,
        lastName: null,
        email: null,
      },
      validation: {
        firstName: null,
        lastName: null,
        email: null,
        already: false,
      },
    };
  },
  computed: {
    ...mapGetters("club", ["getManager"]),
    pagination(){
      return this.manager.length > 10 ? pagination: {enabled:false}
    },
    search(){
      return this.manager.length > 0 ? search: {enabled:false}
    },
    manager(){
      return this.getManager(this.club)
    }
  },
  methods: {
    ...mapActions("club", ["loadClubById", "registerManager"]),
    async register() {
      this.form.email = this.form.email.toLowerCase();
      await this.registerManager({
        club: this.club,
        email: this.form.email,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
      }).then(() => {
        this.loadClubById(this.club);
        this.add = false;
        this.form.firstName = "";
        this.form.lastName = "";
        this.form.email = "";
        this.validation.email = null;
        this.validation.firstName = null;
        this.validation.lastName = null;
        this.validation.already = false;
      });
    },
  },
  watch: {
    "form.firstName"() {
      if (this.form.firstName == "") {
        this.validation.firstName = null;
      } else {
        this.validation.firstName = true;
      }
    },
    "form.lastName"() {
      if (this.form.lastName == "") {
        this.validation.lastName = null;
      } else {
        this.validation.lastName = true;
      }
    },
    async "form.email"() {
      if (this.form.email == "") {
        this.validation.email = null;
        this.validation.already = false;
      } else {
        if (EmailValidator.validate(this.form.email)) {
            this.validation.email = true;
            this.validation.already = false;
        } else {
          this.validation.email = false;
          this.validation.already = false;
        }
      }
    },
  },
};
</script>

<style scoped></style>
