<template>
  <h4><i class="fa-solid fa-list"></i>&nbsp;Hallenansicht</h4>
  <h5><i class="fa-solid fa-house"></i>{{ gym.bezeichnung }}</h5>
  <VueCal
      :active-view="day"
      :disable-views="['years', 'year', 'month']"
      :editable-events="false"
      :events="events"
      :hide-weekdays="[1,2, 3,4,5]"
      :locale="'de'"
      :selected-date="getDate"
      :time-step="60"
      :timeFrom="480"
      :timeTo="1350"
  />
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import {mapActions, mapGetters} from "vuex";
import {calcTimeDifference} from "@/helper/date.helper";

export default {
  name: "Table",
  props: ['game', 'reschedule'],
  components: {
    VueCal
  },
  computed: {
    ...mapGetters('gym', ['getGymById']),
    gym(){
        return this.getGymById(this.getGym)
    },
    gymId() {
      return this.game.resAnswer === "ACCEPTED" ? this.game.resGymId: this.game.gymId
    },
    date() {
      return this.game.resAnswer === "ACCEPTED" ? this.game.resKickoffDate: this.game.kickoffDate
    },
    time(){
      return this.game.resAnswer === "ACCEPTED" ? this.game.resKickoffTime: this.game.kickoffTime
    },
    day() {
      if (new Date(this.getDate).getDay() === 0 || new Date(this.getDate).getDay() === 6) {
        return 'month'
      } else {
        return 'day'
      }
    },

    changedDate() {
      return this.reschedule.newDate !== null && !((new Date(this.reschedule.newDate)).getFullYear() === (new Date(this.getDate)).getFullYear() &&
          (new Date(this.reschedule.newDate)).getMonth() === (new Date(this.getDate)).getMonth() &&
          (new Date(this.reschedule.newDate)).getDate() === (new Date(this.getDate)).getDate())

    },
    changedGym() {
      return this.reschedule.newGym !== null && this.reschedule.newGym !== this.getGym

    },
    changedTime() {
      return this.reschedule.newTime !== null && this.reschedule.newTime !== this.time

    },
    getGym() {
      return this.changedGym ? this.reschedule.newGym : this.gymId
    },
    getTime() {
      return this.changedTime ? this.reschedule.newTime : this.time
    },
    getDate() {
      return this.changedDate ? this.reschedule.newDate : this.date
    },
    events() {
      if(!this.gym.matches){
        return []
      }
      let result = []
      if(this.changedDate || this.changedTime || this.changedGym){

          const overlaps = this.gym.matches.filter((game) => {
              return this.getDate === game.kickoffDate
            }).filter((game) => {
              return calcTimeDifference(this.getTime, game.kickoffTime) < 2
            }).length > 0 || this.gym.reschedule.filter((game) => {
              return this.getDate === game.resKickoffDate
            }).filter((game) => {
              return calcTimeDifference(this.getTime, game.resKickoffTime) < 2
            }).length > 0

        result = [{
            game: this.game.matchId,
            start: `${this.getDate} ${this.getTime}`,
            end: `${this.getDate} ${(parseInt(this.getTime.split(":")[0]) + 2)}:${this.getTime.split(":")[1]}`,
            content: this.game.homeTeamTeamname + "&nbsp;<i class=\"fa-solid fa-basketball\"></i>&nbsp;" + this.game.guestTeamTeamname,
            title: overlaps ? `<i class="ms-2 fa-solid fa-triangle-exclamation fa-xl"></i> ${this.game.liganame} ( Entwurf )<i class="ms-2 fa-solid fa-triangle-exclamation fa-xl"></i>`:`${this.game.liganame} ( Entwurf )`,
            class: overlaps ? 'red-event' : 'green-event',
            deletable: false,
            resizable: false
        }]
      }

      return [...result, ...this.gym.matches.map(match =>{
        var title = match.liganame
        var className = 'dark-event'
        var start =  `${match.kickoffDate} ${match.kickoffTime}`
        var end = `${match.kickoffDate} ${(parseInt(match.kickoffTime.split(":")[0]) + 2)}:${match.kickoffTime.split(":")[1]}`

        if(match.resAnswer === "OPEN"){
          title= match.liganame + ' ( wird verlegt - von hier weg )'
          className = 'green-event'
        }else if(match.resAnswer === "ACCEPTED" ){
          title = match.liganame + ' ( verlegt )'
          start = `${match.resKickoffDate} ${match.resKickoffTime}`
          end = `${match.resKickoffDate} ${(parseInt(match.resKickoffTime.split(":")[0]) + 2)}:${match.resKickoffTime.split(":")[1]}`
        }
          return{
            game: match.matchId,
            start: start,
            end: end,
            content: match.homeTeamTeamname + "&nbsp;<i class=\"fa-solid fa-basketball\"></i>&nbsp;" + match.guestTeamTeamname,
            title: title,
            class: className,
            deletable: false,
            resizable: false
          }
      }), ...this.gym.reschedule.map(match =>{
        return{
          game: match.matchId,
          start: `${match.resKickoffDate} ${match.resKickoffTime}`,
          end: `${match.resKickoffDate} ${(parseInt(match.resKickoffTime.split(":")[0]) + 2)}:${match.resKickoffTime.split(":")[1]}`,
          content: match.homeTeamTeamname + "&nbsp;<i class=\"fa-solid fa-basketball\"></i>&nbsp;" + match.guestTeamTeamname,
          title: match.liganame + ' ( wird verlegt - hierher )',
          class: 'orange-event',
          deletable: false,
          resizable: false
        }
      }),
      ]


    },
  },
  methods:{
    ...mapActions('gym', ['loadGym'])
  },
  async mounted(){
    await this.loadGym(this.getGym)
  },
  watch:{
    getGym:{
      async handler(){
        await this.loadGym(this.getGym)
      }
    }
  }
};
</script>

<style>
.vuecal__event-title {
  margin-top: 10px;

  font-size: 0.7rem;
}

.grey-event {
  background: repeating-linear-gradient(
      45deg,
      rgba(20, 20, 20, 0.2),
      white 10px
  );
  color: black;
  border-color: black;
  border-style: dashed;
}

.orange-event {
  background: repeating-linear-gradient(
      45deg,
      rgba(255, 165, 0, 0.2),
      white 10px
  );
  color: black;
  border-color: black;
  border-style: dashed;
}

.green-event {
  background: repeating-linear-gradient(
      45deg,
      rgba(26, 255, 0, 0.2),
      white 10px
  );
  color: black;
  border-color: black;
  border-style: dashed;
}
.red-event {
  background:
      rgba(187, 1, 1, 0.8);

  color: white;
  border-color: black;
  border-style: dashed;
}

.dark-event {
  background-color: #3f4458;
  color: white;
  border-color: white;
  border-style: dashed;
}


.vuecal__event-time {
  font-size: 0.9rem;
}

.vuecal__event-content {
  font-size: 0.7rem;
}

.vuecal__title-bar button,
.vuecal__title-bar span {
  color: rgb(255, 255, 255, 0.95);
}

.vuecal__title-bar {
  background-color: #3f4458;

}

.vuecal__no-event {
  display: none;
}

@media only screen and (max-width: 761px) {
  .vuecal__event-title {
    margin-top: 10px;

    font-size: 0.4rem;
  }

  .vuecal__event-time {
    font-size: 0.5rem;
  }

  .vuecal__event-content {
    font-size: 0.4rem;
  }
}
</style>