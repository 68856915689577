<template>
  <li
      class="list-group-item d-flex justify-content-between align-items-center row mx-2 mb-1 shadow-sm border border-dark" :class="{ 'bg-blue': league.onlyHistory}"> 
    <div class="align-items-center col-8 col-sm-7">
      <div class="p-3">
        <p class="fw-bold mb-1 searchable-name text-start"> {{ league.liganame }}</p>
        <p class="text-muted mb-0 list-search-item text-start"><i
            class="fa-solid fa-venus-mars"></i>&nbsp;{{ league.geschlecht }}
        </p>
        <p class="text-muted mb-0 list-search-item text-start"><i
            class="fa-solid fa-fingerprint"></i>&nbsp;{{ league.liganr }}</p>
        <p class="text-muted mb-0 text-start" v-if="!league.onlyHistory">
          <i class="fa-solid fa-calendar-days"></i>
          <span class="mx-2">
            <span v-if="league.resMon"><i class="fa-solid fa-check text-success"></i></span>
            <span v-else><i class="fa-solid fa-ban text-danger"></i></span>
            Mo
          </span>
          <span class="mx-2">
            <span v-if="league.resTue"> <i class="fa-solid fa-check text-success"></i></span>
            <span v-else><i class="fa-solid fa-ban text-danger"></i></span>
            Di
          </span>
          <span class="mx-2">
            <span v-if="league.resWed"> <i class="fa-solid fa-check text-success"></i></span>
            <span v-else><i class="fa-solid fa-ban text-danger"></i></span>
            Mi
          </span>
          <span class="mx-2">
            <span v-if="league.resThu"> <i class="fa-solid fa-check text-success"></i></span>
            <span v-else><i class="fa-solid fa-ban text-danger"></i></span>
            Do
          </span>
          <span class="mx-2">
            <span v-if="league.resFri"> <i class="fa-solid fa-check text-success"></i></span>
            <span v-else><i class="fa-solid fa-ban text-danger"></i></span>
            Fr
          </span>
          <span class="mx-2">
            <span v-if="league.resSat"> <i class="fa-solid fa-check text-success"></i></span>
            <span v-else><i class="fa-solid fa-ban text-danger"></i></span>
            Sa
          </span>
          <span class="mx-2">
            <span v-if="league.resSun"> <i class="fa-solid fa-check text-success"></i></span>
            <span v-else><i class="fa-solid fa-ban text-danger"></i></span>
            So
          </span>

        <div v-if="league.blockingDates.length === 1" class="col-12">
        <i class="fa-solid fa-calendar-days"></i> {{league.blockingDates.length}} Sperrtag
        </div>
        <div v-else-if="league.blockingDates.length > 1" class="col-12">
          <i class="fa-solid fa-calendar-days"></i> {{league.blockingDates.length}} Sperrtage
        </div>

        </p>
      </div>
    </div>

    <div class="btn-group-vertical col-3 col-sm-4 me-0 pe-0">
      <router-link :to="{name: 'leagueSettings', query:{liga: league.ligaId}}" class="btn btn-outline-secondary" v-if="!league.onlyHistory"><i class="fa-solid fa-gears"></i><span
          class="btn-description">&nbsp;Einstellungen</span></router-link>
      <button class="btn btn-secondary" @click="getFromTeamSL(league.ligaId)"><i class="fa-solid fa-down"></i><span
          class="btn-description">&nbsp;Update</span></button>
      <button class="btn btn-danger" @click="deleteLeague(league.ligaId)"><i class="fa-solid fa-down"></i><span
          class="btn-description">&nbsp;Löschen</span></button>
    </div>

  </li>
</template>

<script>

import {mapActions} from "vuex";
import axios from "axios";

export default {
  name: "LeagueListItem",
  emits: ['update'],
  props: ['league', 'index', 'id'],
  methods: {
    ...mapActions("league", ["updateLeagueTeamSL"]),
    ...mapActions('toast', ['add']),
    async getFromTeamSL(id){
         await this.updateLeagueTeamSL(id);
      await this.add({
        title: 'Erfolgreich',
        type: 'success',
        message: 'geupdated'
      })
      this.$emit('update')
      },
      async deleteLeague(id){
      if(confirm("Wirklich löschen?")){
        await axios.delete('leagues/' + id).catch(async res =>{
          if(res.status === 226){
            if(confirm("Es sind Spiele bereist verlegt worden, trotzdem löschen? ***ACHTUNG***")){
              await axios.delete('leagues/force/' + id)
            }
          }
        })
      }
        await this.add({
          title: 'Erfolgreich',
          type: 'danger',
          message: 'gelöscht'
        })
      this.$emit('update')

      }
  }
}
</script>

<style scoped>
.bg-blue{
  background-image:
       repeating-linear-gradient(45deg, 
                                white 0%, 
                                white 2%, rgb(255, 157, 0) 0.1%, 
                                rgb(255, 157, 0) 0.5%, white 4%);
  color: black;
}
</style>