<template>
    <hr/>
  <h3 class="my-3">
    <i class="fa-solid fa-ban"></i>
    &nbsp;Abgesagte Spiele
  </h3>
    <div class="row d-flex justify-content-center mb-5">
      <div class="col-10">
        <p>Abgesagte Spiele müssen vor dem Startdatum des Abgleiches liegen. Eine Verlegung von Abgesagten Spielen, wird
          mit E-Mail, also über die offizielle Team-SL Verlegefunktion (mit Auslösen der typischen Nachrichten) erzeugt,
          so dass hierfür eine Rechnung für eien Spielverlegung erzeugt werden kann.</p>
      </div>
      <div class="col-6">

    <div class="input-group flex-nowrap input-group-lg mb-4">
      <span class="input-group-text" id="addon-wrapping-canceled">&nbsp;#</span>
      <input type="text" v-model="form.abgesagt" class="form-control" placeholder="Abgesagt Spiel-ID" aria-label="Abgesagt SpielID" aria-describedby="addon-wrapping-canceled">
      </div>

    <button class="btn btn-outline-success" @click="submitAbgesagt">eintragen</button>
      </div>
    </div>


  <hr/>
  <h3 class="my-3">
    <i class="fa-solid fa-arrow-right-arrow-left"></i>
    &nbsp;Verlegungen in TeamSL übertragen
  </h3>
    <div class="row d-flex justify-content-center">
      <div class="col-6">
      <ol class="list-group list-group-numbered">
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Verlegungen</div>
            über Spielplantag
          </div>
          <span class="badge bg-primary rounded-pill">{{ length }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-start">
          <div class="ms-2 me-auto">
            <div class="fw-bold">Abgesagte Spiele</div>
            Verlegungen mit E-Mail
          </div>
          <span class="badge bg-primary rounded-pill">{{ lengthMail }}</span>
        </li>
      </ol>
        <button class="btn btn-outline-dark mt-4" @click="submit = !submit">in TeamSL eintragen</button>
        <div v-if="submit">
          <h5>TeamSL Zugangsdaten:</h5>
          <div class="input-group flex-nowrap input-group-lg mb-3">
            <span class="input-group-text" id="addon-wrapping-user">&nbsp;@</span>
            <input type="text" v-model="form.username" class="form-control" placeholder="Nutzername" aria-label="Nutzername" aria-describedby="addon-wrapping-user">
          </div>
          <div class="input-group flex-nowrap input-group-lg ">
            <span class="input-group-text" id="addon-wrapping-pass">&nbsp;#</span>
            <input type="password" v-model="form.password" class="form-control" placeholder="Passwort" aria-label="Passwort" aria-describedby="addon-wrapping-pass">
          </div>
          <button class="btn btn-outline-success" @click="submitForm">eintragen</button>
        </div>

        <div class="my-4"><i v-html="lastMessage"></i></div>
        <div v-if="done>0">
        <div class="progress mt-5">
          <div class="progress-bar" role="progressbar"  :style="`width: ${percent}%`" :aria-valuenow="done" aria-valuemin="0" :aria-valuemax="total">
            {{done}} / {{total}}</div>
        </div >


          <div class="history">
            <ul class="list-group">
              <template v-for="list in lastMessageHistory">
                <li class="list-group-item" v-html="list">
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import socket from "@/socket/socket";
import store from "@/store/store";

export default {
  name: "Spielplantag",
  components: {},
  data() {
    return {
      submit: false,
      status: [],
      lastMessage: null,
      lastMessageHistory: [],
      done: 0,
      total: 100,
      form:{
        username: null,
        password: null,
        abgesagt: null
      }
    };
  },
  computed: {
  length(){
    if(!this.status.verlegungen){
      return 0
    }
    return this.status.verlegungen.length - this.lengthMail
  },
  lengthMail(){
    if(!this.status.verlegungen){
      return 0
    }
    return this.status.verlegungen.filter(game => game.result === "reschedule").length
  },
    percent(){
    return parseInt((this.done / this.total) * 100)
    }
  },
  methods: {
    async submitForm(){
      if(confirm("Wirklich eintragen?")){
        await axios.post('/administrator/teamsl', {username: this.form.username, password: this.form.password}).catch(()=>{})
      }
    },
    async submitAbgesagt(){
      if(confirm("Wirklich eintragen?")){
        await axios.post('/administrator/abgesagt', {abgesagt: this.form.abgesagt}).then((res) => {
          store.dispatch('toast/add', {
            title: res.data.title,
            type: res.data.type,
            message: res.data.message,
          })}).catch(()=>{})
      }
    }
  },
  async mounted() {
    const status = await axios.get('/administrator/status').catch(()=>{})
    this.status = status.data
    socket.on('adminStatus',(res) => {
      if(res.done){
        this.done = parseInt(res.done)
      }
      if(res.total){
        this.total = parseInt(res.total)
      }
      if(res.lastMessage){
        if(this.lastMessage){
          this.lastMessageHistory = [this.lastMessage, ...this.lastMessageHistory]
        }
        this.lastMessage = res.lastMessage
      }
    })
  },
};
</script>

<style scoped>
.history{
  max-height: 300px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
}

</style>
