import store from '@/store/store'
import axios from 'axios'
import router from '@/router/router'
import socket from "@/socket/socket";


export default {
    namespaced: true,
    state: {
        filter: {
            gym: [],
            date: [],
            search: "",
            canceled: false,
            accepted: false,
            declined: false,
            open: false,
            calledBack: false,
            spt: false,
            message: false,
            unread: false,
            preFilter: []
        }

    },
    getters: {
        getFilter(state) {
            return state.filter
        },
    },
    mutations: {
        setFilter(state, filter) {
            state.filter = {...state.filter, ...filter}
        }
    }
}
