import {createStore} from "vuex";
import auth from "@/store/auth.store";
import league from "@/store/league.store";
import club from "@/store/club.store";
import toast from "@/store/toast.store";
import match from "@/store/match.store";
import route from "@/store/route.store";
import gym from "@/store/gym.store";
import filter from "./filter.store";

export default createStore({
    modules: {
        auth,
        league,
        match,
        club,
        route,
        toast,
        gym,
        filter
    }
});
