<template>
  <div class="wrapper">
    <h2 class="text-start">
      <i class="fa-solid fa-address-card"></i>
      &nbsp;Benutzerprofil
    </h2>
    <div class="list-wrapper mt-5">
      <div class="list-group list-group-light justify-content-between searchable-list">
        <ul class="list-group">
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 me-auto">
              <div class="fw-bold text-start">E-Mail</div>
            </div>
            <div class="">{{ user.email }}</div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 my-auto flex-fill">
              <div class="fw-bold text-start align-middle">Vorname</div>
            </div>
            <div class="flex-fill"></div>
            <div class="flex-fill">
              <div class="input-group">
                <input
                    v-model="firstName"
                    aria-describedby="button-save"
                    class="form-control text-end"
                    placeholder="Vorname"
                    type="text"
                    v-on:keyup.enter="updateUser()"
                />
                <button
                    v-show="firstName != user.firstName"
                    class="btn btn-outline-dark"
                    type="button-save"
                    @click="updateUser()"
                >
                  Speichern
                </button>
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 me-auto my-auto flex-fill">
              <div class="fw-bold text-start align-middle">Nachname</div>
            </div>
            <div class="flex-fill"></div>
            <div class="flex-fill">
              <div class="input-group">
                <input
                    v-model="lastName"
                    aria-describedby="button-save"
                    class="form-control text-end"
                    placeholder="Nachname"
                    type="text"
                    v-on:keyup.enter="updateUser()"
                />
                <button
                    v-show="lastName != user.lastName"
                    class="btn btn-outline-dark"
                    type="button-save"
                    @click="updateUser()"
                >
                  Speichern
                </button>
              </div>
            </div>
          </li>
          <h3 class="my-3">
            <i class="fa-solid fa-envelope"></i>
            &nbsp;Benachrichtigungen
          </h3>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 me-auto my-auto">
              <div class="fw-bold text-start align-middle">
                E-Mail Benachrichtigung bei Verlegungen
              </div>
            </div>

            <div class="align-middle mb-1">
              <div class="form-check form-switch form-switch-xl my-auto">
                <input
                    v-model="verlegung"
                    class="form-check-input"
                    type="checkbox"
                    @change="updateUser"
                />
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 me-auto my-auto">
              <div class="fw-bold text-start align-middle">
                E-Mail Benachrichtigung bei Nachrichten
              </div>
            </div>

            <div class="align-middle mb-1">
              <div class="form-check form-switch form-switch-xl my-auto">
                <input
                    v-model="message"
                    class="form-check-input"
                    type="checkbox"
                    @change="updateUser"
                />
              </div>
            </div>
          </li>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 me-auto my-auto">
              <div class="fw-bold text-start align-middle">
                Benachrichtigungen (In-App) per Banner
              </div>
            </div>

            <div class="align-middle mb-1">
              <div class="form-check form-switch form-switch-xl my-auto">
                <input
                    v-model="showToast"
                    class="form-check-input"
                    type="checkbox"
                    @change="updateUser"
                />
              </div>
            </div>
          </li>
          <h3 class="my-3">
            <i class="fa fa-phone-volume"></i>
            &nbsp;Kontaktinfos
          </h3>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 me-auto my-auto">
              <div class="fw-bold text-start align-middle">
                Kontaktinfos dem Spielpartner anzeigen?
              </div>
            </div>

            <div class="align-middle mb-1">
              <div class="form-check form-switch form-switch-xl my-auto">
                <input
                    v-model="contactVisible"
                    class="form-check-input"
                    type="checkbox"
                    @change="updateUser"
                />
              </div>
            </div>
          </li>
          <div v-if="contactVisible" class="mb-5">
            <li
                class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
            >
              <div class="ms-2 my-auto flex-fill">
                <div class="fw-bold text-start align-middle">Telefonnummer</div>
              </div>
              <div class="flex-fill"></div>
              <div class="flex-fill">
                <div class="input-group">
                  <input
                      v-model="contactPhone"
                      aria-describedby="button-save"
                      class="form-control text-end"
                      placeholder="Telefonnummer"
                      type="text"
                      v-on:keyup.enter="updateUser()"
                  />
                  <button
                      v-show="contactPhone != user.contactPhone"
                      class="btn btn-outline-dark"
                      type="button-save"
                      @click="updateUser()"
                  >
                    Speichern
                  </button>
                </div>
              </div>
            </li>
            <li
                v-if="contactPhone != ''"
                class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
            >
              <div class="ms-2 my-auto">
                <div class="fw-bold text-start align-middle text-center">
                  <i class="fa-brands fa-whatsapp fa-xl" style="font-size: xx-large"></i>
                  Whatsapp-Messenger
                </div>
              </div>

              <div class="align-middle mb-1 text-end">
                <div
                    class="form-check form-switch form-switch-lg my-auto mt-2 align-middle"
                >
                  <input
                      v-model="contactWhatsapp"
                      class="form-check-input"
                      type="checkbox"
                      @change="updateUser"
                  />
                </div>
              </div>
            </li>
            <h5>
              <i class="fa-solid fa-circle-info"></i>&nbsp;Kontaktinformationen für den
              Spielpartner
            </h5>
            <li
                class="list-group-item d-flex justify-content-between align-items-start mb-5 p-2 shadow border border-dark"
            >
              <div class="align-middle mb-1 flex-fill">
                <div class="form-check form-switch form-switch-xl my-auto">
                  <div class="input-group">
                    <textarea class="form-control" v-model="contactInfo"></textarea>
                    <button
                        v-if="contactInfo != user.contactInfo"
                        class="btn btn-outline-secondary"
                        type="button"
                        @click="updateUser()"
                    >
                      Speichern
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </div>
          <h3 class="my-3">
            <i class="fa fa-gear"></i>
            &nbsp;Einstellungen
          </h3>
          <li
              class="list-group-item d-flex justify-content-between align-items-start mb-2 p-2 shadow border border-dark"
          >
            <div class="ms-2 my-auto flex-fill">
              <div class="fw-bold text-start align-middle">Anzahl an Items pro Seite</div>
            </div>
            <div class="flex-fill"></div>
            <div class="flex-fill">
              <div class="input-group">
                <input
                    ref="numberInput"
                    v-model="itemsPerPage"
                    aria-describedby="button-save"
                    class="form-control text-end"
                    placeholder="Anzahl"
                    type="number"
                    min="10"
                    max="100"
                    step="5"
                    @change="updateUser"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";


export default {
  name: "UserProfile",
  data() {
    return {
      firstName: null,
      lastName: null,
      message: false,
      verlegung: false,
      showToast: false,
      contactVisible: false,
      contactPhone: null,
      contactWhatsapp: false,
      contactInfo: null,
      itemsPerPage: null
    };
  },
  computed: {
    ...mapGetters({user: "auth/getUser"}),
  },
  mounted() {
    this.$refs["numberInput"].addEventListener("keypress", (event) => {
      event.preventDefault();
    });
    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.message =
        this.user.emailNotificationMessage == null
            ? false
            : this.user.emailNotificationMessage;
    this.verlegung =
        this.user.emailNotificationVerlegung == null
            ? false
            : this.user.emailNotificationVerlegung;
    this.contactPhone = this.user.contactPhone;
    this.showToast = this.user.showToast == null ? false : this.user.showToast;
    this.contactWhatsapp = this.user.contactWhatsapp;
    this.contactVisible = this.user.contactVisible;
    this.contactInfo = this.user.contactInfo;
    this.itemsPerPage = this.user.optionItemsOnPage;
  },
  methods: {
    ...mapActions({update: "auth/update"}),
    async updateUser() {
      if (this.contactPhone == "") {
        this.contactWhatsapp = false;
      }
      this.update({
        firstName: this.firstName,
        lastName: this.lastName,
        showToast: this.showToast,
        emailNotificationMessage: this.message,
        emailNotificationVerlegung: this.verlegung,
        contactVisible: this.contactVisible,
        contactPhone: this.contactPhone,
        contactWhatsapp: this.contactWhatsapp,
        contactInfo: this.contactInfo,
        optionItemsOnPage: this.itemsPerPage
      });
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  label {
    min-width: 10px;
    text-align: end;
  }
}
</style>
