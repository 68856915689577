<template>
  <div class="row justify-content-center">
    <div class="col-lg-6">
      <ul class="list-group  list-group-flush justify-content-center">
        <BlockingListItem v-for="(element, index) in dates" :id="element._id" :key="index"
                          :date="element.date" :ids="ids" :info="element.info" :setting="setting" :type="element.type"
                          v-on:change="$emit('change')"/>
      </ul>
    </div>
  </div>

</template>

<script>
import BlockingListItem from "@/components/Helper/BlockingList/Item/Item";

export default {
  name: 'BlockingList',
  emits: ['change'],
  props:
      {
        dates: Object,
        setting: Boolean,
        ids: {
          required: false,
          type: Array
        },
      },
  components: {BlockingListItem}
};
</script>
