<template>
  <form @submit.prevent="submit">
    <div class="row justify-content-center">
      <div class="col-lg-12 mb-3">
        <img class="img-responsive center-block d-block mx-auto img-logo-bbv"
             src="@/assets/images/bbv_puls_logo_cymk.png">
      </div>
      <div class="col-8">
        <h2 class="h3 mb-3 font-weight-normal"><i class="fa-solid fa-lock-open"></i> Bitte einloggen</h2>
        <div class="input-group mb-3">
          <input v-model="form.email" autocomplete="email" autofocus
                 class="form-control" placeholder="E-Mail" required type="email">
        </div>
        <div class="input-group mb-3">
          <input v-model="form.password" autocomplete="current-password" class="form-control" placeholder="Password"
                 required type="password">

        </div>

        <div class="checkbox mb-3">
          <router-link :to="{ name: 'forget'}">Passwort vergessen?</router-link>
        </div>

        <button class="btn btn-lg btn-primary" type="submit">
          Login
        </button>

      </div>
    </div>
  </form>
</template>

<script>
import store from '@/store/store'
export default {
  name: "LoginForm",
  data() {
    return {
      form: {
        email: null,
        password: null      }
    };
  },
  methods: {
    async submit() {
      await store.dispatch("auth/login", this.form);
    }
  }
};
</script>

<style scoped>

</style>