<template>
  <div v-if="preFilterVisible" class="container mb-5">
    <v-select v-model="filter.preFilter" :get-option-label="(option) => option.teamname + ' - ' + option.liganame"
              :options="getMyTeams" class="mb-5" multiple placeholder="Meine Team - Vorauswahl"
              @update:modelValue="loadData"/>
  </div>
  <div class="container mb-2">
    <div class="row">
      <div class="col-12 col-md-6">
        <v-select v-model="filter.gym" :options="possibleFilter.gyms" label="bezeichnung" multiple placeholder="Halle"
                  @update:modelValue="loadData"/>
      </div>
      <div class="col-12 col-md-6">
        <v-select v-model="filter.date" :options="possibleFilter.date" label="german" @update:modelValue="loadData"
                  multiple placeholder="Datum"/>
      </div>
    </div>
    <div class="input-group my-3">
      <input v-model="filter.search" class="form-control" placeholder="Suche" type="text" v-debounce:400ms="loadData">
      <div class="input-group-append">
        <button class="btn btn-outline-secondary" type="button" disabled><i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
    <div class="mt-3" role="group">

      <input v-if="possibleFilter.canceled || filter.canceled" id="canceled" v-model="filter.canceled"
             autocomplete="off" @change="loadData" class="btn-check"
             type="checkbox">
      <label v-if="possibleFilter.canceled || filter.canceled" class="btn btn-outline-dark float-left mt-2 "
             for="canceled"><i
          class="fa-solid fa-triangle-exclamation"></i> Abgesagt - zu terminieren</label>

      <input v-if="possibleFilter.accepted || filter.accepted" id="accepted" v-model="filter.accepted"
             autocomplete="off" @change="loadData" class="btn-check"
             type="checkbox">
      <label v-if="possibleFilter.accepted|| filter.accepted" class="btn btn-outline-dark float-left mt-2"
             for="accepted"><i
          class="fa-solid fa-arrow-right-arrow-left"></i> Verlegt</label>

      <input v-if="possibleFilter.declined || filter.declined" id="declined" v-model="filter.declined"
             autocomplete="off" @change="loadData" class="btn-check"
             type="checkbox">
      <label v-if="possibleFilter.declined || filter.declined" class="btn btn-outline-dark float-left mt-2"
             for="declined"><i
          class="fa-solid fa-circle-xmark"></i>
        Abgelehnt</label>

      <input v-if="possibleFilter.open || filter.open" id="open" v-model="filter.open" autocomplete="off"
             class="btn-check" @change="loadData" type="checkbox">
      <label v-if="possibleFilter.open ||filter.open " class="btn btn-outline-dark float-left mt-2" for="open"> <i
          class="fa-solid fa-code-pull-request"></i>
        Offen</label>

      <input v-if="possibleFilter.calledBack || filter.calledBack" id="calledBack" v-model="filter.calledBack"
             autocomplete="off" @change="loadData" class="btn-check"
             type="checkbox">
      <label v-if="possibleFilter.calledBack || filter.calledBack" class="btn btn-outline-dark float-left mt-2"
             for="calledBack"> <i
          class="fa-solid fa-clock-rotate-left"></i>
        Zurückgezogen</label>

      <input v-if="possibleFilter.spt || filter.spt" id="spt" v-model="filter.spt" autocomplete="off" class="btn-check"
             @change="loadData" type="checkbox">
      <label v-if="possibleFilter.spt|| filter.spt" class="btn btn-outline-dark float-left mt-2" for="spt"><i
          class="fa-solid fa-triangle-exclamation"></i>
        Spielplantag</label>

      <input v-if="possibleFilter.message|| filter.message" id="message" v-model="filter.message" autocomplete="off"
             class="btn-check" @change="loadData"
             type="checkbox">
      <label v-if="possibleFilter.message|| filter.message" class="btn btn-outline-dark float-left mt-2"
             for="message"><i
          class="fa-solid fa-comments"></i>
        Nachrichten</label>

      <input v-if="possibleFilter.unread|| filter.unread" id="unread" v-model="filter.unread" autocomplete="off"
             class="btn-check" @change="loadData"
             type="checkbox">
      <label v-if="possibleFilter.unread|| filter.unread" class="btn btn-outline-dark float-left mt-2" for="unread"><i
          class="fa-solid fa-envelope"></i>
        Ungelesen</label>

      <input id="download" autocomplete="off" class="btn-check" type="checkbox">
      <label class="btn btn-outline-dark float-left mt-2" for="download">
        <download-excel :fetch="fetch" :before-generate="() => this.isLoading = true"
                        :before-finish="() => this.isLoading = false"
                        :fields="getExportFields" :name="filename" worksheet="Spielplan"><i
            class="fa-solid fa-download"></i>
          Excel
        </download-excel>
      </label>

      <input v-if="anyFilter" id="filter" autocomplete="off" class="btn-check" type="checkbox"
             @click="reset">
      <label v-if="anyFilter" class="btn btn-outline-danger float-left mt-2" for="filter"><i
          class="fa-solid fa-eraser"></i>
        Filter löschen</label>

    </div>
  </div>
  <br/>

  <vue-good-table
      :columns="[ {
          field: 'matchId'
        }]"
      :isLoading.sync="isLoading"
      :pagination-options="pagination"
      :rows="getGamesFromState"
      :totalRows="totalRecords"
      :rowsPerPageText="'Spiele pro Seite'"
      :prevText="'Zurück'"
      :nextText="'Weiter'"
      mode="remote"
      styleClass="vgt-table"
      theme="polar-bear"

      v-on:page-change="onPageChange"
      v-on:per-page-change="onPerPageChange">
    <template #emptystate>
      <i class="fa-solid fa-magnifying-glass"></i> Es wurden keine Spiele gefunden.
    </template>
    <template #table-row="props">
      <GamesListItem :key="props.row.matchId" :game="props.row" @update="update"/>
    </template>
    <template #loadingContent>
      <div class="spinner-border" role="status" style="width: 5rem; height: 5rem;">
        <span class="visually-hidden">Loading...</span>
      </div>
    </template>

  </vue-good-table>
</template>

<script>
import GamesListItem from "@/components/Shared/Games/List/Item/GamesListItem";
import {mapGetters, mapMutations} from "vuex";
import axios from "axios";
import {toFormatedDate, toGermanDate} from "@/helper/date.helper";
import socket from "@/socket/socket";




export default {
  name: "GamesList",
  components: {
    GamesListItem
  },
  data() {
    return {
      isConfirmed: false,
      getGamesFromState: [],
      totalRecords: 0,
      currentPage: 1,
      isLoading: false,
      pagination: {
        enabled: true,
        perPage: 10,
        nextLabel: 'Weiter',
        prevLabel: 'Vor',
        rowsPerPageLabel: 'Spiele pro Seite',
        ofLabel: 'von',
        pageLabel: 'Seite', // for 'pages' mode
        allLabel: 'Alle'
      },
      possibleFilter: {
        gyms: [],
        date: [],
        canceled: false,
        accepted: false,
        declined: false,
        open: false,
        calledBack: false,
        spt: false,
        message: false,
        unread: false
      },
      filter: {
        gym: [],
        date: [],
        search: "",
        canceled: false,
        accepted: false,
        declined: false,
        open: false,
        calledBack: false,
        spt: false,
        message: false,
        unread: false,
        preFilter: []
      }
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('filter', ['getFilter']),
    anyFilter() {
      return this.filter && this.filter.gym.length > 0 ||
          this.filter.date.length > 0 ||
          this.filter.search ||
          this.filter.canceled ||
          this.filter.accepted ||
          this.filter.declined ||
          this.filter.open ||
          this.filter.calledBack ||
          this.filter.spt ||
          this.filter.message ||
          this.filter.unread
    },
    getExportFields() {
      return {
        "Datum": {
          field: "kickoffDate",
          callback: (value) => toGermanDate(value)
        },
        "Hallenbezeichnung": "gymBezeichnung",
        "Zeit": "kickoffTime",
        "Liganame": "liganame",
        "Nr.": "matchNo",
        "Heimmannschaft": "homeTeamTeamname",
        "Gastmannschaft": "guestTeamTeamname",
      }
    },
    filename() {
      return 'spielplan-' + toFormatedDate((new Date()).toString()) + '.xls'
    },
    preFilterVisible() {
      return this.getMyTeams.length > 1
    },
    getMyTeams() {
      return this.getUser.myTeams
    },
  },
  methods: {
    ...mapMutations('filter', ['setFilter']),
    confirm1() {
      confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          console.log("YES")
        },
        reject: () => {
          console.log("NO")
        }
      })
    },
    async fetch() {
      if (confirm("Aktuelle Filter nach Excel exportieren? Für alle möglichen Spiele bitte ABBRECHEN wählen!")) {
        return await axios.post('/match/page', {
          limit: 0,
          skip: 0,
          filter: this.filter
        }).then(res => res.data.matches)
      }
      if (confirm("Alle möglichen Spiele nach Excel exportieren?")) {
        return await axios.post('/match/page', {
          limit: 0,
          skip: 0,
          filter: {
            gym: [],
            date: [],
            search: "",
            canceled: false,
            accepted: false,
            declined: false,
            open: false,
            calledBack: false,
            spt: false,
            message: false,
            unread: false,
            preFilter: []
          }
        }).then(res => res.data.matches)
      }
    },
    reset() {
      this.resetFilter()
      this.loadData()
    },
    resetFilter() {
      this.filter = {
        gym: [],
        date: [],
        search: "",
        canceled: false,
        accepted: false,
        declined: false,
        open: false,
        calledBack: false,
        spt: false,
        message: false,
        unread: false,
        preFilter: []
      }
    },
    async onPerPageChange(params) {
      this.isLoading = true
      this.pagination.perPage = params.currentPerPage
      this.currentPage = 1
      await this.loadData();
      this.isLoading = false
    },
    async onPageChange(params) {
      this.isLoading = true
      this.currentPage = params.currentPage
      await this.loadData();
      this.isLoading = false
    },
    update(){
      this.loadData()
    },
    async loadData() {
      this.isLoading = true;
      this.setFilter(this.filter)
      const res = await axios.post('/match/page', {
        limit: this.pagination.perPage,
        skip: (this.currentPage - 1) * this.pagination.perPage,
        filter: this.filter
      })
      this.totalRecords = res.data.total
      this.getGamesFromState = res.data.matches
      this.possibleFilter = res.data.possibleFilter
      this.isLoading = false
    }
  },
  async mounted() {
    this.filter = this.getFilter
    await this.loadData()
    socket.on('answerAdmin', () => this.loadData())
    socket.on('reschedule', (res) => this.loadData())
    socket.on('answer', (res) => this.loadData())
    socket.on('chat', (res) => this.loadData())
  },
  beforeMount() {
    this.pagination.perPage = this.getUser ? this.getUser.optionItemsOnPage : 10;
  },
  watch: {
    async getFilter() {
      if (this.getFilter.unread === "unread") {
        this.resetFilter()
        this.filter.unread = true
        await this.loadData()
      }
      if (this.getFilter.open === "open") {
        this.resetFilter()
        this.filter.open = true
        await this.loadData()
      }
    }
  }
}
</script>

<style>
thead {
  display: none !important;
}

table,
tbody,
tr {
  border: none !important;
}

table.vgt-table td {
  padding: 0 !important;
  border: none !important;
}

.vgt-responsive {
  overflow-x: hidden !important;
}
</style>