<template>
  <div class="list-group-item rounded mb-3 mb-lg-1 shadow border border-dark p-0 w-100">
    <div class="align-items-center container">
      <span class="top-right bg-dark text-light">{{ game.akName }}</span>
      <span class="top-left bg-dark text-light">{{ game.liganame }}</span>


      <div class="p-3 row mb-1 mt-5 rounded border-bottom">
        <div class="col-5">
          <p class="fw-bold mb-0 text-end">
            <i class="fa-solid fa-house"></i>
            &nbsp;{{ game.homeTeamTeamname }}
          </p>
        </div>
        <div class="col-2 mb-0 align-middle h-100 text-center">
          <i class="fa-solid fa-arrow-right-arrow-left"></i>
        </div>
        <div class="col-5">
          <p class="fw-bold mb-0 text-start">
            <i class="fa-solid fa-suitcase"></i>
            &nbsp;{{ game.guestTeamTeamname }}
          </p>
        </div>
      </div>
    </div>
    <div class="justify-content-between align-items-center row">
      <div class="col-12 text-center">
        <i class="fa-solid fa-house"></i> {{ game.gymBezeichnung }}
      </div>
      <div class="justify-content-between align-items-center row mb-3">
        <div class="col col-9 col-lg-10 justify-content-center">
          <div class="px-3 row justify-content-center">
            <div class="col-lg-4">
              <p class="mb-1">
                <i class="fa-solid fa-hashtag"></i>
                &nbsp;{{ game.matchNo }}
              </p>
            </div>
            <div class="col-lg-4">
              <p class="mb-1">
                <i class="fa-solid fa-calendar-days"></i>
                &nbsp;{{ date }}
              </p>
            </div>
            <div class="col-lg-4 mb-5">
              <p class="mb-1">
                <i class="fa-solid fa-clock"></i>
                &nbsp;{{ game.kickoffTime }}
              </p>
            </div>
          </div>
        </div>
        <div class="btn-group-vertical col-3 col-lg-2 ">
          <router-link :to="{ name: 'reschedule', params: { gameId: game.matchId } }" class="btn btn-outline-secondary">
            <i class="fa-solid fa-arrow-right-arrow-left"></i>
            <span v-if="game.open" class="btn-description">&nbsp;
              anzeigen
            </span>
            <span v-else-if="game.accepted" class="btn-description">&nbsp;
              neu verlegen
            </span>
            <span v-else class="btn-description">&nbsp;
              verlegen
            </span>
          </router-link>
          <button class="btn btn-outline-danger" v-if="isAdmin && !game.deleted" @click="adminDelete">Spiel ausblenden</button>
          <button class="btn btn-outline-danger" v-if="isAdmin && game.deleted" @click="adminUnDelete">Spiel ausblenden rückgängig</button>
        </div>
      </div>
      <router-link :to="{ name: 'reschedule', params: { gameId: game.matchId } }" v-if="game.open"
        class="bottom-left text-decoration-none  orange-event draft">
        <span v-if="game.unread"
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white border border-2 border-white"><i
            class="fa-solid fa-envelope"></i> Neu
          <span class="visually-hidden">New alerts</span></span>
        <i class="fa-solid fa-code-pull-request"></i> Offen</router-link>
      <router-link :to="{ name: 'reschedule', params: { gameId: game.matchId } }" v-else-if="game.spt"
        class="bottom-left text-decoration-none  yellow-event draft">
        <span v-if="game.unread"
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white border border-2 border-white"><i
            class="fa-solid fa-envelope"></i> Neu
          <span class="visually-hidden">New alerts</span></span>
        <i class="fa-solid fa-triangle-exclamation"></i> Spielplantag</router-link>
      <router-link :to="{ name: 'reschedule', params: { gameId: game.matchId } }" v-else-if="game.canceled"
        class="bottom-left text-decoration-none  red-event draft">
        <span v-if="game.unread"
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white border border-2 border-white"><i
            class="fa-solid fa-envelope"></i> Neu
          <span class="visually-hidden">New alerts</span></span>
        <i class="fa-solid fa-triangle-exclamation"></i> Abgesagt - zu terminieren</router-link>
      <router-link :to="{ name: 'reschedule', params: { gameId: game.matchId } }" v-else-if="game.declined"
        class="bottom-left text-decoration-none  grey-event draft">
        <span v-if="game.unread"
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white border border-2 border-white"><i
            class="fa-solid fa-envelope"></i> Neu
          <span class="visually-hidden">New alerts</span></span>
        <i class="fa-solid fa-circle-xmark"></i> Abgelehnt</router-link>
      <router-link :to="{ name: 'reschedule', params: { gameId: game.matchId } }" v-else-if="game.accepted"
        class="bottom-left text-decoration-none  green-event draft">
        <span v-if="game.unread"
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white border border-2 border-white"><i
            class="fa-solid fa-envelope"></i> Neu
          <span class="visually-hidden">New alerts</span></span>
        <i class="fa-solid fa-arrow-right-arrow-left"></i> Verlegt</router-link>
      <router-link :to="{ name: 'reschedule', params: { gameId: game.matchId } }" v-else-if="game.message"
        class="bottom-left text-decoration-none  grey-event draft">Nachrichten
        <span v-if="game.unread"
          class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger text-white border border-2 border-white"><i
            class="fa-solid fa-envelope"></i> Neu
          <span class="visually-hidden">New alerts</span>
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>

import { toGermanDate } from "@/helper/date.helper";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: 'GamesListItem',
  props: ['game'],
  emits: ['update'],
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    date() {
      return toGermanDate(this.game.kickoffDate)
    },
    open() {
      return this.game.open
    }
  },
  methods:{
    async adminDelete(){
      if(confirm("Spiel wirklich löschen?")){
        await axios.delete('match/' + this.game.matchId)
        this.$emit("update")
      }
    },
    async adminUnDelete(){
      if(confirm("Spiel wirklich löschen?")){
        await axios.delete('match/undelete/' + this.game.matchId)
        this.$emit("update")
      }
    }
  }
}
</script>

<style scoped>
.grey-event {
  background: repeating-linear-gradient(45deg,
      rgba(20, 20, 20, 0.1),
      white 10px);
  color: black;
  border-bottom: solid 0px;
  border-left: solid 0px;
  border-color: black;
  border-style: dashed;
}

.orange-event {
  background: rgb(194, 86, 6);
  color: white;
  border-bottom: solid 0px;
  border-left: solid 0px;
  border-color: black;
  border-style: dashed;
}

.red-event {
  background: rgb(255, 0, 0);
  color: white;
  border-bottom: solid 0px;
  border-left: solid 0px;
  border-color: black;
  border-style: dashed;
}

.green-event {
  background: rgb(21, 132, 43);
  color: white;

  border-bottom: solid 0px;
  border-left: solid 0px;
  border-color: black;
  border-style: dashed;
}

.yellow-event {
  background: #ffca2c;
  color: black;
  border-bottom: solid 0px;
  border-left: solid 0px;
  border-color: black;
  border-style: dashed;
}</style>
