<template>
  <div>
    <div class="list-wrapper">
      <div class="list-group list-group-light justify-content-between searchable-list">
        <div class="col-12 mb-5">
          <h5>
            <button class="btn btn-outline-primary" @click="add = !add">
              <i class="fa-solid fa-file-circle-plus"></i>
              &nbsp;Teamverantwortlichen hinzufügen
            </button>
          </h5>

          <div  v-if="add">
            <v-select
                v-model="form.teams"
                :options="options"
                :get-option-label="(option) => option.teamname + ' - ' + option.liganame"
                multiple
                placeholder="Mannschaften wählen"
                class="form-control mb-5"
            />
            <div class="input-group mb-3">
            <input
                v-model="form.firstName"
                :class="{ 'is-valid': validation.firstName }"
                class="form-control"
                placeholder="Vorname"
                type="text"
            />
            <input
                v-model="form.lastName"
                :class="{ 'is-valid': validation.lastName }"
                class="form-control"
                placeholder="Nachname"
                type="text"
            />
            <input
                v-model="form.email"
                :class="{
                'is-valid': validation.email,
                'is-invalid': validation.email === false,
              }"
                class="form-control"
                placeholder="E-Mail"
                type="email"
            />
            <button
                v-if="
                validation.email &&
                validation.firstName &&
                validation.lastName &&
                validation.teams
              "
                class="btn btn-outline-secondary"
                type="button"
                @click="register()"
            >
              Speichern
            </button>
            <div
                v-if="
                validation.email &&
                validation.firstName &&
                validation.lastName &&
                validation.teams &&
                !validation.already
              "
                class="valid-feedback"
            >
              Klicke auf Speichern zum hinzufügen!
            </div>
            <div class="invalid-feedback" v-if="validation.already">
              Die E-Mail-Adresse wurde bereits verwendet, bitte eine andere wählen!
            </div>
          </div>
          </div>
        </div>
        <div class="list-wrapper mb-2">
          <vue-good-table
              :columns="columns"
              :pagination-options="pagination"
              :rows="user"
              :search-options="search"
              styleClass="vgt-table"
              theme="polar-bear"
          >
            <template #emptystate>
              <i class="fa-solid fa-magnifying-glass"></i> Es wurden keine Teamverantwortlichen gefunden.
            </template>
            <template #table-row="props">
              <ClubUserListItem
                  :user="props.row"
                  :clubId="clubDetails.vereinId"
                  :options="options"
              />
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClubUserListItem from "@/components/Shared/User/Item/ClubUserListItem";
import {mapActions, mapGetters, mapMutations} from "vuex";
import EmailValiidator from "email-validator";
import {pagination, search} from "@/config/datatable.config";

export default {
  name: "ClubUserList",
  props: ["club", "clubDetails"],
  inject: ["sortFunction"],
  components: {
    ClubUserListItem,

  },
  data() {
    return {
      page: 1,
      add: false,
      columns:[{
        name:"email",
        field: "email"
      },
        {
          field:"firstName",
          hidden:true
        },
        {
          field:"lastName",
          hidden:true
        }
      ],
      form: {
        teams: null,
        firstName: null,
        lastName: null,
        email: null,
      },
      validation: {
        firstName: null,
        lastName: null,
        email: null,
        teams: null,
      },
      already: false,
    };
  },
  computed: {
    ...mapGetters("club", ["getClubById", "getUser"]),
    ...mapGetters('auth', {myUser: 'getUser'}),
    user(){
      return this.getUser(this.club)
    },
    options(){
      return this.clubDetails.teams.sort((a,b) => a.teamname.localeCompare(b.teamname) || a.liganame.localeCompare(b.liganame) )
    },
    pagination(){
      return this.user.length > 10 ? pagination: {enabled:false}
    },
    search(){
      return this.user.length > 0 ? search: {enabled:false}
    }
  },
  methods: {
    ...mapActions("club", ["loadClubById", "registerUser"]),
    async register() {
      this.form.email = this.form.email.toLowerCase();
      await this.registerUser({
        teams: this.form.teams,
        email: this.form.email,
        firstName: this.form.firstName,
        lastName: this.form.lastName,
      })
          .then(() => this.loadClubById(this.club))
          .finally(() => {
            this.add = false
            this.form.teams = [];
            this.form.email = "";
            this.form.firstName = "";
            this.form.lastName = "";
            this.validation.firstName = null;
            this.validation.lastName = null;
            this.validation.teams = null;
            this.validation.email = null;
            this.validation.already = false;
          });
    },
  },
  watch: {
    "form.firstName"() {
      if (this.form.firstName == "") {
        this.validation.firstName = null;
      } else {
        this.validation.firstName = true;
      }
    },
    "form.lastName"() {
      if (this.form.lastName == "") {
        this.validation.lastName = null;
      } else {
        this.validation.lastName = true;
      }
    },
    async "form.email"() {
      if (this.form.email == "") {
        this.validation.email = null;
        this.validation.already = false;
      } else {
        if (EmailValiidator.validate(this.form.email)) {
            this.validation.email = true;
            this.validation.already = false;
        } else {
          this.validation.email = false;
          this.validation.already = false;
        }
      }
    },
    "form.teams"() {
      if (this.form.teams == null || this.form.teams.length == 0) {
        this.validation.teams = null;
      } else if (this.form.teams.length > 0) {
        this.validation.teams = true;
      }
    },
  },
};
</script>

<style scoped></style>
