<template>
  <li
      class="list-group-item d-flex justify-content-between align-items-center row mx-2 mb-1 shadow-sm border border-dark">
    <div class="align-items-center col-8 col-sm-7">
      <div class="p-3">
        <p class="fw-bold mb-1 searchable-name text-start"> {{ club.vereinsname }}</p>
        <p class="text-muted mb-0 list-search-item text-start"><i
            class="fa-solid fa-signature"></i>&nbsp;{{ club.kurzname }}
        </p>
        <p class="text-muted mb-0 list-search-item text-start"><i
            class="fa-solid fa-fingerprint"></i>&nbsp;{{ club.vereinsnummer }}</p>
      </div>
    </div>

    <div class="btn-group-vertical col-3 col-sm-4 me-0 pe-0">
      <router-link :to="{name: 'clubDetail', params: {club: club.vereinId}}"
                   class="btn btn-outline-dark"><i class="fa-solid fa-list"></i><span
          class="btn-description">&nbsp;Details</span></router-link>
    </div>
  </li>
</template>

<script>

export default {
  name: "ClubsListItem",
  props: ["club"],
  methods: {}
}
</script>

<style scoped>

</style>