<template>
  <div class="btn-group mb-3">
    <a class="btn btn-outline-secondary" @click="add = !add" :class="{active : add}">
      <i class="fa-solid fa-plus"></i>
      <span class="btn-description">&nbsp;Hinzufügen</span>
    </a>
  </div>
  <div v-if="add">
    <div class="input-group mb3">
      <input
          v-model="form.bezeichnung"
          class="form-control"
          placeholder="Bezeichnung"
          type="text"
      />
      <input
          v-model="form.gymId"
          class="form-control"
          placeholder="gymId"
          type="number"
      />
    </div>
    <div class="input-group mb-3 mt-3" >
      <input
          v-model="form.strasse"
          class="form-control"
          placeholder="Straße"
          type="text"
      />
      <input
          v-model="form.plz"
          class="form-control"
          placeholder="PLZ"
          type="text"
      />
      <input
          v-model="form.ort"
          class="form-control"
          placeholder="Ort"
          type="text"
      />
      <button
          v-if="form.bezeichnung && form.gymId && form.ort && form.plz && form.strasse"
          class="btn btn-outline-secondary"
          type="button"
          @click="gymAdd()"
      >
        Speichern
      </button>
    </div>
  </div>
  <div class="list-wrapper">

    <vue-good-table
        :columns="columns"
        :pagination-options="pagination"
        :rows="gyms"
        :search-options="search"
        styleClass="vgt-table"
        theme="polar-bear"
    >
      <template #emptystate>
        <i class="fa-solid fa-magnifying-glass"></i> Es wurden keine Halle gefunden.
      </template>
      <template #table-row="props">
        <GymListItem
            :gym="props.row"
        />
      </template>
    </vue-good-table>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import GymListItem from "@/components/Admin/Settings/Gym/GymList/Item/GymListItem";
import {pagination, search, } from '@/config/datatable.config'

export default {
  name: "GymList",
  data() {
    return {
      add: false,
      columns:[{
        name: "Name",
        field: "bezeichnung"
      },
        {
          name: "Strasse",
          field: "strasse",
          hidden: true
        },
        {
          name: "PLZ",
          field: "plz",
          hidden: true
        },
        {
          name: "GymId",
          field: "gymId",
          hidden: true
        },
      ],
      form:{
        gymId: null,
        bezeichnung: null,
        strasse: null,
        plz: null,
        ort: null,
        blocked: null
      }
    };
  },
  components: {
    GymListItem,
  },
  computed: {
    ...mapGetters("gym", ['getGyms']),
    gyms(){
      return this.getGyms
    },
    pagination(){
      return pagination
    },
    search(){
      return search
    }
  },
  methods: {
    ...mapActions("gym", ["loadGyms", "addGym"]),
    async gymAdd(){
      await this.addGym(this.form).then(()=>
      {
        this.form = {
          gymId: null,
          bezeichnung: null,
          kurzName: null,
          strasse: null,
          plz: null,
          ort: null,
          blocked: null
        }
      })
    }
  },
  async beforeMount() {
     await this.loadGyms
  }
};
</script>

<style scoped></style>
