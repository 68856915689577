<template>
  <button class="btn btn-outline-dark" @click="updateAll"> Update all</button>
  <button class="btn btn-outline-dark" @click="orphanRemove"> OrphanRemoval</button>
  <button class="btn btn-outline-dark" @click="updateFuture"> Update only Future Games</button>
  <input type="number" v-model="newLeague" /> <button class="btn btn-outline-dark" @click="getFromTeamSL">Liga hinzufügen</button>
  <vue-good-table
      :columns="columns"
      :pagination-options="pagination"
      :rows="getLeagues"
      :search-options="search"
      :sort-options="{enabled: true}"
      styleClass="vgt-table"
      theme="polar-bear">
    <template #emptystate>
      <i class="fa-solid fa-magnifying-glass"></i> Es wurden keine Ligen gefunden.
    </template>
    <template #table-row="props">
      <LeagueListItem
          :league="props.row" @update="update"
      />
    </template>
  </vue-good-table>



</template>

<script>
import {mapActions, mapGetters} from "vuex";
import LeagueListItem from "@/components/Admin/Leagues/List/Item/LeagueListItem";
import {pagination, search} from '@/config/datatable.config';

export default {
  name: "LeaguesList",
  components: {
    LeagueListItem,
  },
  data(){
    return{
      columns:[   {
        label: 'Name',
        field: 'liganame',
      },],
      lastMessage: null,
      lastMessageHistory: [],
      newLeague: null
    }
  },
  computed: {
    ...mapGetters('league', ['getLeagues']),
    search(){
      return search
    },
    pagination(){
      return pagination
    }
  },
  methods:{
    ...mapActions('league', ['updateTeamSL', 'updateTeamSLFuture', 'updateLeagueTeamSL', 'loadLeagues', 'orphanRemoval']),
    ...mapActions('toast', ['add']),
    async getFromTeamSL(){
      if(this.newLeague !== null && this.newLeague > 0 ){
        await this.updateLeagueTeamSL(this.newLeague);
      }else{
        this.add({
          title: 'Liganummer',
          type: 'danger',
          message: 'Falsche Liganummer angegeben.'
        })
      }
      },
    update(){
      this.loadLeagues()
    },
    async updateAll(){
      if(confirm("Alles Updaten? Das dauert ein paar Minuten!")){
        await this.updateTeamSL()
        this.add({
          title: 'Erfolgreich',
          type: 'success',
          message: 'geupdated'
        })
      }
    },
    async updateFuture(){
      if(confirm("Alle zukünftigen Updaten? Das dauert ein paar Minuten!")){
        await this.updateTeamSLFuture()
        this.add({
          title: 'Erfolgreich',
          type: 'success',
          message: 'geupdated'
        })
      }
    },
    async orphanRemove(){
      if(confirm("Alle verwaisten Teams und Ligen löschen?")){
        await this.orphanRemoval()
        this.add({
          title: 'Erfolgreich',
          type: 'success',
          message: 'geupdated'
        })
      }
    }
  }
};
</script>

<style scoped>
.history{
  max-height: 300px;
  margin-bottom: 10px;
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
}
</style>
