<template>
  <div class="justify-content-start d-flex">
<h2 class="text-start"><i class="fa-solid fa-hashtag"></i>&nbsp;Dashboard</h2>
  </div>
  <div>

  </div>
<Cards />
</template>


<script>
//TODO Dashboard
import Cards from "@/components/Dashboard/Cards/Cards";
import {mapGetters} from "vuex";

export default {
  name: "Dashboard",
  components: {
    Cards
  },
  data() {
    return {
      dash: 1
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isManager', 'isUser', 'isAuthenticated', 'getUser'])
  },methods:{
  }
};
</script>

<style>
.hithere {
  opacity: 100;
  animation: hithere 1s ease 1, JumpOut 1s 3s ease 1, KeepOut 4s 1s linear infinite;
}
@keyframes JumpOut {
  0%   { opacity:100;}
  100% { opacity:0;}
}
@keyframes KeepOut {
  0%   { opacity:0;}
  100% { opacity:0;}
}

@keyframes hithere {
  30% {
    transform: scale(1.2);
  }
  40%, 60% {
    transform: rotate(-20deg) scale(1.2);
  }
  50% {
    transform: rotate(20deg) scale(1.2);
  }
  70% {
    transform: rotate(0deg) scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>