<template>
  <li
      class="list-group-item d-flex justify-content-center align-items-center row mx-2 mb-1 shadow-sm border border-dark"
  >
    <div class="align-items-center col-12 col-sm-12">
      <div class="p-3 mt-5">
        <p class="fw-bold mt-5 mb-1 searchable-name text-center">
          {{ user.firstName }}&nbsp;{{ user.lastName }}
        </p>
        <p>&nbsp;</p>
        <span class="top-left bg-dark text-light">{{ user.email }}</span>
        <span class="bottom-right bg-success text-light" v-if="user.isVerified">
          <i class="fa-solid fa-circle-check"></i>
          verifiziert
        </span>
        <span class="bottom-right bg-danger text-light" v-if="!user.isVerified">
          <i class="fa-solid fa-circle-xmark"></i>
          nicht verifiziert
        </span>
        <span class="bottom-left bg-light text-dark">
          <i class="fa-solid fa-stopwatch"></i>
          zuletzt online:
          <span v-if="user.lastOnline != null">{{ ago }}</span>
          <span v-else>nie</span>
        </span>

        <span class="top-right bg-none text-light">
          <div class="mb-1">
            <button class="btn btn-outline-danger" @click="remove()">
              <i class="fa-solid fa-eraser"></i>
              löschen
            </button>
          </div>
          <div>
            <button
                v-if="!change"
                :class="{ active: change }"
                class="btn btn-outline-dark"
                @click="changeOption()"
            >
              <i class="fa-solid fa-eraser"></i>
              ändern
            </button>
          </div>
        </span>
        <div class="row">
          <div class="col-lg-6 mx-auto" :class="{ 'mb-5': change }">
            <ul class="list-group list-group-flush mb-5" v-if="!change">
              <ClubUserListItemTeamsList
                  v-for="team in teams"
                  :key="team.seasonTeamId"
                  :team="team"
              />
            </ul>
            <div v-else class="w-100">
              <v-select
                  v-model="form.teams"
                  :options="options"
                  :get-option-label="(option) => option.teamname + ' - ' + option.liganame"
                  multiple
                  placeholder="Mannschaften wählen"
                  class="form-control mb-5"
              />
              <button class="btn btn-outline-success" @click="save()">Speichern</button>
              &nbsp;
              <button
                  v-if="change"
                  class="btn btn-outline-danger"
                  @click="change = !change"
              >
                Abbrechen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ClubUserListItemTeamsList from "./Teams/ClubUserListItemTeamsList.vue";
import Multiselect from "@vueform/multiselect";
import {mapActions, mapMutations} from "vuex";

const moment = require("moment");

export default {
  name: "ClubUserListItem",
  props: ["user", "clubId", "options"],
  data() {
    return {
      change: false,
      form: {
        teams: [],
      },
    };
  },
  computed: {
    teams(){
      return this.options.filter(option =>
      {
        return this.user.teams.map(team=> team.seasonTeamId).includes(option.seasonTeamId)
      })
    },
    ago() {
      moment.locale("de");
      return moment(new Date(this.user.lastOnline)).fromNow();
    },
  },
  methods: {
    ...mapActions("club", ["changeTeams", "deleteUser", "loadClubById"]),
    changeOption(){
      this.form.teams= this.teams;
      this.change = !this.change
    },
    async save(){
      if(this.form.teams.length === 0){
        return this.remove()
      }
      else{
        await this.changeTeams({
          delTeams: this.teams.filter((team) => !this.form.teams.includes(team)),
          teams: this.form.teams,
          user: this.user,
          club: this.clubId
        }).then(() => this.loadClubById(this.clubId))
            .finally(() => {
              this.change = !this.change
            });
      }
    },
    async remove() {
      if (confirm("Wirklich löschen?")) {
        await this.deleteUser({user: this.user._id, teams: this.user.teams.map(team=> team._id), club: this.clubId}).then(() => {
          this.loadClubById(this.clubId)})

      }
    },
  },
  components: {ClubUserListItemTeamsList, Multiselect},

};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .searchable-name {
    margin-top: 40px;
  }
}
</style>
