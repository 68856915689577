<template>
  <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" ref="toast"  data-bs-autohide="false">
    <div class="toast-header" @click="dismiss"  :class="'bg-'+ toast.type">
      <span v-if="toast.icon" v-html="toast.icon"></span>&nbsp;
      <strong class="me-auto title">{{ toast.title }}</strong>
      <button type="button" class="btn-close" ></button>
    </div>
    <div class="toast-body bg-white" v-html="toast.message" @click="push">
    </div>
    <div v-if="toast.link" class="btn mb-2" :class="'btn-outline-'+ toast.type" @click="push">{{toast.link.text}}</div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { Toast } from "bootstrap";

export default {
  name: "SingleToast",
  props: {
    toast: {required: true, type: Object},
  },
  data(){
    return{
     polling: null
    }
  },
  computed:{
    ...mapGetters('auth', ['showToast', 'isAuthenticated' ])
  },
  created() {
    let timeout = 10000
    if(this.toast.timeout){
      timeout = this.toast.timeout
    }
    // Automatically dismiss toast after 10 seconds
    this.polling = setInterval(() => {
      this.dismissToast();
    }, timeout)
  },
  mounted(){
    if(this.showToast || !this.isAuthenticated) {
      var myToast = new Toast(this.$refs.toast);
      myToast.show();
    }else{
      this.dismissToast()
    }
  },

    beforeUnmount() {
      clearInterval(this.polling)
    },
  methods: {
    ...mapActions("toast", ["dismiss"]),
    push(){
      this.dismiss()
      if(this.toast.link){
        this.$router.push({name: this.toast.link.to, params: this.toast.link.params})
      }
    },
    dismissToast() {
      this.dismiss(this.toast.title);
    },
  },
};
</script>

<style lang="scss">

.bg-danger{
  .title{
    color: #FFFFFF;
  }
  .button{
    color:#FFFFFF;
  }

}
.bg-success{
  .title{
    color: #FFFFFF;
  }
  .button{
    color:#FFFFFF;
  }
}
</style>
