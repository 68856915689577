<template>
      <HistoryItem v-for="(history, index) in histories" :key="index" :index="index" :history="history" :game="game"/>
  <div class="container rounded-2 border bg-dark border-white text-light mb-2">
    <div class="px-3 row  border-bottom border-white">
      <div class="col-12"><i class="fa-solid fa-clipboard"></i>&nbsp;ursprünglicher Termin</div>
    </div>
    <div class="px-3 row d-flex justify-content-around">
      <div class="col-xl-3 my-2">
        <i class="fa-solid fa-calendar-days"></i>
        &nbsp;{{ date }}
      </div>
      <div class="col-xl-3 my-2">
        <i class="fa-solid fa-clock"></i>
        &nbsp;{{ game.oTime }}
      </div>
      <div class="col-xl-3 my-2">
        <i class="fa-solid fa-house"></i>
        &nbsp;{{ game.oGym }}
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import HistoryItem from "@/components/Reschedule/GameDetails/RescheduleItem/History/Item/HistoryItem";
import {toGermanDate} from "@/helper/date.helper";
import {compareAsc} from "date-fns";

export default {
  name: "History",
  components: {HistoryItem},
  props: ['game'],
  computed:{
    ...mapGetters('gym', ['getGymById']),
    date(){
      return toGermanDate(this.game.oDate)
    },
    histories(){
      return [{ initialName: this.game.resInitialName,
                initialClub: this.game.resInitialClub,
                gym: this.game.resGymId,
                kickoffDate: this.game.resKickoffDate,
                kickoffTime: this.game.resKickoffTime,
                date:   this.game.resDate,
                answer: this.game.resAnswer,
                type: this.game.resType,
                answerName: this.game.resAnswerName,
                answerDate: this.game.resAnswerDate,
                },...this.game.rescheduleHistory.sort((a,b)=> compareAsc(new Date(a.answerDate), new Date(b.answerDate)))]
    }
  }
};
</script>

<style scoped>
.bg-dark {
  background-color: #3f4458 !important;
}
</style>