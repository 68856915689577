<template>
  <div class="calendar">
    <h4><i class="fa-solid fa-calendar-days"></i>&nbsp;Neuer Spieltermin</h4>
    <div>
      &nbsp;<VueFlatPickr v-model="dateModel" :config="config" class="d-none"/>
    </div>
  </div>
</template>

<script>
import VueFlatPickr from 'vue-flatpickr-component';
import {German} from 'flatpickr/dist/l10n/de';
import {mapGetters} from "vuex";
import {toGermanDate, toEnglishDate} from '@/helper/date.helper'

export default {
  name: "Calendar",
  props: ['date', 'game'],
  components: {
    VueFlatPickr,
  },
  computed: {
    ...mapGetters('league', ['getLeagueById']),
    league() {
      if (this.game) {
        return this.getLeagueById(this.game.ligaId)
      }
      return [];
    },
    lastDay() {
      return toEnglishDate(this.league.lastDay)
    },
    firstDay() {
      return toEnglishDate(this.league.firstDay)
    }
  },
  data() {
    return {
      dateModel: null,
      config: {
        mode: 'single',
        position: 'center',
        wrap: false, // set wrap to true only when using 'input-group'
        locale: German, // locale for this instance only
        inline: true,
        onChange: (date, string) => this.$emit('setDate', string),
        static: true,
        weekNumbers: false,
        nextArrow: '<i class="fa-solid fa-arrow-right fa-xl"></i>',
        prevArrow: '<i class="fa-solid fa-arrow-left fa-xl"></i>',
        maxDate: this.lastDay,
        minDate: this.firstDay,
        disable: []
      },
    }
  },
  methods: {
    disable(date) {
      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();
      const kickoffDate = new Date(this.game.kickoffDate)
      if (year === kickoffDate.getFullYear() && month === kickoffDate.getMonth() && day === kickoffDate.getDate()) {
        return false
      }

      if (
          year === kickoffDate.getFullYear()
          && month === kickoffDate.getMonth()
          && day === (kickoffDate.getDate() - 1)
      ) {
        if (date.getDay() === 6 || date.getDay() === 0) {
          return false;
        }
      }
      if (
          year === kickoffDate.getFullYear()
          && month === kickoffDate.getMonth()
          && day === (kickoffDate.getDate() + 1)
      ) {
        if (date.getDay() === 6 || date.getDay() === 0) {
          return false;
        }
      }


      let block = this.league.blockingDates.filter(date => date.type === "BLOCK").map(date => new Date(toEnglishDate(date.date)))

      let free = this.league.blockingDates.filter(date => date.type === "FREE").map(date => new Date(toEnglishDate(date.date)))

      if (free.find(el => year === el.getFullYear() && month === el.getMonth() && day === el.getDate())) {
        return false
      }
      if (block.find(el => year === el.getFullYear() && month === el.getMonth() && day === el.getDate())) {
        return true
      }
      if (!this.league.resMon && date.getDay() === 1) {
        return true
      }
      if (!this.league.resTue && date.getDay() === 2) {
        return true
      }
      if (!this.league.resWed && date.getDay() === 3) {
        return true
      }
      if (!this.league.resThu && date.getDay() === 4) {
        return true
      }
      if (!this.league.resFri && date.getDay() === 5) {
        return true
      }
      if (!this.league.resSat && date.getDay() === 6) {
        return true
      }
      if (!this.league.resSun && date.getDay() === 0) {
        return true
      }

      return false

    }
  },
  mounted() {
    this.config.disable.push((date) => this.disable(date))
    this.config.maxDate = this.lastDay
    this.config.minDate = this.firstDay
    if (Date.parse(this.firstDay) > Date.parse(this.date)) {
      this.dateModel = this.firstDay
    } else {
      this.dateModel = this.date
    }
  }
};
</script>

<style scoped>
.flatpickr-day.flatpickr-disabled {
  color: red;
}

.flatpickr-day.flatpickr-disabled:hover {
  color: red;
}

.flatpickr-day.selected {
  background: rgb(0, 175, 255);
  border-color: rgb(0, 175, 255);
}


</style>

