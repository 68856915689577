<template>
  <div class="py-2">
    <hr />
    <div class="row justify-content-center">
      <div class="col text-center">
        Montag:
        <div class="form-check form-switch form-switch-xl my-auto mx-auto">
          <input
              v-model="mon"
              :class="{'undefined': mon === null}"
              class="form-check-input"
              type="checkbox"
              @change="update({monday:mon})"
          />
        </div>
      </div>
      <div class="col text-center">
        Dienstag:
        <div class="form-check form-switch form-switch-xl my-auto mx-auto">
          <input
              v-model="tue"
              class="form-check-input"
              type="checkbox"
              @change="update({tuesday:tue})"
          />
        </div>
      </div>
      <div class="col text-center">
        Mittwoch:
        <div class="form-check form-switch form-switch-xl my-auto mx-auto">
          <input
              v-model="wed"
              :class="{'undefined': wed === null}"
              class="form-check-input"
              type="checkbox"
              @change="update({wednesday:wed})"
          />
        </div>
      </div>
      <div class="col text-center">
        Donnerstag:
        <div class="form-check form-switch form-switch-xl my-auto mx-auto">
          <input
              v-model="thu"
              :class="{'undefined': thu === null}"
              class="form-check-input"
              type="checkbox"
              @change="update({thursday:thu})"
          />
        </div>
      </div>
      <div class="col text-center">
        Freitag:
        <div class="form-check form-switch form-switch-xl my-auto mx-auto">
          <input
              v-model="fri"
              :class="{'undefined': fri === null}"
              class="form-check-input"
              type="checkbox"
              @change="update({friday:fri})"
          />
        </div>
      </div>
      <div class="col text-center">
        Samstag:
        <div class="form-check form-switch form-switch-xl my-auto mx-auto">
          <input
              v-model="sat"
              :class="{'undefined': sat === null}"
              class="form-check-input"
              type="checkbox"
              @change="update({saturday:sat})"
          />
        </div>
      </div>
      <div class="col text-center">
        Sonntag:
        <div class="form-check form-switch form-switch-xl my-auto mx-auto">
          <input
              v-model="sun"
              class="form-check-input"
              :class="{'undefined': sun === null}"
              type="checkbox"
              @change="update({sunday:sun})"
          />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Days",
  props:['value'],
  emits: ['change'],
  data(){
    return{
      mon: null,
      tue: null,
      wed: null,
      thu: null,
      fri: null,
      sat: null,
      sun: null
    }
  },
  computed: {
    monday() {
      if (this.value.filter(league => league.monday === true).length === this.value.length) {
        return true
      }
      if (this.value.filter(league => league.monday === false).length === this.value.length) {
        return false
      }
      return null
    },
    tuesday() {
      if (this.value.filter(league => league.tuesday === true).length === this.value.length) {
        return true
      }
      if (this.value.filter(league => league.tuesday === false).length === this.value.length) {
        return false
      }
      return null
    },
    wednesday() {
      if (this.value.filter(league => league.wednesday === true).length === this.value.length) {
        return true
      }
      if (this.value.filter(league => league.wednesday === false).length === this.value.length) {
        return false
      }
      return null
    },
    thursday() {
      if (this.value.filter(league => league.thursday === true).length === this.value.length) {
        return true
      }
      if (this.value.filter(league => league.thursday === false).length === this.value.length) {
        return false
      }
      return null
    },
    friday() {
      if (this.value.filter(league => league.friday === true).length === this.value.length) {
        return true
      }
      if (this.value.filter(league => league.friday === false).length === this.value.length) {
        return false
      }
      return null
    },
    saturday() {
      if (this.value.filter(league => league.saturday === true).length === this.value.length) {
        return true
      }
      if (this.value.filter(league => league.saturday === false).length === this.value.length) {
        return false
      }
      return null
    },
    sunday() {
      if (this.value.filter(league => league.sunday === true).length === this.value.length) {
        return true
      }
      if (this.value.filter(league => league.sunday === false).length === this.value.length) {
        return false
      }
      return null
    }
  },
  methods:{
    ...mapActions('league', ['updateSettings']),
    update(object){
      let map = Object.values(this.value).map(val => val.id)
      let ids = {
        ids: map
      }
      this.updateSettings({...ids, ...object})
      this.$emit('change')
    }
  },
  mounted(){
    this.mon= this.monday
    this.tue= this.tuesday
    this.wed= this.wednesday
    this.thu= this.thursday
    this.fri= this.friday
    this.sat= this.saturday
    this.sun= this.sunday
  },
watch:{
    value(){
      this.mon= this.monday
      this.tue= this.tuesday
      this.wed= this.wednesday
      this.thu= this.thursday
      this.fri= this.friday
      this.sat= this.saturday
      this.sun= this.sunday
    }
}

}
</script>

<style scoped>
.undefined{
  background-color: #000000 !important;
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e) !important;
}
</style>