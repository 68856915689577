<template>
  <div class="input">
    <input placeholder="Nachricht an Spielpartner schreiben!" type="text" v-model="message" v-on:keyup.enter="sendMessage"/>
    <div class="sender" v-if="showButton">
      <i class="fa-solid fa-paper-plane" @click="sendMessage" ></i>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'Input',
  props: ['gameId'],
  data(){
    return {
      message: null,
    }
  },
  computed:{
    showButton(){
      if(this.message !== null &&this.message !== '' ){
        return true
      }
      return false
    },
  },
  methods:{
    ...mapActions('match', ['addChat']),
    sendMessage(){
        if(this.showButton){
          this.addChat({id: this.gameId, message: this.message})
          this.message = null;
        }
      }
    }
}
</script>

<style scoped lang="scss">
$text-1: #333;
$text-2: #666;
$text-3: #999;
$line: #ccc;
$time-bg: #eee;
$background: #f7f7f7;

.input {
  box-sizing: border-box;
  flex-basis: 4rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 0 0.5rem 0 1.5rem;
margin: auto 0;
  i {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: $text-2;
    cursor: pointer;
    transition: color 200ms;

    &:hover {
      color: $text-1;

    }

  }

  input {
    border: none;
    background-image: none;
    background-color: white;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    border-radius: 1.125rem;
    flex-grow: 2;
    box-shadow: 0 0 1rem rgba(black, 0.1), 0rem 1rem 1rem -1rem rgba(black, 0.2);
    font-family: Red hat Display, sans-serif;
    font-weight: 400;
    letter-spacing: 0.025em;

    &::placeholder {
      color: $text-3;
    }
  }
}
</style>