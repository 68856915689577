<template>
  <div class="wrapper">
    <h2 class="text-start"><i class="fa-solid fa-user-graduate"></i>&nbsp;&nbsp;Vereinsverantwortliche</h2>
    <div class="inner-wrapper " v-for="(club, index) in getUser.club" :key="club.vereinId">
      <hr/>
      <div v-if="getUser.club.length > 1">
        <input
            v-model="visibility['toggler' + index]"
            autocomplete="off"
            :id="'toggler' + index"
            class="btn-check btn-block"
            type="checkbox"
        />
        <label class="btn btn-outline-dark w-100 mb-3" :for="'toggler' + index"
        >{{ club.vereinsname }}</label
        ><br/>

        <div v-show="visibility['toggler' + index]">
          <ClubManagerList :club="club.vereinId" />

        </div>
      </div>
      <div v-else>
        <ClubManagerList :club="club.vereinId" />
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ClubManagerList from "@/components/Shared/Manager/ClubManagerList";

export default {
  name: "ClubManager",
  data(){
    return{
      visibility: {}
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  mounted(){
    for (const index in this.getUser.club){
      this.visibility["toggler" + index] = parseInt(index) === 0
    }
  },
  components: {ClubManagerList},
  beforeMount() {

  }
};
</script>

<style scoped>

</style>