<template>
  <li class="list-group-item d-flex justify-content-center">
    <span>
      <i class="fa-solid fa-people-group"></i>
      &nbsp; {{ team.teamname }} - {{team.liganame}}
    </span>
  </li>
</template>

<script>
export default {
  name: "ClubUserListItemTeamsList",
  props: ["team"],
};
</script>

<style></style>
