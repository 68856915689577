<template>
  <vue-cookie-comply
      :preferences="preferences"
      acceptAllLabel="Akzeptieren"
      preferencesLabel="Informationen"
  >

    <template v-slot:header>
      <slot name="header">
        <h3 class="cookie-comply__header-title">Cookie Einstellungen</h3>
        <p class="cookie-comply__header-description">Wir benutzen Cookies und ähnliche Technologien um die Performance
          des Portal sicher zustellen. Es werden <u>keine</u> persönlichen Daten oder Nutzungsstatistiken gespeichert
          oder zu sonstigen Zwecken verwendet.</p>
      </slot>
    </template>

    <template v-slot:modal-header>
      <h3 class="text-center">Einstellungen</h3>
    </template>
  </vue-cookie-comply>
  <navbar/>
  <div class="container mb-2" v-if="card.show && !isAdmin">
    <div class="row d-flex justify-content-center">
      <div class="col-12">
        <div class="card">
          <div class="card-header" :class="card.CSS">
            <i :class="card.icon"></i> {{card.title}}
          </div>
          <div class="card-body" v-html="card.HTML">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <router-view :key="$route.fullPath"/>
  </div>
  <Toasts :position="'top-toast-right'"/>


</template>

<script>
import navbar from "./components/Navbar/Navbar.vue";
import Toasts from './components/Helper/Toasts/Toasts.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import {mapGetters} from "vuex";

export default {
  components: {
    navbar,
    Toasts
  },
  data() {
    return {
      fullPage: true,
      preferences: [
        {
          title: 'Funktionscookies',
          description:
              'Wir benutzen Cookies und ähnliche Technologien um die Performance des Portal sicher zustellen. Es werden keine persönlichen Daten oder Nutzungsstatistiken gespeichert oder zu sonstigen Zwecken verwendet.',
          items: [{label: 'Active', value: 'performance', isRequired: true, isDefaultEnable: true}],

        },
      ],
    }
  },
  computed:{
    ...mapGetters('auth',['getGlobals', 'isAdmin']),
   card(){ 
    const show = this.getGlobals.find(global => global.setting === "showCard").value === "true";
    const CSS = this.getGlobals.find(global => global.setting === "cardCSS").value;
    const HTML = this.getGlobals.find(global => global.setting === "cardHTML").value;
    const title = this.getGlobals.find(global => global.setting === "cardTitle").value;
    const icon = this.getGlobals.find(global => global.setting === "cardIcon").value;

    return {
    show,
    CSS,
    HTML,
    title,
    icon
    }
   },
    isLoading(){
      return this.getLoading
    }
  },
  methods:{
    onCancel(){
      //Do Nothing
    }
  },
  mounted(){

  }
};
</script>

<style lang="scss">
input:checked + .cookie-comply-required.cookie-comply-slider {
  background-color: #00c58e !important;
}

.cookie-comply__button:hover {
  background-color: white;
  color: #000;

  h2 {
    color: #000;
  }

  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'><g fill-rule='evenodd'><g fill='%23000000' fill-opacity='0.05' fill-rule='nonzero'><path d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/></g></g></svg>");
  text-align: center;
  overflow: hidden;
  background-position: 50% 50%;
  border: 5px solid #00a6e1;
}

.btn:hover {
  background-color: white;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'><g fill-rule='evenodd'><g fill='%23000000' fill-opacity='0.05' fill-rule='nonzero'><path d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/></g></g></svg>");
  overflow: hidden;
  background-position: 50% 50%;
  border: 5px solid #00a6e1 !important;
}

.cookie-comply {
  position: sticky;
  bottom: 0;
  z-index: 1000;
}

.cookie-comply__button.cookie-comply__button-accept {
  background-color: black;
}

.cookie-comply__button.cookie-comply__button-accept:hover {
  color: #00a6e1;
}
</style>
