<template>
  <div>
    <div class="d-flex">
      <div class="timefrom d-flex justify-content-around">
        <div>
          {{ message.name }}
        </div>
        <div>
          {{ ago }}
        </div>
      </div>
    </div>
    <div :class="{'theirmessage' : !myMessage}" class="message">
      {{ message.message }}
    </div>
    <div v-if="loading" class="message memessage">
      <div class="typing typing-1"></div>
      <div class="typing typing-2"></div>
      <div class="typing typing-3"></div>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Message",
  props: ['message'],
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    myMessage() {
      return this.getUser.myTeams.filter(team => parseInt(team.seasonTeamId) === this.message.seasonTeamId).length > 0;
    },
    ago() {
      moment.locale("de");
      return moment(new Date(this.message.sent)).fromNow();
    },
  },
  methods:{
    ...mapActions('match', ['markAsRead'])
  },
  mounted(){
    if(this.message.readBy.filter(read => read === this.getUser._id).length === 0){
      this.markAsRead({message: this.message._id})
    }
  }
}
</script>

<style lang="scss" scoped>
$text-1: #333;
$text-2: #666;
$text-3: #999;
$line: #ccc;
$time-bg: #eee;


.timefrom {
  font-size: 0.6em;
  background: $text-1;
  border-radius: 2rem;
  color: $line;
  width: 60%;
  margin: 5px auto;
  padding: 0.25rem 1rem;

}


.message {
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  margin: 1rem;
  background: darkgreen;
  color:white;
  border-radius: 1.125rem 1.125rem 1.125rem 0;
  min-height: 2.25rem;
  width: fit-content;
  max-width: 66%;
  overflow-wrap: break-word;

  box-shadow: 0 0 2rem rgba(black, 0.075),
  0rem 1rem 1rem -1rem rgba(black, 0.1);

  &.theirmessage {
    margin: 1rem 1rem 1rem auto;
    border-radius: 1.125rem 1.125rem 0 1.125rem;
    background: $text-1;
    color: white;
  }

  .typing {
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0rem;
    box-sizing: border-box;
    background: #ccc;
    border-radius: 50%;

    &.typing-1 {
      animation: typing 3s infinite;
    }

    &.typing-2 {
      animation: typing 3s 250ms infinite;
    }

    &.typing-3 {
      animation: typing 3s 500ms infinite;
    }
  }
}

@keyframes typing {
  0%,
  75%,
  100% {
    transform: translate(0, 0.25rem) scale(0.9);
    opacity: 0.5;
  }

  25% {
    transform: translate(0, -0.25rem) scale(1);
    opacity: 1;
  }
}
</style>