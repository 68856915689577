<template>
    <div class="Gym">
      <h4 class="text-start">
        <i class="fa-solid fa-list"></i>&nbsp;Halleneinstellung
      </h4>
      <GymList />
    </div>
</template>

<script>
import GymList from "@/components/Admin/Settings/Gym/GymList/GymList";

    export default {
        name: 'GymSettings',
      components: {GymList}
    };
</script>
