<template>
  <li class="list-group-item rounded mb-5  shadow  mx-2 border border-dark orange-event">
    <div class="align-items-center container">
      <span class="top-left bg-orange text-white">Offen</span>
      <div class="p-3 row mb-1 mt-5 rounded border-bottom border-dark">
        <div class="col-5">
          <p class="fw-bold mb-0 searchable-name text-end">
            <i class="fa-solid fa-house"></i>
            &nbsp;{{ game.homeTeamTeamname }}
          </p>
        </div>
        <div class="col-2 mb-0 align-middle h-100">
          <i class="fa-solid fa-arrow-right-arrow-left"></i>
        </div>
        <div class="col-5">
          <p class="fw-bold mb-0 searchable-name text-start">
            <i class="fa-solid fa-suitcase"></i>
            &nbsp;{{ game.guestTeamTeamname }}
          </p>
        </div>
      </div>
    </div>
    <div class="justify-content-between align-items-center row">
      <div class="col col-12 col-lg-12 justify-content-center">
        <div class="px-3 row justify-content-center">
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-hashtag"></i>
              &nbsp;{{ game.matchNo }}
            </p>
          </div>
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-calendar-days"></i>
              &nbsp;{{ gameDate }}
            </p>
          </div>
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-clock"></i>
              &nbsp;{{ game.resKickoffTime }}
            </p>
          </div>
          <div class="col-lg-3">
            <p class="mb-1 searchable-matchNo">
              <i class="fa-solid fa-house"></i>
              &nbsp;{{ gym.bezeichnung }}
            </p>
          </div>
        </div>
        <div class="col col-12 col-lg-12 justify-content-center">
          <div class="px-3 row justify-content-center">
            <div class="col-lg-12">
              <p class="mb-1 searchable-matchNo">
                <i class="fa-solid fa-arrow-right-arrow-left"></i>
                &nbsp;verlegt von: {{ game.resInitialName }} ({{ getRescheduleClub }})
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="justify-content-center align-items-center row mt-4" v-if="!userClub">
      <div class="col-lg-6">
        <div class="btn-group align-items-center mx-auto">
          <button class="btn btn-success" @click="accept">
            <i class="fa-solid fa-check-double"></i>&nbsp;Annehmen
          </button>
          <button class="btn btn-danger" @click="decline">
            <i class="fa-solid fa-circle-xmark"></i>&nbsp;Ablehnen
          </button>
        </div>
      </div>
    </div>
    <div class="justify-content-center align-items-center row mt-4" v-else>
      <div class="col-lg-6">
        <div class="btn-group align-items-center mx-auto">
          <button class="btn btn-danger" @click="callback">
            <i class="fa-solid fa-clock-rotate-left"></i>&nbsp;Zurückziehen
          </button>

        </div>
      </div>
    </div>
  </li>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {toGermanDate} from "@/helper/date.helper";

export default {
  name: 'OpenItem',
  props: ['game'],
  inject: ['formatTime', 'formatDate', 'formatTableDate'],
  computed: {
    ...mapGetters('auth', ['getUser', 'isManager']),
    ...mapGetters('gym', ['getGymById']),
    gameDate() {
      return toGermanDate(this.game.resKickoffDate)
    },
    gym() {
      return this.getGymById(this.game.resGymId)
    },
    userClub() {
      if (parseInt(this.game.homeTeamClubId) === parseInt(this.game.guestTeamClubId) && this.isManager) {
        return false
      }
      if (parseInt(this.game.homeTeamClubId) === parseInt(this.game.resInitialClub) && this.getUser.myTeams.map(team => parseInt(team.seasonTeamId)).includes(parseInt(this.game.homeTeamSeasonTeamId))) {
        return true
      } else if (parseInt(this.game.guestTeamClubId) === parseInt(this.game.resInitialClub) && this.getUser.myTeams.map(team => parseInt(team.seasonTeamId)).includes(parseInt(this.game.guestTeamSeasonTeamId))) {
        return true
      }
      return false
    },
    getRescheduleClub() {
      if (parseInt(this.game.homeTeamClubId) === parseInt(this.game.resInitialClub)) {
        return this.game.homeTeamTeamname
      } else if (parseInt(this.game.guestTeamClubId) === parseInt(this.game.resInitialClub)) {
        return this.game.guestTeamTeamname
      } else if (parseInt(this.game.resInitialClub) === 0) {
        return "BBV Spielleitung"
      }
    },
  },methods:{
    ...mapActions('match', ['submitAnswer']),
    ...mapActions('toast', ['add']),
    accept(){
    this.submitAnswer({
      id: this.game.matchId,
      answer: "ACCEPTED",
      gym: this.game.resGymId
    }).then(() => this.$emit('reschedule'))
      this.add({
        title: 'Bestätigt',
        type: 'success',
        message: 'Die Verlegung wurde bestätigt'
      })
    },
    decline(){
      this.submitAnswer({
        id: this.game.matchId,
        answer: "DECLINED",
        gym: this.game.resGymId
      }).then(() => this.$emit('reschedule'))
      this.add({
        title: 'Abgelehnt',
        type: 'danger',
        message: 'Die Verlegung wurde abgelehnt.'
      })
    },
    callback(){
      this.submitAnswer({
        id: this.game.matchId,
        answer: "CALLED_BACK",
        gym: this.game.resGymId
      }).then(() => this.$emit('reschedule'))
      this.add({
        title: 'Zurückgezogen',
        type: 'danger',
        message: 'Die Verlegung wurde zurückgezogen.'
      })
    }
  }
}
</script>

<style scoped>
.grey-event {
  background: repeating-linear-gradient(
      45deg,
      rgba(20, 20, 20, 0.3),
      white 10px
  );
  color: black;
  border-bottom: solid 0px;
  border-left: solid 0px;
  border-color: black;
  border-style: dashed;
}

.bg-orange {
  background-color: rgb(194, 86, 6)
}

.orange-event {
  background: repeating-linear-gradient(
      45deg,
      rgba(194, 86, 6, 0.3),
      white 10px
  );
  color: black;
  border-bottom: solid 0px;
  border-left: solid 0px;
  border-color: black;
  border-style: dashed;
}

</style>