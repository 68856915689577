import store from '@/store/store'
import axios from 'axios'
import router from '@/router/router'
import socket from "@/socket/socket";


export default {
    namespaced: true,
    state: {
        token: null,
        user: {
            roles: [],
            myTeams:[]
        },
        users: [],
        lastPath: null,
        globals: []

    },
    getters: {
        isAuthenticated(state) {
            return !!state.token && !!state.user
        },
        getUser(state) {
            return state.user
        },
        getGlobals(state) {
            return state.globals
        },
        getToken(state) {
            return state.token
        },
        getRoles(state) {
            return state.user.roles
        },
        getMyTeams(state){
            return state.user.myTeams
        },
        isAdmin(state) {
            return state.user.roles.includes('ROLE_ADMIN')
        },
        isManager(state) {
            return state.user.roles.includes('ROLE_MANAGER')
        },
        isUser(state) {
            return state.user.roles.includes('ROLE_USER')
        },
        showToast(state) {
            return !!state.user.showToast
        },
       
        getUsers(state){
            return state.users
        }
    },
    mutations: {
        setToken(state, token) {
            state.token = token
        },
        setUser(state, user) {
            state.user = user
        },
        setUsers(state, users){
            state.users = users
        },
        setLastPath(state, lastPath) {
            state.lastPath = lastPath
        },
        setGlobals(state, globals){
            state.globals = globals
        },
        async reset(state){
            state.user = {
                roles: [],
                myTeams:[]
            }
            state.token = null
            state.lastPath = null
            localStorage.removeItem('token')
            return Promise.resolve()
        }
    },
    actions: {
        //*** LOGIN
        //*** log into Backend with credentials
        async login({dispatch, commit, state}, credentails) {
            let lastPath = null
            if(state.lastPath){
                lastPath = {...state.lastPath}
            }
            await dispatch('clearLocalStorage')
            if(lastPath !== null){
                commit('setLastPath' , lastPath)
            }
            let response = await axios.post(`/auth/login`, credentails).catch(async () =>{
                socket.emit('logout')
                await dispatch("clearLocalStorage")
                //store.commit('dash/clearState')
                return router.push({name: 'login'})
            })
            if (response.data.token) {
                await dispatch('attempt', response.data)
            }
        },
        //*** ATTEMPT
        //*** if token is stored => get User from Backend
        async attempt({commit, state, dispatch}, payload) {
            if (payload.token) {
                commit('setToken', payload.token)
                const data = await axios.get(`/auth/resign`).then((response) => response.data).catch(async () =>{
                    socket.emit('logout')
                    await dispatch("clearLocalStorage")
                    //store.commit('dash/clearState')
                    return router.push({name: 'login'})
                })
                    if (data.token) {
                        await commit('setToken', data.token)
                        socket.emit('login', data.token)
                        await commit('setUser', data)
                        await dispatch('initialLoad')
                    }
            }
            if (!state.token) {
                return
            }
            try {
                if (!payload.noredirect) {
                    if (state.lastPath) {
                        let next = state.lastPath.fullPath
                        commit('setLastPath', null)
                        return router.replace({path: next})
                    } else {
                        return router.replace({name: 'home'})
                    }
                }
            } catch (e) {
                commit('setUser', null)
                commit('setToken', null)
                commit('setLastPath', null)
            }
        },
        //*** FORGET
        //*** Send request to Backend to send new Passwort to User
        async forget({}, credentails) {
             await axios.post('/auth/forget', credentails)
        },
        //*** LOAD USER
        //*** load users from Backend
        async loadUsers({commit}){
            const users = await axios.get('/user/list')
            if(users){
                commit('setUsers', users.data)
            }
        },
        //*** RESET PASS
        //**+ Reset Password of a user
        async reset({}, credentails) {
            await axios.post('/auth/reset', credentails).then((res) => {
                if (res.data.token) {
                    store.dispatch('auth/attempt', {token: res.data.token})
                }
                router
                    .push({name: 'login'})
                    .then(() => {
                        store.dispatch('toast/add', {
                            title: 'Erfolg',
                            type: 'success',
                            message: 'Das Passwort wurde erfolgreich geändert.',
                        })
                    })
                    .catch(() => {
                        router.push({name: 'forget'}).then(() => {
                            store.dispatch('toast/add', {
                                title: 'Fehlgeschlagen',
                                type: 'danger',
                                message:
                                    'Das ist etwas schiefgelaufen, bitte erneut versuchen.',
                            })
                        })
                    })
            })
        },
        async globals({commit}){
            const globals = await axios.get('globals').then(res => res.data)
            commit('setGlobals', globals)
        },
        //*** LOGOUT
        //*** Clear Token,Stores and Data after logout
        async logout({dispatch}) {
            socket.emit('logout')
            await dispatch("clearLocalStorage")
            return router.push({name: 'login'})
        },
        //*** InitialLoad
        //*** Load Data from API after Login
        async initialLoad({dispatch, state}){
            if(state.user.roles.includes("ROLE_MANAGER")){
                await store.dispatch('club/loadClubs')
            }
            if(state.user.roles.includes("ROLE_ADMIN")){
                await dispatch('loadUsers')
            }
            await store.dispatch('league/loadLeagues')
            await store.dispatch('gym/loadGyms')

            // await store.dispatch('match/loadGames')
        },

        async clearLocalStorage({commit}){
            await store.commit('club/resetClubs')
            await store.commit('gym/resetGyms')
            await store.commit('league/resetLeagues')
            await commit('reset')
        },

        async update({state}, payload) {
            var user = state.user
            Object.keys(payload).forEach((index) => {
                user[index] = payload[index]
            })
            await axios.put('user/update', user)

        }
    },
}
