   <template>
     <div class="center round">
       <div class="chat bg-dark rounded-0">
         <div class="m-contact m-bar d-flex justify-content-between text-light">
           <div>
             <div class="name text-start">
               <i class="fa-solid fa-envelope"></i> Chat
             </div>
           </div>
           <div class="sticky" @click="this.$router.go(-1)" >
             <i class="fa-solid fa-x fa-xl text-secondary"></i>
           </div>
         </div>
         <Messages :game-id="gameId" />
          <Input :game-id="gameId"/>
       </div>
     </div>
   </template>

   <script>
   import Input from "@/components/Helper/Chat/Input/Input";
    import Messages from "@/components/Helper/Chat/Messages/Messages";

  export default{
     name: 'Mobile',
     props:['gameId'],
     components:{
       Input, Messages
     },
  mounted(){
    var chat = document.getElementById("chat");
    chat.scrollTop = chat.scrollHeight - chat.clientHeight;
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
   }
   </script>

   <style scoped lang="scss">
    .fa-x{
   color:#FFF;
   }
   .fa-x:hover{
     color:#0099cc !important;
     cursor:pointer !important;
   }
   .center {
     position:absolute;
     top: 0;
     bottom: 0;
     right: 0;
     left: 0;
     width: 100vw;
     height: 100vh;
     height: calc(var(--vh, 1vh) * 100);
     transform: translate(0%, 0%);
   }
   .chat {
     bottom:0;
     right: 0;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     z-index: 100000;
     box-sizing: border-box;
     background: white;
     box-shadow: 0 0 8rem 0 rgba(black, 0.1), 0rem 2rem 4rem -3rem rgba(black, 0.5);
     position: sticky;
     width: 100vw;
     height: 100vh;
     height: calc(var(--vh, 1vh) * 100);
     top: 50%;
     left: 50%;

   .m-contact.m-bar {
     overflow:hidden;
     margin: 1rem;
     box-sizing: border-box;
   }
   }



   </style>