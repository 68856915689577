<template>
  <form @submit.prevent="submit">
    <div class="row justify-content-center">
      <div class="col-lg-12 mb-3">
        <img
            class="img-responsive center-block d-block mx-auto img-logo-bbv"
            src="@/assets/images/bbv_puls_logo_cymk.png"
        />
      </div>
      <div class="col-8">
        <h2 class="h3 mb-3 font-weight-normal">
          <i class="fa-solid fa-key"></i>
          Passwort zurücksetzen?
        </h2>
        <div class="input-group mb-3">
          <input
              v-model="form.password"
              :class="{ 'is-invalid': error || toshort, 'is-valid': success }"
              autofocus
              class="form-control"
              placeholder="Passwort"
              required
              type="password"
          />
        </div>
        <div class="input-group mb-3">
          <input
              v-model="form.passwordVerify"
              :class="{ 'is-invalid': error || toshort, 'is-valid': success }"
              class="form-control"
              placeholder="Passwort wiederholen"
              required
              type="password"
          />
          <div v-if="error" class="invalid-feedback">
            Die Passwörter müssen übereinstimmen!
          </div>
          <div v-if="toshort" class="invalid-feedback">
            Das Passwort muss mindest 5 Stellen lang sein.
          </div>
          <div v-if="success" class="valid-feedback">
            Das sieht gut aus!
          </div>
        </div>

        <button class="btn btn-lg btn-primary" type="submit">
          Zurücksetzen
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import router from '@/router/router'
import {mapActions} from 'vuex'

export default {
  name: 'ResetPassword',
  data() {
    return {
      form: {
        password: null,
        passwordVerify: null,
        token: null,
      },
      error: false,
      success: false,
      toshort: false,
    }
  },
  methods: {
    ...mapActions('toast', ['add']),
    ...mapActions('auth', ['clearLocalStorage', "attempt"]),
    async submit() {
      if (this.form.password == this.form.passwordVerify && this.form.password != "" && this.form.password.length > 4) {
        await this.$store
            .dispatch('auth/reset', this.form)
      }
    }
  },
  watch: {
    'form.password'(newValue) {
      if (newValue.length <= 4 && newValue != '') {
        this.toshort = true
      } else {
        this.toshort = false
      }

      if (this.form.passwordVerify != newValue && newValue != '') {
        this.error = true
        this.success = false
      } else if (newValue == '' && this.form.passwordVerify != '') {
        this.error = true
        this.success = false
      } else if (newValue == '' && this.form.passwordVerify == '') {
        this.error = false
        this.success = false
      } else {
        this.error = false
        this.success = true
      }
    },
    'form.passwordVerify'(newValue) {
      if (this.form.password != newValue && newValue != '') {
        this.error = true
        this.success = false
      } else if (newValue == '' && this.form.password != '') {
        this.error = true
        this.success = false
      } else if (newValue == '' && this.form.password == '') {
        this.error = false
        this.success = false
      } else {
        this.error = false
        this.success = true
      }
    },
  },
  async mounted() {
    if (this.$route.query.token) {
      await this.clearLocalStorage()
      this.form.token = this.$route.query.token
    } else {
      router.push({name: 'forget'}).then(() => {
        this.add({
          title: 'Fehler',
          type: 'danger',
          message: 'Da ist etwas schiefgelaufen: Der Token ist fehlerhaft! Bitte erneut versuchen!'
        });
      })
    }
  },
}
</script>

<style scoped></style>
