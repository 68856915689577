<template>
  <div class="wrapper">
    <div class="text-start mb-1">
      <button class="btn btn-outline-dark" type="button" @click="this.$router.go(-1)">
        <i class="fa-solid fa-backward-step"></i>
        Zurück
      </button>
    </div>
    <h2 class="text-start">
      <i class="fa-solid fa-table-list"></i>&nbsp;{{ vereinsname }}
    </h2>
    <hr/>
    <ClubDetails
        :club="club"
        :kurz-name="clubDetails.kurzName"
        :sr-name="clubDetails.srName"
        :team-length="teamLength"
        :vereins-nummer="clubDetails.vereinsnummer"
    />
    <hr/>
    <input
        id="btnManagerVisible"
        v-model="managerVisible"
        autocomplete="off"
        class="btn-check btn-block"
        type="checkbox"
    />
    <label class="btn btn-outline-dark w-100 mb-3" for="btnManagerVisible"
    ><i class="fa-solid fa-user-graduate"></i>&nbsp;Vereinsverantwortliche</label
    ><br/>

    <div v-show="managerVisible">
      <ClubManagerList :club="club"/>
    </div>

    <input
        id="btnUserVisible"
        v-model="userVisible"
        autocomplete="off"
        class="btn-check btn-block"
        type="checkbox"
    />
    <label class="btn btn-outline-dark w-100 mb-5" for="btnUserVisible" >
    <i class="fa-solid fa-user"></i>&nbsp;Teamverantwortliche
    </label>
    <br/>
    <div v-show="userVisible"  class="mb-5">
      <ClubUserList :club="club" :clubDetails="clubDetails"/>
    </div>
    <div v-if="isAdmin">
      <input
          id="btnAdminVisible"
          v-model="adminVisible"
          autocomplete="off"
          class="btn-check btn-block"
          type="checkbox"
      />
      <label class="btn btn-outline-dark w-100 mb-5" for="btnAdminVisible">
        <i class="fa-solid fa-bug"></i>&nbsp;offene Spiele
      </label>
      <br/>
      <div v-show="adminVisible" class="mb-5">
        <ClubOpen :club="club"/>
      </div>
    </div>
  </div>
</template>

<script>
import ClubDetails from "@/components/Admin/Club/ClubDetails/ClubDetails";
import {mapActions, mapGetters} from "vuex";
import ClubManagerList from "@/components/Shared/Manager/ClubManagerList";
import ClubUserList from "@/components/Shared/User/ClubUserList";
import ClubOpen from "@/components/Admin/Club/ClubOpen/ClubOpen.vue";

export default {
  name: "Club",
  props: ["club"],
  components: {ClubDetails, ClubManagerList, ClubUserList, ClubOpen},
  data() {
    return {
      teamsVisible: false,
      managerVisible: false,
      userVisible: false,
      adminVisible: false,
      search: "",
      page: 1,
    };
  },
  computed: {
    ...mapGetters('club', ['getClubById']),
    ...mapGetters('auth', ['isAdmin']),
    clubDetails() {
      return this.getClubById(this.club)
    },
    vereinsname(){
      return this.clubDetails.vereinsname !== undefined ? this.clubDetails.vereinsname : null
    },
    teamLength(){
      return this.clubDetails.teams !== undefined ? this.clubDetails.teams.length : null
    }
  },
  methods: {
    ...mapActions('club', ['loadClubById'])
  },
  async created() {
    await this.loadClubById(this.club)
  },
};
</script>

<style scoped></style>
