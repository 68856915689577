import axios from "axios";
import store from '@/store/store';
import socket from "@/socket/socket";
import router from "@/router/router";
import {useStorage} from "@vueuse/core";


export default {
    namespaced: true,
    state: {
        gyms: useStorage("gym", [], localStorage),
    },
    getters: {
        getGyms(state) {
            return state.gyms;
        },
        getGymById: (state) => (id) => {
            return Object.values(state.gyms).find((gym) => gym.gymId === id)
        },
    },
    mutations: {
        removeGym(state, gym) {
            let element = Object.values(state.gyms).find((el) => el.gymId === gym)
            let index = Object.values(state.gyms).indexOf(element)
            Object.values(state.gyms).splice(index, 1)
        },
        resetGyms(state) {
            state.gyms = [];
            localStorage.removeItem('gym')
        },
        setGyms(state, gyms) {
            state.gyms = gyms
        },
        updateGym(state, gym) {
          state.gyms[Object.values(state.gyms).indexOf(Object.values(state.gyms).find(el => el.gymId === gym.gymId))] = gym
        },
    },
    actions: {
        async deleteGym({commit},payload){
             await axios.delete('gym/'+ payload).then((res)=> commit('setGyms', res.data))
        },
        async addGym({commit}, payload){
             await axios.post('gym/update', payload).then((res) => commit('updateGym', res.data))
        },
        async loadGyms({commit}){
            await axios.get('gym/list').then((res) => commit('setGyms', res.data)).catch(async () =>{
                socket.emit('logout')
                await store.dispatch("auth/clearLocalStorage")
                //store.commit('dash/clearState')
                return router.push({name: 'login'})
            })
        },
        async loadGym({commit}, payload){
            await axios.get('gym/' + payload).then((res) => commit('updateGym', res.data)).catch(async () =>{
                socket.emit('logout')
                await store.dispatch("auth/clearLocalStorage")
                //store.commit('dash/clearState')
                return router.push({name: 'login'})
            })
        }
    }
};