const {PDFDocument, rgb} = require("pdf-lib");

export async function generatePDFWithCodesFromArray(tanlisten) {
    var pdfDoc = await PDFDocument.create();

    for (const liste of tanlisten) {
        const page = pdfDoc.addPage([600, 800]);

        // Add a big title
        page.drawText(liste.clubName, {
            x: 75,
            y: 750,
            size: 30,
            color: rgb(0, 0, 0),
        });

        // Determine the number of columns based on the codes array
        const columns = ["Column 1", "Column 2", "Column 3", "Column 4", "Column 5"];
        const numColumns = columns.length;

        const cellWidth = 100;
        const cellHeight = 30;
        const startY = 700;

        // Draw table rows from the codes array
        let lastRow = 1;
        for (let i = 0; i < liste.tans.length; i++) {
            const code = liste.tans[i].tan;
            const rowIndex = parseInt(i / numColumns);
            const columnIndex = i % numColumns;
            const x = 75 + columnIndex * cellWidth;
            const y = startY - rowIndex * cellHeight;

            const text = liste.tans[i].used ? "*******" : code;

            page.drawText(text, {
                x,
                y,
                size: 10,
                color: rgb(0, 0, 0),
            });
        }
    }

    // Save the PDF to a file
    return await pdfDoc.save();
}

export async function generatePDFWithCodes(liste) {
    var pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 800]);

    // Add a big title
    page.drawText(liste.clubName, {
        x: 75,
        y: 750,
        size: 30,
        color: rgb(0, 0, 0),
    });

    // Determine the number of columns based on the codes array
    const columns = ["Column 1", "Column 2", "Column 3", "Column 4", "Column 5"];
    const numColumns = columns.length;

    const cellWidth = 100;
    const cellHeight = 30;
    const startY = 700;

    // Draw table rows from the codes array
    let lastRow = 1;
    for (let i = 0; i < liste.tans.length; i++) {
        const code = liste.tans[i].tan;
        const rowIndex = parseInt(i / numColumns);
        const columnIndex = i % numColumns;
        const x = 75 + columnIndex * cellWidth;
        const y = startY - rowIndex * cellHeight;

        const text = liste.tans[i].used ? "*******" : code;

        page.drawText(text, {
            x,
            y,
            size: 10,
            color: rgb(0, 0, 0),
        });
    }

    // Save the PDF to a file
    return await pdfDoc.save();
}
