<template>
  <div class="col border-0 position-relative card-hover">
  <div class="card border-2 border-dark rounded-5 mt-2" :class="'bg-'+ card.color">
    <div class="card-block mt-3">
      <a @click="toLink" class="stretched-link text-reset" v-if="card.unread">
      <p class="position-relative"><i class="fa-solid fa-3x" :class="`fa-${card.icon? card.icon: 'chart-line'}`"
            ></i>

        <span class="position-absolute top-0 start-50 translate-middle badge rounded-pill bg-danger" v-if="card.unread">
 {{card.unread}} ungelesen
    <span class="visually-hidden">unread messages</span>
  </span>
      </p>
      </a>
      <a @click="toLink"  class="stretched-link text-reset" v-else>
        <p class="position-relative"><i class="fa-solid fa-3x" :class="`fa-${card.icon? card.icon: 'chart-line'}`"
        ></i>
        </p>
      </a>
      <h2 class="code">
        {{ card.number }}
      </h2>
      <h5 class="code">
        {{ card.smallNumber }}
      </h5>
      <div class="code">
        {{ card.text }}
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: 'Card',
      props:['card'],
      methods:{
        ...mapMutations('filter', ['setFilter']),
        ...mapMutations('route', ['setParam']),
          toLink(){
            if(this.card.link){
              if(this.card.number > 0 && this.card.filter){
                this.setFilter({
                  ...{
                    gym: [],
                    date: [],
                    search: "",
                    canceled: false,
                    accepted: false,
                    declined: false,
                    open: false,
                    calledBack: false,
                    spt: false,
                    message: false,
                    unread: false,
                    preFilter: []
                  }, ...this.card.filter
                })
              }
              this.$router.replace({name: this.card.link.name})
            }
          }
      }
    };
</script>

<style lang="scss" scoped>
.card-block{
  min-height: 11rem !important;
  h5{
    margin-bottom: 8px;
  }
}
.bg-blue{
  background-color: blue;
  color: #FFF;
  h2 {
    color:#FFF;
  }
  background-image:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'><g fill-rule='evenodd'><g fill='%23000000' fill-opacity='0.05' fill-rule='nonzero'><path d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/></g></g></svg>");
  text-align:center;
  overflow:hidden;
  background-position: 50% 50%;
  border:5px solid #00a6e1;
}
.bg-red{
  background-color: red;
  color:#FFF;
  h2 {
    color:#FFF;
  }
  background-image:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'><g fill-rule='evenodd'><g fill='%23000000' fill-opacity='0.05' fill-rule='nonzero'><path d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/></g></g></svg>");
  text-align:center;
  overflow:hidden;
  background-position: 50% 50%;
  border:5px solid #00a6e1;
}
.bg-black{
  background-color: rgba(0, 0, 0, 0.06);
  color:#FFF;
  h2 {
    color:#FFF;
  }
  background-image:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'><g fill-rule='evenodd'><g fill='%23000000' fill-opacity='0.05' fill-rule='nonzero'><path d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/></g></g></svg>");
  text-align:center;
  overflow:hidden;
  background-position: 50% 50%;
  border:5px solid #00a6e1;
}
.bg-white{
  background-color: white;
  color:#000;
  h2 {
    color:#000;
  }
  background-image:url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'><g fill-rule='evenodd'><g fill='%23000000' fill-opacity='0.05' fill-rule='nonzero'><path d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/></g></g></svg>");
  text-align:center;
  overflow:hidden;
  background-position: 50% 50%;
  border:5px solid #00a6e1;
}
.bg-black:hover,
.bg-red:hover,
.bg-blue:hover,
.bg-white:hover{
  border:5px solid #00a6e1 !important;
  color: #00a6e1 !important;
  h2,h5{
    color: #00a6e1 !important;
  }
}

</style>
