import {createApp} from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import '@fortawesome/fontawesome-free/css/all.css'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import './assets/app.scss'
import globalFunction from '@/plugin/globalFunction'
import vSelect from '@/plugin/vue-select/index'
import {VueGoodTable} from 'vue-good-table-next';
import 'bootstrap'
import VueCookieComply from 'vue-cookie-comply'
import socket from './socket/socket'
import JsonExcel from "vue-json-excel3";
import {vue3Debounce} from 'vue-debounce'


(async () =>{
    require('@/axios/axios')
    require('@/subscriber/subscriber')
    await store.dispatch('auth/globals');
    await store
    .dispatch('auth/attempt', {
        token: localStorage.getItem('token'),
        noredirect: true,
    })
    

const app = createApp(App)
app.use(store)
app.use(router)
app.use(globalFunction)
app.use(VueCookieComply)
    app.directive('debounce', vue3Debounce({lock: true}))
app.component("downloadExcel", JsonExcel);
app.config.globalProperties.$socket = socket;
app.config.productionTip = false
app.component('v-select', vSelect)
app.component('vue-good-table', VueGoodTable)
app.mount('#app')})()

