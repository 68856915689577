<template>
  <div class="time">
  <h4><i class="fa-solid fa-clock"></i>&nbsp;Neuer Spielbeginn</h4>
  <div class="wrapper mt-2 align-items-center">
    <div class="row justify-content-center">
      <div class="input-group">

        <VueTimepicker
            v-model="timeModel"
            :auto-scroll="true"
            :close-on-complete="true"
            :fixed-dropdown-button="true"
            :format="'HH:mm'"
            :hide-disabled-hours="true"
            :hour-label="'Stunde'"
            :hour-range="[8,9,10,11,12,13,14,15,16,17,18,19,20,21]"
            :input-class="'text-center form-control'"
            :input-width="'100%'"
            :minute-interval="15"
            :minute-label="'Minute'"
            :placeholder="'Uhrzeit'"
            @update:modelValue="time => timeChangeHandler(time)"
        />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'

export default {
  name: "Time",
  props: ['time', 'game'],
  components: {
    VueTimepicker
  },
  data() {
    return {
      timeModel: '08:00'
    }
  }, computed: {},
  methods: {
    timeChangeHandler(time) {
      this.$emit('setTime', time)
    }
  },
  mounted() {
    if (this.time) {
      if(this.time === "23:59"){
        this.timeModel = "09:00"
      }else{
        this.timeModel = this.time
      }
    }
  },
};
</script>

<style scoped>
input {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  background-color: #3f4458;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 400;
}

.hours, .minutes {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  font-family: inherit !important;
  font-size: 16px !important;
}

.dark-input::placeholder {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
  font-family: inherit !important;
  font-size: 16px !important;
  color: rgba(255, 255, 255, 0.80);
  font-weight: 200;
}

</style>
