<template>

  <li class="nav-item dropdown">
    <a
        id="daten"
        aria-expanded="false"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        href="#"
    >
      {{
        vereinsName
      }}
    </a>
    <ul aria-labelledby="daten" class="dropdown-menu">
      <li>
      <router-link :to="{ name: 'home'}" class="dropdown-item">
        <i class="fa-solid fa-hashtag"></i>
        &nbsp;Dashboard
      </router-link>
      </li>
      <!-- <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>-->
    </ul>
  </li>


</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "UserBar",
  computed: {
    ...mapGetters('auth', ['getUser']),
    vereinsName() {
      if (this.getUser && this.getUser.UserRoles && this.getUser.UserRoles.length > 0) {
        return this.getUser.UserRoles[0].club.vereinsName
      }
      return "Mein Verein"
    }
  }
};
</script>
<style></style>
