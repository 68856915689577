<template>
  <div class="wrapper">
    <h2 class="text-start"><i class="fa-solid fa-user"></i>&nbsp;Teamverantwortliche</h2>
    <div class="inner-wrapper " v-for="(club, index) in getUser.club" :key="club.vereinId">
      <hr/>
      <div v-if="getUser.club.length > 1">
      <input
          v-model="visibility['toggler' + index]"
          autocomplete="off"
          :id="'toggler' + index"
          class="btn-check btn-block"
          type="checkbox"
      />
      <label class="btn btn-outline-dark w-100 mb-3" :for="'toggler' + index"
      >{{ club.vereinsname }}</label
      ><br/>

      <div v-show="visibility['toggler' + index]">
        <ClubUserList :club="club.vereinId" :club-details="getClub(club.vereinId)"/>
      </div>
      </div>
      <div v-else>
        <ClubUserList :club="club.vereinId" :club-details="getClub(club.vereinId)"/>
      </div>
      </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ClubUserList from "@/components/Shared/User/ClubUserList";

export default {
  name: "ClubUser",
  data(){
    return{
      visibility: {}
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters("club", ["getClubById"]),
    clubDetails() {
      return this.getClubById(this.getUser.club[0].vereinId);
    },
  },
  methods:{
    getClub(id){
      return this.getClubById(id)
    }
  },
  mounted(){
    for (const index in this.getUser.club){
      this.visibility["toggler" + index] = parseInt(index) === 0
      }
  },
  components: {ClubUserList},
};
</script>

<style scoped>

</style>