import axios from "axios";
import store from '@/store/store';
import socket from "@/socket/socket";
import router from "@/router/router";
import {useStorage} from "@vueuse/core";


export default {
    namespaced: true,
    state: {
        unread: {value: null},
        open: {value: null},
    },
    getters: {
        getOpen(state) {
            return state.open.value
        },
        getUnread(state) {
            return state.unread.value
        }
    },
    mutations: {
        setUnread(state, payload) {
            state.unread.value = payload
        },
        setOpen(state, payload) {
            state.open.value = payload
        }
    },
    actions: {
        async rescheduleSocket({commit, dispatch}, payload) {
            await dispatch('profileNavbar')
            if (payload.game) {
                if (router.currentRoute && router.currentRoute.value.params.gameId && router.currentRoute.value.params.gameId === payload.game) {
                    return
                }
                let game = payload.data
                await store.dispatch('toast/add', {
                    title: 'Verlegungsanfrage',
                    type: 'info',
                    message: `Es gibt eine neue Verlegungsanfrage <br/> ${game.liganame} ${game.matchNo} <br />von ${game.resInitialName}`,
                    link: {
                        to: 'reschedule',
                        text: 'Zum Spiel',
                        params: {
                            gameId: payload.game
                        }
                    }
                })

            }
        },
        async chatSocket({commit, dispatch}, payload) {
            await dispatch('profileNavbar')
            if (payload.game) {
                if (router.currentRoute && router.currentRoute.value.params.gameId && router.currentRoute.value.params.gameId === payload.game) {
                    return
                }
                let game = payload.data
                await store.dispatch('toast/add', {
                    title: 'Nachricht',
                    type: 'info',
                    message: `Es gibt eine neue Nachricht zum Spiel <br/> ${game.liganame} ${game.matchNo} <br />von ${payload.name}<br/><strong>${payload.message}</strong>`,
                    link: {
                        to: 'reschedule',
                        text: 'Zum Spiel',
                        params: {
                            gameId: payload.game
                        }
                    }
                })

            }
        },
        async answerSocket({commit, dispatch}, payload) {
            await dispatch('profileNavbar')
            if (payload.game) {
                if (router.currentRoute && router.currentRoute.value.params.gameId && router.currentRoute.value.params.gameId === payload.game) {
                    return
                }
                let game = payload.data
                if (payload.answer === 'ACCEPTED') {
                    await store.dispatch('toast/add', {
                        title: 'Verlegung angenommen',
                        type: 'success',
                        message: `Die Verlegungsanfrage zum Spiel<br/> ${game.liganame} ${game.matchNo} <br />wurde von ${game.resAnswerName} angenommen.`,
                        link: {
                            to: 'reschedule',
                            text: 'Zum Spiel',
                            params: {
                                gameId: payload.game
                            }
                        }
                    })
                } else if (payload.answer === 'DECLINED') {
                    if (game.resAnswer !== 'DECLINED') {
                        game.resAnswerName = game.rescheduleHistory[game.rescheduleHistory.length - 1].answerName
                    }
                    await store.dispatch('toast/add', {
                        title: 'Verlegung abgelehnt',
                        type: 'danger',
                        message: `Die Verlegungsanfrage zum Spiel<br/> ${game.liganame} ${game.matchNo} <br />wurde von ${game.resAnswerName} abgelehnt.`,
                        link: {
                            to: 'reschedule',
                            text: 'Zum Spiel',
                            params: {
                                gameId: payload.game
                            }
                        }
                    })
                } else if (payload.answer === 'CALLED_BACK') {
                    if (game.resAnswer !== 'CALLED_BACK') {
                        game.resAnswerName = game.rescheduleHistory[game.rescheduleHistory.length - 1].answerName
                    }
                    await store.dispatch('toast/add', {
                        title: 'Anfrage zurückgezogen',
                        type: 'danger',
                        message: `Die Verlegungsanfrage zum Spiel<br/> ${game.liganame} ${game.matchNo} <br />wurde von ${game.resAnswerName} zurückgezogen.`,
                        link: {
                            to: 'reschedule',
                            text: 'Zum Spiel',
                            params: {
                                gameId: payload.game
                            }
                        }
                    })
                }
            }
        },
        async profileNavbar({commit}) {
            const res = await axios.get('match/profile').then(res => res.data)
            commit('setOpen', res.open)
            commit('setUnread', res.unread)

        },
        async addChat({dispatch}, payload) {
            await axios.post('match/chat/' + payload.id, {message: payload.message}).catch(async () => {
                socket.emit('logout')
                await store.dispatch("auth/clearLocalStorage")
                return router.push({name: 'login'})
            })
            await dispatch('profileNavbar')
        },
        async markAsRead({dispatch}, payload) {
            await axios.post('match/read/', payload).catch(async () => {
                socket.emit('logout')
                await store.dispatch("auth/clearLocalStorage")
                //store.commit('dash/clearState')
                return router.push({name: 'login'})
            })
            await dispatch('profileNavbar')
        },
        async submitReschedule({commit, dispatch}, payload) {
            return await axios.post
            ('match/reschedule/' + payload.game, {
                newDate: payload.newDate,
                newGym: payload.newGym,
                newTime: payload.newTime,
                type: payload.type,
                tan: payload.tan
            }).then(() => {
                store.dispatch('gym/loadGym', payload.newGym)
            }).catch(err => err)
            await dispatch('profileNavbar')
        },
        async submitAnswer({commit, dispatch}, payload) {
            await axios.post
            ('match/answer/' + payload.id, {
                answer: payload.answer
            }).then(async () => {
                await store.dispatch('gym/loadGym', payload.gym)
            }).catch(async () =>{
                socket.emit('logout')
                await store.dispatch("auth/clearLocalStorage")
                return router.push({name: 'login'})
            })
            await dispatch('profileNavbar')
        }
    }
};