<template>
  <div class="text-start mb-1">
    <button class="btn btn-outline-dark " type="button" @click="this.$router.go(-1)"><i
        class="fa-solid fa-backward-step"></i> Zurück
    </button>
  </div>
    <div class="list-group list-group-light justify-content-between searchable-list">
      <li class="list-group-item rounded mb-2 shadow  mx-2 border border-dark">
        <div class="align-items-center container">
          <span class="top-left bg-dark text-light"><i class="fa-solid fa-stamp"></i> Impressum</span>
      <div class="panel panel-info">
        <div class="panel-body">
          <div class="form-group">
            <div class="impressum">
              <p>Angaben gem&auml;&szlig; § 5 TMG</p>
              <p>
                Berliner Basketball Verband <br />
                Hanns-Braun-Stra&szlig;e<br />
                14053 Berlin <br />
              </p>
              <p>
                <strong style="text-decoration: underline;">Vertreten durch: </strong><br />
                Stephan Herwig<br />
                Hans-Jürgen Heck<br />
              </p>
              <p>
                <strong style="text-decoration: underline;">Registereintrag: </strong><br />
                Eintragung im Vereinsregister des AG Berlin-Charlottenburg<br />
                <br />
              </p>

              <p>
                <strong style="text-decoration: underline;">Umsatzsteuer-ID: </strong> <br />
                Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; §27a Umsatzsteuergesetz:
                27/610/50884<br />
                <br />
              </p>
              <p>
                <strong style="text-decoration: underline;">Verantwortlich f&uuml;r den Inhalt
                  nach § 55 Abs. 2 RStV:</strong><br />
                Berliner Basketball Verband <br />
                Hanns-Braun-Stra&szlig;e<br />
                14053 Berlin <br />
              </p>

              <p>
                <strong style="text-decoration: underline;">Haftungsausschluss:
                </strong><br /><br /><strong>Haftung f&uuml;r Inhalte</strong><br /><br />
                Die Inhalte unserer Seiten wurden mit gr&ouml;&szlig;ter Sorgfalt erstellt.
                F&uuml;r die
                Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t der Inhalte k&ouml;nnen
                wir jedoch
                keine Gew&auml;hr &uuml;bernehmen. Als Diensteanbieter sind wir gem&auml;&szlig;
                § 7 Abs.1 TMG
                f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu
                &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige
                T&auml;tigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
                unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem
                Zeitpunkt der
                Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von
                entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
                entfernen.<br /><br />
                <strong style="text-decoration: underline;">Haftung f&uuml;r
                  Links</strong><br /><br />
                Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter, auf deren
                Inhalte
                wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden
                Inhalte auch
                keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten
                ist stets der
                jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
                Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche
                Rechtsverst&ouml;&szlig;e
                &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
                nicht
                erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
                jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.
                Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
                entfernen.<br /><br />
                <strong style="text-decoration: underline;">Urheberrecht</strong><br /><br />
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung,
                Verbreitung und jede Art der Verwertung au&szlig;erhalb der Grenzen des
                Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors
                bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den
                privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
                aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
                entfernen.<br /><br />

              </p>
            </div>



          </div>
        </div>
      </div>
    </div>
      </li>
  </div>

</template>

<script>
    export default {
        name: 'Impressum'
    };
</script>
