<template>
  <li class="nav-item dropdown">
    <a
        id="admin"
        aria-expanded="false"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        href="#"
    >
      <i class="fa-brands fa-accusoft"></i>&nbsp;Admin
    </a>
    <ul aria-labelledby="admin" class="dropdown-menu">
      <li>
        <router-link :to="{ name: 'home'}" class="dropdown-item">
          <i class="fa-solid fa-hashtag"></i>
          &nbsp;Dashboard
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'adminLeagues'}" class="dropdown-item">
          <i class="fa-solid fa-table-list"></i>
          &nbsp;Ligen
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'clubs'}" class="dropdown-item">
          <i class="fa-solid fa-people-group"></i>
          &nbsp;Vereine
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'deletedGames'}" class="dropdown-item">
          <i class="fa-solid fa-eraser"></i>
          &nbsp;ausgeblendete Spiele
        </router-link>
      </li>
    </ul>
  </li>
  <li class="nav-item dropdown">
    <a
        id="setting"
        aria-expanded="false"
        class="nav-link dropdown-toggle"
        data-bs-toggle="dropdown"
        href="#"
    >
      <i class="fa-solid fa-gears"></i> Einstellungen
    </a>
    <ul aria-labelledby="setting" class="dropdown-menu">
      <li>
        <router-link :to="{ name: 'leagueSettings'}" class="dropdown-item">
          <i class="fa-solid fa-list"></i>&nbsp;Liga
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'gymSettings'}" class="dropdown-item">
          <i class="fa-solid fa-house"></i>&nbsp;Hallen
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'globalSettings'}" class="dropdown-item">
          <i class="fa-solid fa-gear"></i>&nbsp;Einstellungen
        </router-link>
      </li>
    </ul>
  </li>
</template>
<script>
export default {
  name: "Adminbar"
};
</script>
<style></style>
