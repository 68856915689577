<template>
  <li class="list-group-item rounded shadow  my-3 mx-2 border border-dark">
    <div class="align-items-center container">
      <span class="top-left bg-secondary text-light">Team</span>
      <div class="pt-2 row  mt-2 rounded  justify-content-center fw-bold text-center">
        <div class="row d-flex justify-content-center text-center">
          <ContactTeamUser v-for="(role, index) in user" :key="index" :role="role"/>
        </div>
      </div>

    </div>
  </li>
</template>


<script>
import ContactTeamUser from "@/components/Reschedule/GameDetails/RescheduleItem/ContactUser/User/User";

export default {
  name: 'ContactTeam',
  components: {ContactTeamUser},
  props: ['user']
}
</script>


<style scoped>

</style>