<template>
  <div class="text-start mb-4">
    <button class="btn btn-outline-dark" type="button" @click="this.$router.go(-1)">
      <i class="fa-solid fa-backward-step"></i>
      Zurück
    </button>
  </div>
  <div class="wrapper">
    <h2 class="text-start">
      <i class="fa-solid fa-lock"></i>
      &nbsp;TAN
    </h2>
    <v-select v-model="clubs" :options="getClubs" :get-option-label="(option) => option.vereinsname" multiple
              placeholder="Mannschaften wählen" class="form-control mb-5"/>
    <div class="btn-group" role="group" aria-label="Basic example">

      <button @click="deselectAll" v-if="clubs.length === getClubs.length" class="btn btn-outline-danger"><i
          class="fa-solid fa-xmark"></i> Alle abwählen
      </button>
      <button @click="selectAll" v-else class="btn btn-outline-primary"><i class="fa-solid fa-check-double"></i> Alle
        auswählen
      </button>
      <button @click="generateTan" v-if="clubs ? clubs.length > 0 : false" class="btn btn-outline-primary"><i
          class="fa-solid fa-lock"></i> TANS erstellen
      </button>
      <button @click="generateAll" v-if="tanListen.length > 0" class="btn btn-outline-dark"><i
          class="fa-solid fa-print"></i> Alle vorhandenen Drucken
      </button>
      <button @click="deleteAll" v-if="tanListen.length > 0" class="btn btn-outline-danger"><i
          class="fa-solid fa-eraser"></i> Alle vorhandenen löschen
      </button>
    </div>
    <div class="mt-2">
      <span v-if="wait"> Die Datei wird erstellt, dies kann je nach Anzahl eine gewisse Zeit dauern.</span>
      <div class="spinner-border" role="status" v-if="wait">
        <span class="visually-hidden">Wird erstellt biite warten...</span>
      </div>
      <a v-if="pdf" :href="pdfUrl" download="Sammlung.pdf">Download PDF</a>
      <span v-if="pdf"> Die Datei enthält die TANs von {{ clubText }} Vereinen </span>
    </div>
    <ul class="list-group mt-3">
      <tan-list-item v-for="(list, index) in tanListen" :liste="list" :key="index"
                     @update="getTanListen"></tan-list-item>
    </ul>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from 'axios';
import {generatePDFWithCodesFromArray} from '@/helper/pdf.helper'
import TanListItem from './TanListItem.vue';

export default {
  components: {TanListItem},
  name: "Tan",
  data() {
    return {
      name: "Hallo",
      length: 5,
      numberOfTan: 100,
      text: null,
      tanListen: [],
      pdf: null,
      wait: false,
      clubs: [],
      clubText: ""
    }
  },
  methods: {
    selectAll() {
      this.clubs = [...this.getClubs]
    },
    deselectAll() {
      this.clubs = []
    },
    async generateAll() {
      this.pdf = null;
      this.wait = true
      this.clubText = this.tanListen.length
      this.pdf = await generatePDFWithCodesFromArray(this.tanListen)
          .then((doc) => {
            this.wait = false
            return doc
          })
          .catch(error => console.error('Error generating PDF:', error));
    },
    async deleteAll() {
      if (confirm("Wirklich löschen?")) {
        for (const list of this.tanListen) {
          try {
            await axios.delete('administrator/tan/' + list._id);
            this.getTanListen()
          } catch (error) {
            console.error(`Error deleting TAN list with ID ${list._id}:`, error);
          }
        }
        this.getTanListen()
      }
    },
    async generateTan() {
      try {
        this.pdf = null;
        this.wait = true
        this.clubText = this.clubs.length
        const tanlist = await axios.post('administrator/tan', {clubs: this.clubs.map(club => club.vereinId)}).then(res => res.data);
        this.clubs = []
        const newListe = tanlist.map(liste => {
          return {
            ...liste, clubName: this.getClubs.find(club => club.vereinId === liste.clubId)?.vereinsname || ''
          }
        })
        this.tanListen = [...this.tanListen, ...newListe]
        this.tanListen.sort((a, b) => a.clubName.localeCompare(b.clubName) || a.tans.filter(tan => !tan.used).length - b.tans.filter(tan => !tan.used).length)

        this.pdf = await generatePDFWithCodesFromArray(newListe)
            .then((doc) => {
              this.wait = false
              return doc
            })
            .catch(error => console.error('Error generating PDF:', error));

      } catch (error) {
        console.error('Error getting TANs:', error);
      }
    },
    async getTanListen() {
      try {
        const tanlist = await axios.get('administrator/tanlist').then(res => res.data);
        const newListe = tanlist.map(liste => {
          return {
            ...liste, clubName: this.getClubs.find(club => club.vereinId === liste.clubId)?.vereinsname || ''
          }
        })

        newListe.sort((a, b) => a.clubName.localeCompare(b.clubName) || a.tans.filter(tan => !tan.used).length - b.tans.filter(tan => !tan.used).length)
        this.tanListen = [...newListe]


      } catch (error) {
        console.error('Error getting TANs:', error);
      }
    },


  },
  computed: {
    ...mapGetters('club', ['getClubs']),
    pdfUrl() {
      if (this.pdf) {
        const blob = new Blob([this.pdf], {type: 'application/pdf'});
        return URL.createObjectURL(blob);
      }
      return '';
    },
  },
  async mounted() {
    await this.getTanListen()
  }
}
</script>

<style scoped></style>