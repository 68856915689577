<template>
  <li
      class="list-group-item d-flex justify-content-center align-items-center row mx-2 mb-3 shadow-sm border border-dark"
  >
    <div class="align-items-center col-12 col-sm-12">
      <div class="p-3">
        <p class="fw-bold mb-1 searchable-name text-center">
          {{ user.firstName }}&nbsp;{{ user.lastName }} <span v-if="getUser.email === user.email" class="text-danger"><br/><i
            class="fa-solid fa-circle-exclamation"></i> dieser Account&nbsp;<i
            class="fa-solid fa-circle-exclamation"></i></span>
        </p>
        <p>&nbsp;</p>
        <span class="top-left bg-dark text-light">{{ user.email }}</span>
        <span class="bottom-right bg-success text-light" v-if="user.isVerified"
        ><i class="fa-solid fa-circle-check"></i> verifiziert</span
        >
        <span class="bottom-right bg-danger text-light" v-if="!user.isVerified"
        ><i class="fa-solid fa-circle-xmark"></i> nicht verifiziert</span
        >
        <span class="bottom-left bg-light text-dark"
        ><i class="fa-solid fa-stopwatch"></i> zuletzt online:
          <span v-if="user.lastOnline != null">{{ ago }}</span
          ><span v-else>nie</span>
        </span>

        <span class="top-right bg-none text-light"
        ><button class="btn btn-outline-danger" @click="remove()">
            <i class="fa-solid fa-eraser"></i> löschen
          </button></span
        >
      </div>
    </div>
  </li>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

const moment = require("moment");

export default {
  name: "ClubManagerListItem",
  props: ["user", "club"],
  computed: {
    ...mapGetters('auth', ['getUser']),
    ago() {
      moment.locale("de");
      return moment(new Date(this.user.lastOnline)).fromNow();
    },
  },
  methods: {
    ...mapActions("club", ["deleteManager", "loadClubById"]),
    async remove() {
      if (confirm("Wirklich löschen?")) {
        await this.deleteManager({user: this.user._id, club: this.club}).then(() =>this.loadClubById(this.club));
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .searchable-name {
    margin-top: 40px;
  }
}
</style>
