// socket.js
import io from 'socket.io-client'

const socket = io(process.env.VUE_APP_NODE_ENV === 'production' ? process.env.VUE_APP_WS_URL : process.env.VUE_APP_DEV_WS_URL)

import store from "@/store/store";

socket.on('gym', (gym) => store.commit('gym/setGym', gym))
socket.on('games', (games) => store.commit('match/setGames', games))
socket.on('leagues', (leagues) => store.commit('leagues/setLeagues', leagues))
socket.on('gymId', (gym) => store.commit('gym/updateGym', gym))
socket.on('clubs', (clubs) => store.commit('club/setClubs', clubs))
socket.on('clubId', (club) => store.commit('club/updateClub', club))
socket.on('reschedule', (res) => store.dispatch('match/rescheduleSocket', res))
socket.on('answer', (res) => store.dispatch('match/answerSocket', res))
socket.on('chat',(res) => store.dispatch('match/chatSocket', res))


export default socket